import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  BackNavigable,
  BackNavigationService,
  Command,
  CommandStore,
  handleRequestErrors,
  SOFTLINE_FEATURE_COMMANDS,
  SOFTLINE_FEATURE_TITLE,
  TitleStore
} from "@softline/application";
import {combineLatestWith, Observable, Subscription, switchMap} from "rxjs";
import {base64Encode, Store} from '@softline/core';
import {map} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {SOFTLINE_DEFINITION_VOUCHER_QUERY} from '../../gesamtabfrage.api';
import {Belegart} from '../../types/belegart.model';
import {
  Definition,
  DefinitionStore,
  DynamicFormComponent,
  DynamicModule, FieldOkResultConverter,
  SOFTLINE_FEATURE_DEFINITIONS
} from "@softline/dynamic";
import {
  GesamtabfrageConfig,
  SOFTLINE_CONFIG_GESAMTABFRAGE,
  SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG,
  SOFTLINE_FEATURE_GESAMTABFRAGE_BELEGART,
} from '../../gesamtabfrage.shared';
import {CommonModule} from '@angular/common';
import {UiCoreModule} from '@softline/ui-core';
import {BelegStore} from '../../store/beleg.store';
import {BelegartStore} from '../../store';
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";

@Component({
  standalone: true,
  // tslint:disable-next-line:component-selector
  selector: 'soft-gesamtabfrage-query',
  templateUrl: './gesamtabfrage-query.component.html',
  styleUrls: ['./gesamtabfrage-query.component.scss'],
  imports: [CommonModule, UiCoreModule, DynamicModule, ReactiveFormsModule]
})
export class GesamtabfrageQueryComponent
  implements OnInit, OnDestroy, BackNavigable
{
  private subscription?: Subscription;
  private formSubscription?: Subscription;

  @ViewChild('form') form?: DynamicFormComponent<object>;

  activeType$ = this.route.paramMap.pipe(
    map((o) => o.get('type')),
    combineLatestWith(
      this.store.observe(
        SOFTLINE_FEATURE_GESAMTABFRAGE_BELEGART,
        BelegartStore.getters.entities
      )
    ),
    map(([type, entities]) => entities[type ?? -1])
  );

  definition$: Observable<Definition> = this.activeType$.pipe(
    switchMap(o => this.store.observe(
      SOFTLINE_FEATURE_DEFINITIONS,
      DefinitionStore.getters.definition,
      [
        `${SOFTLINE_DEFINITION_VOUCHER_QUERY}+${o?.id}`,
        SOFTLINE_DEFINITION_VOUCHER_QUERY
      ])
  ));

  readonly value$: Observable<any> = this.store
    .observe(SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG, BelegStore.getters.query.query);

  constructor(
    private store: Store,
    protected router: Router,
    private route: ActivatedRoute,
    private backNavigationService: BackNavigationService,
    private cdRef: ChangeDetectorRef,
    @Inject(SOFTLINE_CONFIG_GESAMTABFRAGE) private config: GesamtabfrageConfig
  ) {}

  async ngOnInit(): Promise<void> {
    this.backNavigationService.set(this);

    this.subscription = this.activeType$.subscribe((o) => {
      if(!o)
        return;
      this.store.commit(
        SOFTLINE_FEATURE_TITLE,
        TitleStore.mutations.setTitle,
        o?.kundenName
      );
      this.store.commit(
        SOFTLINE_FEATURE_GESAMTABFRAGE_BELEGART,
        BelegartStore.mutations.select,
        o
      );
      this.store.commit(
        SOFTLINE_FEATURE_COMMANDS,
        CommandStore.mutations.addSet,
        {
          name: GesamtabfrageQueryComponent,
          commands: this.createCommands(o),
        }
      );
      try {
        this.store.dispatch(
          SOFTLINE_FEATURE_DEFINITIONS,
          DefinitionStore.actions.loadOnce,
          { name: `${SOFTLINE_DEFINITION_VOUCHER_QUERY}+${o.id}`  })
      }
      catch (e) {
        handleRequestErrors(this.store, e);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();

    if (this.formSubscription && !this.formSubscription.closed)
      this.formSubscription.unsubscribe();

    this.formSubscription = undefined;
    this.subscription = undefined;

    this.backNavigationService.set(undefined);

    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.removeSet,
      GesamtabfrageQueryComponent
    );
  }

  async onSubmit(value: any): Promise<void> {
    const typeIdentifier = this.route.snapshot.paramMap.get('type');
    const belegart: Belegart = this.store.get(SOFTLINE_FEATURE_GESAMTABFRAGE_BELEGART, BelegartStore.getters.entity, typeIdentifier);

    value = {
      ...value,
      belegart
    };

    this.store.commit(
      SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG,
      BelegStore.mutations.clear
    );

    const query = base64Encode(JSON.stringify(value));

    await this.router.navigate(['/gesamtabfrage', belegart.id, 'list'], {
      queryParams: { query },
    });
  }

  clearStore(): void {
    this.patchFormViaStoreCommit({});
  }

  patchFormViaStoreCommit(newValue: object): void {
    this.store.commit(
      SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG,
      BelegStore.mutations.query.setQuery,
      newValue
    );
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['/gesamtabfrage']);
  }

  protected createCommands(type: Belegart): Command[] {
    return [
      {
        name: type.kundenName,
        class: 'menu main-menu main-menu-top title',
      },
      {
        icon: 'fas fa-search',
        name: '#GESAMTABFRAGE.MENU.FIND',
        class: 'menu main-menu main-menu-top',
        routerLink: ['/gesamtabfrage/', type.id, 'find'],
      },
    ];
  }
}
