<ng-container *ngIf="activeType$ | async as type">
  <div class="flex justify-between flex-wrap w-full mt-4">
    <h1 class="text-2xl font-bold">
      {{ '#GESAMTABFRAGE.PAGES.QUERY.HEADER' | translate }}
    </h1>
    <div class="flex flex-nowrap">
      <button class="soft-button link mr-2" (click)="clearStore(); form.onReset($event)">
        {{ '#GESAMTABFRAGE.PAGES.QUERY.RESET' | translate }}
      </button>
      <button
        class="soft-button primary"
        [disabled]="(form.form.controls | equals:({})) || form.form.invalid"
        (click)="onSubmit(form.form.value)"
      >
        {{ '#GESAMTABFRAGE.PAGES.QUERY.SUBMIT' | translate }}
      </button>
    </div>
  </div>
  <hr class="my-4" />
</ng-container>

<soft-dynamic-form
  [value]="value$ | async"
  [definition]="$any(definition$ | async)"
  (reset)="clearStore()"
  (submit)="onSubmit($event)"
  #form
></soft-dynamic-form>
