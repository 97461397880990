<!--<ng-container *ngIf="userAgentService.isMobile()">
  <div class="c center-v">
    <input
      type="date"
      class="mr-2"
      [value]="value | date: 'yyyy-MM-dd'"
      (change)="setValue(inputElement.value, true)"
      [readOnly]="readonly"
      (blur)="onTouch()"
      #inputElement
      placeholder="{{
        '#UI_CORE.COMPONENTS.DATE_INPUT.PLACEHOLDER' | translate
      }}"
    />
  </div>
</ng-container> -->

<input
  [value]="input ?? (value | format: format || 'DATE.DEFAULT')"
  (input)="onInput(inputElement.value)"
  (change)="onBlur(inputElement.value)"
  #inputElement
  (dblclick)="openDatePickerModal()"
  (blur)="onTouch()"
  [readOnly]="readonly"
  placeholder="{{
    placeholder || '#UI_CORE.COMPONENTS.DATE_INPUT.PLACEHOLDER' | translate
  }}"
/>
<i
  *ngIf="!readonly"
  class="fas fa-calendar-alt picker-icon pointer ml-4"
  (click)="openDatePickerModal()"
></i>
