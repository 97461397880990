import {
  RemoteCollectionStore,
  SelectStore,
  StoreBuilder,
} from '@softline/core';
import { Belegart } from '../types/belegart.model';

export const BelegartStore = new StoreBuilder(
  RemoteCollectionStore.create<Belegart>()
).add(SelectStore.create<Belegart>()).value;
