import {
  ActionStore,
  CollectionStore,
  createAction,
  on,
  SOFTLINE_SERVICE_UUID,
  StoreBuilder,
  SubscriptionStore,
  SyncedRemoteCollectionDetailStore,
  SyncedRemoteCollectionStore
} from "@softline/core";
import { Workflow } from "../types/workflow";
import { lastValueFrom } from "rxjs";
import { WorkflowService } from "../services/workflow.service";
import { WorkflowData } from "../types/workflow-data";

export interface NextActionParameters {
  id: string;
  task: string;
  token?: string;
}

export class FreigabeStoreExtension {
  static mutations = {};
  static actions = {
    next: createAction<any, NextActionParameters, Workflow>('erEvidenz next'),
    recalculate: createAction<any, NextActionParameters, Workflow>('erEvidenz recalculate'),
  };
  static getters = {};
  static feature = {
    mutations: [],
    actions: [
      on(
        FreigabeStoreExtension.actions.next,
        async ({ commit, featureName, params, injector, state }) => {
          const service = injector.get(WorkflowService);
          const token = params.token ?? injector.get(SOFTLINE_SERVICE_UUID)();
          const subscription$ = SubscriptionStore.handleSubscriptionState(
            service.next(params.id, params.task),
            featureName,
            commit,
            token
          );
          const result = await lastValueFrom(
            ActionStore.handleObservableActionState(
              subscription$,
              featureName,
              commit,
              FreigabeStoreExtension.actions.next.name,
              token
            )
          );
          commit(featureName, CollectionStore.mutations.update, result);
          return result;
        }
      ),
      on(
        FreigabeStoreExtension.actions.recalculate,
        async ({ commit, featureName, params, injector, state }) => {
          const service = injector.get(WorkflowService);
          const token = params.token ?? injector.get(SOFTLINE_SERVICE_UUID)();
          const subscription$ = SubscriptionStore.handleSubscriptionState(
            service.next(params.id, params.task),
            featureName,
            commit,
            token
          );
          const result = await lastValueFrom(
            ActionStore.handleObservableActionState(
              subscription$,
              featureName,
              commit,
              FreigabeStoreExtension.actions.next.name,
              token
            )
          );
          commit(featureName, CollectionStore.mutations.update, result);
          return result;
        }
      ),
    ],
    getters: [],
  };
}

export const FreigabeStore = new StoreBuilder(SyncedRemoteCollectionStore.create<Workflow>())
  .add(SyncedRemoteCollectionDetailStore.create<Workflow, { detail: WorkflowData }>())
  .add(FreigabeStoreExtension).value;
