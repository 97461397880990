import {ENVIRONMENT_INITIALIZER, EnvironmentProviders, inject, Provider, StaticProvider} from "@angular/core";
import { Store } from "@softline/core";
import { SOFTLINE_FEATURE_TRANSLATION, TranslationStore } from "@softline/ui-core";
import { default as de } from "../i18n/de.json";
import { CommandStore, SOFTLINE_FEATURE_COMMANDS } from "@softline/application";

export const moduleProviders: (Provider | EnvironmentProviders)[] = [
  {
    provide: ENVIRONMENT_INITIALIZER,
    useValue: () => {
      const store = inject(Store);
      store.commit(
        SOFTLINE_FEATURE_TRANSLATION,
        TranslationStore.mutations.add,
        {
          module: 'app-overview',
          language: 'de',
          translations: de,
        }
      );
      store.commit(
        SOFTLINE_FEATURE_COMMANDS,
        CommandStore.mutations.addSet,
        {
          name: 'appOverview',
          priority: 1,
          commands: [
            {
              icon: 'fas fa-th',
              name: '#APP_OVERVIEW.ALL_APPLICATIONS',
              class: 'menu main-menu main-menu-top info',
              routerLink: ['/'],
            },
            {
              class: 'menu main-menu main-menu-top separator',
            },
          ],
        }
      );
    },
    multi: true,
  },
];
