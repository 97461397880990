import { CommonModule, DecimalPipe } from "@angular/common";
import { Component, Input } from "@angular/core";


@Component({
  selector: 'soft-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  imports: [CommonModule, DecimalPipe],
  standalone: true
})
export class ProgressBarComponent {

  _progress!: number;

  @Input()
  public set progress(progress: number) {
    if (progress < 0) {
      this._progress = 0;
    }
    else if (progress > 1) {
      this._progress = 1;
    }
    else this._progress = progress;
  }

  @Input() customClass? = '';
}
