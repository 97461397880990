<div *ngIf="((bottomCommands$ | async)?.length ?? 0) > 0"
     class="w-full gap-4 px-4 pb-4 pt-2">
  <ng-container *ngFor="let command of bottomCommands$ | async">
    <ng-container *ngIf="!command.isVisible || (command.isVisible | async)">
      <button  [id]="'framework-application-shell-menu-commands-bottom-' + command.name"
               class="soft-button rounded h-16 w-full"
               *ngVar="
                command.canExecute
                  ? (command.canExecute | async)
                  : true as canExecute
              "
               [ngClass]="command.class ?? ''"
               [disabled]="!canExecute"
               (click)="
                onClick(
                  command.execute,
                  command.routerLink,
                  canExecute,
                  command.routerLinkParams
                )
              ">
        <span class="soft-badge" *ngIf="command?.badge | async as badge">{{ badge }}</span>
        <span *ngIf="command.icon">
          <i [ngClass]="command.icon"></i>
        </span>
        <span class="ml-2">
          {{command.name | translate}}
        </span>
      </button>
    </ng-container>
  </ng-container>
</div>
