<div
  class="soft-container soft-container-column"
  *ngIf="form && definition"
  [formGroup]="form"
>
  <label class="soft-label soft-label-top"
    >{{ definition.title }}
    <span
      class="required-indicator"
      *ngIf="definition.required | booleanRule: form.value"
      >*</span
    >
  </label>
  <ng-container [ngSwitch]="definition.control">
    <soft-date-time-input
      *ngSwitchCase="'datetime'"
      [formControlName]="definition.name"
    ></soft-date-time-input>
    <soft-time-input
      *ngSwitchCase="'time'"
      [formControlName]="definition.name"
      [format]="definition.format | formatRule: form.value"
    ></soft-time-input>
    <soft-date-input
      *ngSwitchDefault
      [formControlName]="definition.name"
      [format]="definition.format | formatRule: form.value"
    ></soft-date-input>
  </ng-container>
  <soft-validation-container
    class="soft-property-validation"
    *ngIf="form.controls[definition.name].touched"
    [validation]="form | combineValidations: definition.name"
  ></soft-validation-container>
</div>
