
import { ENVIRONMENT_INITIALIZER, ImportedNgModuleProviders, inject, Provider } from "@angular/core";
import { Store } from "@softline/core";
import { SOFTLINE_FEATURE_TRANSLATION, TranslationStore } from "@softline/ui-core";
import { default as de } from "../i18n/de.json";
import { ApplicationStore, SOFTLINE_FEATURE_APPLICATIONS } from "@softline/application";
import { SOFTLINE_PERMISSION_GESAMTABFRAGE } from "./gesamtabfrage.permissions";
import { SOFTLINE_CONFIG_GESAMTABFRAGE } from "./gesamtabfrage.shared";

import { default as queryDefaultDefinition } from "./definitions/gesamtabfrage-query.definition.json";
import { default as listDefaultDefinition } from "./definitions/gesamtabfrage-list.definition.json";
import { default as businessCaseDefaultDefinition } from "./definitions/business-case-list.definition.json";
import {
  SOFTLINE_DEFINITION_VOUCHER_BUSINESS_CASE,
  SOFTLINE_DEFINITION_VOUCHER_LIST,
  SOFTLINE_DEFINITION_VOUCHER_QUERY
} from "./gesamtabfrage.api";
import { SOFTLINE_CONFIG_DEFINITION } from "@softline/dynamic";
import { BelegDownloadStrategy } from "./commands/download/beleg-download.strategy";
import { ArchivbelegDownloadStrategy } from "./commands/download/archiv-beleg-download.strategy";
import { DatentraegerDownloadStrategy } from "./commands/download/datentraeger-download.strategy";
import { FremdbelegDownloadStrategy } from "./commands/download/fremd-beleg-download.strategy";

export const gesamtabfrageProviders: (Provider |ImportedNgModuleProviders)[] = [
  {
    provide: ENVIRONMENT_INITIALIZER,
    useValue: () => {
      const store = inject(Store);
      store.commit(
        SOFTLINE_FEATURE_TRANSLATION,
        TranslationStore.mutations.add,
        {
          module: 'gesamtabfrage',
          language: 'de',
          translations: de,
        }
      );
      store.commit(
        SOFTLINE_FEATURE_APPLICATIONS,
        ApplicationStore.mutations.add,
        {
          name: '#GESAMTABFRAGE.TITLE',
          icon: 'fas fa-file-invoice',
          route: '/gesamtabfrage',
          permission: SOFTLINE_PERMISSION_GESAMTABFRAGE,
        }
      );
    },
    multi: true,
  },
  BelegDownloadStrategy,
  FremdbelegDownloadStrategy,
  ArchivbelegDownloadStrategy,
  DatentraegerDownloadStrategy,
  {
    provide: SOFTLINE_CONFIG_GESAMTABFRAGE,
    useValue: { download: [BelegDownloadStrategy] },
  },
  {
    provide: SOFTLINE_CONFIG_DEFINITION,
    useValue: {
      name: SOFTLINE_DEFINITION_VOUCHER_QUERY,
      definition: queryDefaultDefinition
    },
    multi: true
  },
  {
    provide: SOFTLINE_CONFIG_DEFINITION,
    useValue: {
      name: SOFTLINE_DEFINITION_VOUCHER_LIST,
      definition: listDefaultDefinition
    },
    multi: true
  },
  {
    provide: SOFTLINE_CONFIG_DEFINITION,
    useValue: {
      name: SOFTLINE_DEFINITION_VOUCHER_BUSINESS_CASE,
      definition: businessCaseDefaultDefinition
    },
    multi: true
  }
];
