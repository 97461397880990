<div
  class="c column"
  *ngIf="form && definition"
  [formGroup]="form"
  [ngSwitch]="definition.control"
>
  <label class="soft-label top"
    >{{ definition.title }}
    <span
      class="required-indicator"
      *ngIf="definition.required | booleanRule: form.value"
      >*</span
    >
  </label>
  <soft-multiline-input
    *ngSwitchCase="'multiline'"
    [formControlName]="definition.name"
    [readonly]="definition.readonly | booleanRule: form.value"
  >
  </soft-multiline-input>
  <soft-input
    *ngSwitchDefault
    [formControlName]="definition.name"
    [readonly]="definition.readonly | booleanRule: form.value"
    [softScan]="(definition | scanDefinition)?.scan ?? false"
    [labelTypes]="(definition | scanDefinition)?.labelTypes"
    #value
  >
  </soft-input>
  <soft-validation-container
    class="soft-property-validation"
    *ngIf="form.controls[definition.name].touched"
    [validation]="form | combineValidations: definition.name"
  ></soft-validation-container>
</div>
