<ng-container  *ngIf="workflow$ | async as workflow">
  <form
    *ngIf="workflow.detail as detail"
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
  >
    <div class="relative">
      <div
        *ngIf="detail.schlussrechnung || detail.teilrechnung"
        class="flex flex-col soft-card mt-2"
      >

        <div class="soft-overline border-b border-solid">
          {{ '#FREIGABE.WIDGET.ABAU_FREIGABE.FAELLIGKEIT' | translate }}
        </div>
        <div
          class="flex justify-between w-full text-right border-b border-solid pt-1 pb-1 items-center"
        >
            <span class="whitespace-nowrap">{{
              '#FREIGABE.WIDGET.ABAU_FREIGABE.FAELLIGKEIT_MITGLIED' | translate
              }}</span>
          <soft-date-input
            formControlName="faelligkeitMitglied"
            (valueChange)="faelligkeitFeldAenderung = 'faelligkeitMitglied'"
            class="w-1/4 h-6"
            [readonly]="form.disabled"
          ></soft-date-input>
        </div>
        <div
          class="flex justify-between w-full text-right border-b border-solid pt-1 pb-1 items-center"
        >
            <span class="whitespace-nowrap">{{
              '#FREIGABE.WIDGET.ABAU_FREIGABE.FAELLIGKEIT_LIEFERANT' | translate
              }}</span>
          <soft-date-input
            formControlName="faelligkeitLieferant"
            (valueChange)="faelligkeitFeldAenderung = 'faelligkeitLieferant'"
            class="w-1/4 h-6"
            [readonly]="form.disabled"
          ></soft-date-input>
        </div>

        <div class="soft-overline border-b border-solid mt-4">
          {{ '#FREIGABE.WIDGET.ABAU_FREIGABE.AUFSTELLUNG' | translate }}
        </div>

        <!--Teilrechnung-->
        <ng-container *ngIf="detail.teilrechnung">
          <!--Rechnungssumme-->
          <div
            class="flex justify-between w-full text-right border-b border-solid pt-1 pb-1"
          >
            <span class="whitespace-nowrap">
              {{ '#FREIGABE.WIDGET.ABAU_FREIGABE.RECHNUNGSSUMME' | translate }}</span
            >
            <span>{{
              detail.rechnungssummeNetto | format: 'NUMBER.DECIMAL'
              }}</span>
          </div>

          <!--Abstrich-->
            <div
              class="flex justify-between w-full text-right border-b border-solid pt-1 pb-1 items-center"
            >
            <span class="whitespace-nowrap">{{
              '#FREIGABE.WIDGET.ABAU_FREIGABE.ABSTRICH' | translate
              }}</span>

              <div class="flex flex-col w-1/4">
                <soft-number-input
                  formControlName="abstrichNetto"
                  class="w-full align-right h-6"
                  format="CURRENCY.DEFAULT"
                  placeholder="#FREIGABE.WIDGET.ABAU_FREIGABE.ABSTRICH_PLACEHOLDER"
                  [readonly]="form.disabled"
                ></soft-number-input>
                <soft-validation-container
                  [validation]="form|validationMessage:'abstrichNetto'"
                  class="soft-property-validation"></soft-validation-container>
              </div>
          </div>

          <!--Rechnungssumme nach Abstrich-->
          <div
            class="flex justify-between w-full text-right border-b border-solid pt-1 pb-1 items-center"
          >
            <span class="whitespace-nowrap">
              {{
              '#FREIGABE.WIDGET.ABAU_FREIGABE.RECHNUNGSSUMME_NACH_ABSTRICH'
                | translate
              }}</span
            >
            <span>
              {{ detail.rechnungssummeNettoNachAbstrich | format: 'NUMBER.DECIMAL' }}
            </span>
          </div>

          <!--bisherige Teilrechnungen-->
          <div
            class="flex justify-between w-full text-right border-b border-solid pt-1 pb-1 items-center"
          >
            <span class="whitespace-nowrap">
              {{ '#FREIGABE.WIDGET.ABAU_FREIGABE.TEILRECHNUNGEN' | translate }}</span
            >
            <span>{{
              detail.wertBisherigeTeilrechnungen | format: 'NUMBER.DECIMAL'
              }}</span>
          </div>

          <!--Rechnungssumme netto-->
          <div
            class="flex justify-between w-full text-right border-b border-solid pt-1 pb-1 items-center font-bold"
          >
            <span class="whitespace-nowrap">
              {{ '#FREIGABE.WIDGET.ABAU_FREIGABE.RECHNUNGSSUMME_NACH_ABZUG' | translate }}</span
            >
            <span>{{
              detail.rechnungssummeNettoNachAbzug | format: 'NUMBER.DECIMAL'
              }}</span>
          </div>

          <!--MwSt-->
          <div
            class="flex justify-between w-full text-right border-b border-solid pt-1 pb-1 items-center"
          >
            <span class="whitespace-nowrap">
              {{ '#FREIGABE.WIDGET.ABAU_FREIGABE.USTSATZ' | translate | interpolate:detail }}</span
            >
            <span>{{
              detail.wert | format: 'NUMBER.DECIMAL'
              }}</span>
          </div>

          <!--Rechnungssumme brutto-->
          <div
            class="flex justify-between w-full text-right border-b border-solid pt-1 pb-1 items-center font-bold"
          >
            <span class="whitespace-nowrap">
              {{ '#FREIGABE.WIDGET.ABAU_FREIGABE.RECHNUNGSSUMME_BRUTTO' | translate }}</span
            >
            <span>{{
              detail.rechnungssummeBrutto | format: 'NUMBER.DECIMAL'
              }}</span>
          </div>

          <!--DR-->
          <div
            class="flex justify-between w-full text-right border-b border-solid pt-1 pb-1 items-center"
          >
            <span class="whitespace-nowrap">
              {{'#FREIGABE.WIDGET.ABAU_FREIGABE.DR' | translate}}
            </span>
            <span class="flex flex-row w-1/2 justify-between items-center">
              <span class="flex flex-nowrap items-center">
                <soft-number-input
                  formControlName="ruecklassProzent"
                  class="align-right h-6"
                  format="CURRENCY.DEFAULT"
                  [readonly]="form.disabled"
                ></soft-number-input>
                <span class="font-semibold ml-1">%</span>
              </span>
              <span  class="ml-2 whitespace-nowrap">
                {{ detail.ruecklassWert | format: 'NUMBER.DECIMAL' }}
              </span>
            </span>
          </div>

          <!--Zwischensumme brutto-->
          <div
            class="flex justify-between w-full text-right border-b border-solid pt-1 pb-1 items-center"
          >
            <span class="whitespace-nowrap">
              {{ '#FREIGABE.WIDGET.ABAU_FREIGABE.ZWISCHENSUMME_BRUTTO' | translate }}</span
            >
            <span>
              {{ detail.zwischensummeBrutto | format: 'NUMBER.DECIMAL' }}
            </span>
          </div>

          <!--Skonto-->
          <div
            class="flex justify-between w-full text-right border-b border-solid pt-1 pb-1 items-center"
          >
            <span class="whitespace-nowrap">
              {{ '#FREIGABE.WIDGET.ABAU_FREIGABE.SKONTO' | translate }}</span
            >
            <span class="flex flex-row w-1/2 justify-between items-center">
              <span class="flex flex-nowrap items-center">
                <soft-number-input
                  formControlName="skontoProzKu"
                  class="align-right h-6"
                  format="CURRENCY.DEFAULT"
                  [readonly]="form.disabled"
                  #iSkonto
                ></soft-number-input>
                <span class="font-semibold ml-1">%</span>
              </span>
              <span class="ml-2 whitespace-nowrap">
                {{ detail.skontoWertKu | format: 'NUMBER.DECIMAL' }}
              </span>
            </span>
          </div>

          <!--Anzuweisender Betrag-->
          <div
            class="flex justify-between w-full text-right border-b border-solid pt-1 pb-1 items-center font-bold"
          >
            <span class="whitespace-nowrap">
              {{ '#FREIGABE.WIDGET.ABAU_FREIGABE.ANZUWEISENDER_BETRAG' | translate }}</span
            >
            <span>
              {{
              detail.anzuweisenderBetrag | format: 'NUMBER.DECIMAL'
              }}
            </span>
          </div>

        </ng-container>

        <!--Schliussrechnung-->
        <ng-container *ngIf="detail.schlussrechnung">
          <!--Rechnungssumme-->
          <div
            class="flex justify-between w-full text-right border-b border-solid pt-1 pb-1"
          >
            <span class="whitespace-nowrap">
              {{ '#FREIGABE.WIDGET.ABAU_FREIGABE.RECHNUNGSSUMME' | translate }}</span
            >
            <span>{{
              detail.rechnungssummeNetto | format: 'NUMBER.DECIMAL'
              }}</span>
          </div>

          <!--Abstrich-->
          <div
            class="flex justify-between w-full text-right border-b border-solid pt-1 pb-1 items-center"
          >
            <span class="whitespace-nowrap">{{
              '#FREIGABE.WIDGET.ABAU_FREIGABE.ABSTRICH' | translate
              }}</span>
            <div class="flex flex-col w-1/4">
            <soft-number-input
              formControlName="abstrichNetto"
              class="w-full align-right h-6"
              format="CURRENCY.DEFAULT"
              placeholder="#FREIGABE.WIDGET.ABAU_FREIGABE.ABSTRICH_PLACEHOLDE"
              [readonly]="form.disabled"
            ></soft-number-input>

            <soft-validation-container
              [validation]="form|validationMessage:'abstrichNetto'"
              class="soft-property-validation"></soft-validation-container>
          </div>
          </div>

          <!--Rechnungssumme nach Abstrich-->
          <div
            class="flex justify-between w-full text-right border-b border-solid pt-1 pb-1 items-center"
          >
            <span class="whitespace-nowrap">
              {{
              '#FREIGABE.WIDGET.ABAU_FREIGABE.RECHNUNGSSUMME_NACH_ABSTRICH'
                | translate
              }}</span
            >
            <span>
              {{ detail.rechnungssummeNettoNachAbstrich | format: 'NUMBER.DECIMAL' }}
            </span>
          </div>

          <!--Rechnungssumme netto-->
          <div
            class="flex justify-between w-full text-right border-b border-solid pt-1 pb-1 items-center font-bold"
          >
            <span class="whitespace-nowrap">
              {{ '#FREIGABE.WIDGET.ABAU_FREIGABE.RECHNUNGSSUMME_NACH_ABZUG' | translate }}</span
            >
            <span>{{
              detail.rechnungssummeNettoNachAbzug | format: 'NUMBER.DECIMAL'
              }}</span>
          </div>

          <!--MwSt-->
          <div
            class="flex justify-between w-full text-right border-b border-solid pt-1 pb-1 items-center"
          >
            <span class="whitespace-nowrap">
              {{ '#FREIGABE.WIDGET.ABAU_FREIGABE.USTSATZ' | translate | interpolate:detail }}</span
            >
            <span>{{
              detail.wert | format: 'NUMBER.DECIMAL'
              }}</span>
          </div>

          <!--Leistungssumme brutto-->
          <div
            class="flex justify-between w-full text-right border-b border-solid pt-1 pb-1 items-center font-bold"
          >
            <span class="whitespace-nowrap">
              {{ '#FREIGABE.WIDGET.ABAU_FREIGABE.LEISTUNGSSUMME_BRUTTO' | translate }}</span
            >
            <span>{{
              detail.rechnungssummeBrutto | format: 'NUMBER.DECIMAL'
              }}</span>
          </div>

          <!--Skonto-->
          <div
            class="flex justify-between w-full text-right border-b border-solid pt-1 pb-1 items-center"
          >
            <span class="whitespace-nowrap">
              {{ '#FREIGABE.WIDGET.ABAU_FREIGABE.SKONTO' | translate }}</span
            >
            <span class="flex flex-row w-1/2 justify-between items-center">
              <span class="flex flex-nowrap items-center">
                <soft-number-input
                  formControlName="skontoProzKu"
                  class="align-right h-6"
                  format="CURRENCY.DEFAULT"
                  [readonly]="form.disabled"
                  #iSkonto
                ></soft-number-input>
                <span class="font-semibold ml-1">%</span>
              </span>
              <span class="ml-2 whitespace-nowrap">
                {{ detail.skontoWertKu | format: 'NUMBER.DECIMAL' }}
              </span>
            </span>
          </div>

          <!--Rechnungsbetrag-->
          <div
            class="flex justify-between w-full text-right border-b border-solid pt-1 pb-1 items-center font-bold"
          >
            <span class="whitespace-nowrap">
              {{ '#FREIGABE.WIDGET.ABAU_FREIGABE.RECHNUNGSBETRAG' | translate }}</span
            >
            <span>{{
              detail.rechnungsbetragBrutto | format: 'NUMBER.DECIMAL'
              }}</span>
          </div>

          <!--HR-->
          <div
            class="flex justify-between w-full text-right border-b border-solid pt-1 pb-1 items-center"
          >
            <span class="whitespace-nowrap">
              {{'#FREIGABE.WIDGET.ABAU_FREIGABE.HR' | translate}}
            </span>
            <span class="flex flex-row w-1/2 justify-between items-center">
              <span class="flex flex-nowrap items-center">
                <soft-number-input
                  formControlName="ruecklassProzent"
                  class="align-right h-6"
                  format="CURRENCY.DEFAULT"
                  [readonly]="form.disabled"
                ></soft-number-input>
                <span class="font-semibold ml-1">%</span>
              </span>
              <span  class="ml-2 whitespace-nowrap">
                {{ detail.ruecklassWert | format: 'NUMBER.DECIMAL' }}
              </span>
            </span>
          </div>

          <!--Zwischensumme-->
          <div
            class="flex justify-between w-full text-right border-b border-solid pt-1 pb-1 items-center font-bold"
          >
            <span class="whitespace-nowrap">
              {{ '#FREIGABE.WIDGET.ABAU_FREIGABE.ZWISCHENSUMME' | translate }}</span
            >
            <span>{{
              detail.zwischensummeBrutto | format: 'NUMBER.DECIMAL'
              }}</span>
          </div>

          <!--bisherige Teilrechnungen (skontiert)-->
          <div
            class="flex justify-between w-full text-right border-b border-solid pt-1 pb-1 items-center"
          >
            <span class="whitespace-nowrap">
              {{ '#FREIGABE.WIDGET.ABAU_FREIGABE.TEILRECHNUNGEN_SKONTIERT' | translate }}</span
            >
            <span>{{
              detail.wertBisherigeTeilrechnungen | format: 'NUMBER.DECIMAL'
              }}</span>
          </div>

          <!--Anzuweisender Betrag-->
          <div
            class="flex justify-between w-full text-right border-b border-solid pt-1 pb-1 items-center font-bold"
          >
            <span class="whitespace-nowrap">
              {{ '#FREIGABE.WIDGET.ABAU_FREIGABE.ANZUWEISENDER_BETRAG' | translate }}</span
            >
            <span>
              {{
              detail.anzuweisenderBetrag | format: 'NUMBER.DECIMAL'
              }}
            </span>
          </div>

        </ng-container>
      </div>
      <div *ngIf="form.disabled" class="absolute top-0 right-0 bottom-0 left-0 bg-gray-500 bg-opacity-50 w-full h-full">
        <div class="flex w-full h-full items-center align-center justify-center">
          <i class="fas fa-spinner fa-spin fa-4x text-light"></i>
        </div>
      </div>
    </div>
    <div class="mt-2">
      <div class="soft-overline border-b border-solid">
        {{ '#FREIGABE.WIDGET.ABAU_FREIGABE.BEGRUENDUNG' | translate }}
      </div>
      <soft-multiline-input
        formControlName="begruendung"
        [canResize]="true"
        [readonly]="form.disabled"
      ></soft-multiline-input>
    </div>
    <div class="mt-2"
         *ngIf="detail.schlussrechnung || detail.teilrechnung">
      <soft-file-input
        [accept]="'application/pdf'"
        selectionMode="multi">
      </soft-file-input>
    </div>
    <button
      type="submit"
      [disabled]="(saving$ | async) || (formState$ | async) === 'INVALID' || form.disabled"
      class="soft-button primary w-full mt-1 mb-2"
    >
      <i *ngIf="saving$ | async" class="fas fa-spinner fa-spin"></i>
      {{ '#FREIGABE.WIDGET.ABAU_FREIGABE.SUBMIT' | translate }}
    </button>
  </form>
</ng-container>
