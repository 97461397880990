import { WorkflowData } from './workflow-data';

export interface Workflow {
  id: string;
  process: {
    name: string;
    key: string;
    processDisplayName: string;
    state: string;
    startTime: string;
  };
  task: {
    id: string;
    name: string;
    displayName: string;
    priority: number;
    state: string;
    participantsCompactString: string;
    dueDate: string;
    createTime: string;
    asigneeUserBean: {
      name: string;
    };
  };
  availableTasks: {
    id: string;
    name: string;
    displayName: string;
  }[];
  vkforg: unknown;
  ekorg: unknown;
  firma: {
    id: number;
    firma: string;
    kurzbezeichnung: string;
  };
  digest: string;
  barcode: string;
  lastTransition: string;
  lastTransitionUser: string;
  gelesen: boolean;
  wfdata: {
    id: number;
    wfprocesskey: string;
    wfprocessid: string;
    taskenddatum: string;
    status: string;
    startzeit: string;
    enddatum: string;
    erfdatum: string;
  };
  belegart: string;

}
