import {animate, style, transition, trigger} from '@angular/animations';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output,} from '@angular/core';
import moment from 'moment';
import {MomentCalendarService} from '../../../services/moment-calendar.service';
import {DateService, Store} from '@softline/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {I18nModule} from '../../../i18n/i18n.module';
import {L10nModule} from '../../../l10n/l10n.module';
import {UiCorePipesModule} from '../../../pipes/ui-core-pipes.module';
import {SelectComponent} from '../../form/select/select.component';
import {SelectOptionDirective} from '../../form/select/select-option/select-option.directive';
import {ComboBoxComponent} from '../../form/combo-box/combo-box.component';
import {ComboBoxOptionDirective} from '../../form/combo-box/combo-box-option/combo-box-option.directive';
import {SOFTLINE_FEATURE_MODAL} from '../../../modal/modal.shared';
import * as ModalStore from '../../../modal/modal.store';
import {DateParser} from '../../../services/date.parser';

export interface Day {
  date: moment.Moment;
  day: number;
}
export interface Week {
  number: number;
  days: Day[];
}
export interface Month {
  year: number;
  month: number;
  weeks: Week[];
}

@Component({
  selector: 'soft-date-picker',
  animations: [
    trigger('showHide', [
      transition(':enter', [
        style({ opacity: 0, clipPath: 'inset(0px 0px 100% 0px)' }),
        animate(
          '150ms',
          style({ opacity: 1, clipPath: 'inset(0px 0px 0px 0%)' })
        ),
      ]),
      transition(':leave', [
        animate(
          '150ms',
          style({ opacity: 0, clipPath: 'inset(0px 0px 100% 0px)' })
        ),
      ]),
    ]),
  ],
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    I18nModule,
    L10nModule,
    UiCorePipesModule,
    SelectComponent,
    SelectOptionDirective,
    ComboBoxComponent,
    ComboBoxOptionDirective,
  ],
})
export class DatePickerComponent implements OnInit {
  private _month = moment(this.dateService.today()).month();
  private _year = moment(this.dateService.today()).year();

  hasSelected = false;

  _value: moment.Moment = moment(this.dateService.today());
  monthView?: Month;

  get today(): string {
    return moment(this.dateService.today()).toISOString();
  }

  get month(): number {
    return this._month;
  }
  set month(value: number) {
    const update = this._month !== value;
    this._month = value;
    if (update) this.monthView = this.createMonthView(this.year, this.month);
  }

  get year(): number {
    return this._year;
  }
  set year(value: number) {
    if (typeof value === 'string')
      value = +value;

    const update = this._year !== value;
    this._year = value;
    if (update) this.monthView = this.createMonthView(this.year, this.month);
  }

  @Input()
  get value(): string {
    return this._value.toISOString(true);
  }
  set value(value: string) {
    this._value = moment(value);
    const update =
      this._month !== this._value.month() || this._year !== this._value.year();
    this._month = this._value.month();
    this._year = this._value.year();

    if (update) this.monthView = this.createMonthView(this.year, this.month);
  }

  @Output() valueChange = new EventEmitter<string>();
  @Output() submit = new EventEmitter<string>();

  readonly shortcuts: { value: string; label: string }[] = [
    {
      label: 'Gestern',
      value: 'h-1'
    },
    {
      label: 'Vor einer Woche',
      value: 'h-7'
    },
    {
      label: 'Vor einem Monat',
      value: 'h-1m'
    },
    {
      label: 'Vor einem Jahr',
      value: 'h-1j'
    },
    {
      label: 'Wochenanfang',
      value: 'wa'
    },
    {
      label: 'Monatsanfang',
      value: 'ma'
    },
    {
      label: 'Jahresanfang',
      value: 'ja'
    },
  ];

  constructor(
    readonly dateService: DateService,
    readonly dateParser: DateParser<moment.Moment>,
    readonly calendarService: MomentCalendarService,
    readonly store: Store
  ) {
    const today = moment(dateService.today());
    this._month = today.month();
    this._year = today.year();
  }

  ngOnInit(): void {
    this.hasSelected = false;

    if (!this.monthView)
      this.monthView = this.createMonthView(this.year, this.month);
  }

  private createMonthView(year: number, month: number): Month {
    const monthView: Month = { year, month, weeks: [] };
    const currentDay = moment(new Date(year, month));
    while (currentDay.weekday() > 0) currentDay.add(-1, 'day');

    while (
      (currentDay.month() <= month && currentDay.year() === year) ||
      currentDay.year() < year
    ) {
      const week: Week = { number: currentDay.isoWeek(), days: [] };
      for (let i = 0; i < 7; i++) {
        const day: Day = { date: currentDay.clone(), day: currentDay.date() };
        week.days.push(day);
        currentDay.add(1, 'day');
      }
      monthView.weeks.push(week);
    }

    return monthView;
  }

  previousMonth(): void {
    if (this.month <= 0)
      this.month = 11;
    else
      this.month = Math.max(this.month - 1, 0);
  }

  nextMonth(): void {
    if (this.month >= 11)
      this.month = 0;
    else
      this.month = Math.min(this.month + 1, 11);
  }

  onSelect(date: moment.Moment): void {
    this.hasSelected = true;
    this.value = date.toISOString();
    this.valueChange.emit(date.toISOString());
  }

  onSubmit(date: moment.Moment): void {
    this.onSelect(date);
    this.submit.emit(this.value);
  }

  selectShortcut(shortcut:  { value: string; label: string }): void {
    const parsedDate = this.parseShortcutDate(shortcut.value);

    if (!parsedDate)
      return;

    this.onSelect(parsedDate);
  }

  async openShortcutsList(): Promise<void> {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.choose<string>(),
      {
        title: 'Shortcuts',
        dismiss: true,
        options: this.shortcuts.map(o => ({
          value: o.value,
          label: o.label
        }))
      }
    );

    if (!result || result === 'DISMISSED')
      return;

    const shortcut = this.shortcuts.find(o => o.value === result);

    if (!shortcut)
      return;

    this.selectShortcut(shortcut);
  }

  private parseShortcutDate(value: string): moment.Moment | null {
    const result = this.dateParser.parse(value);

    if (!result)
      return null;

    return moment.isMoment(result) ? result : moment(result);
  }
}
