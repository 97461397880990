import {
  ActionStore,
  createAction,
  createMutation,
  RemoteEntityDetailStore,
  EntityStore,
  mutate,
  on,
  RemoteEntityStore,
  SOFTLINE_SERVICE_UUID,
  SOFTLINE_STORE_ENTITY_SERVICE,
  StoreBuilder,
  StoreFeature,
  SubscriptionStore,
  CollectionStore,
  createGetter,
  select,
} from '@softline/core';
import { Beleg } from '../types/beleg';
import { Kopfwert } from '../types/kopfwert';
import { Buchungsbrief } from '../types/buchungsbrief';
import { NavigationExtras, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { BuchungsbriefService } from '../services/buchungsbrief.service';

export interface BuchungsbriefState extends EntityStore.State {
  fromRoute: { url: any[]; extras?: NavigationExtras };
  buchungsbrief?: unknown;
}

export interface CreateBuchungsbriefParameters {
  token?: string;
  buchungsbrief: Buchungsbrief;
}

export class BuchungsbriefExtension {
  static initialState = {
    buchungsbrief: undefined,
  };
  static mutations = {
    set: createMutation<BuchungsbriefState, Partial<BuchungsbriefState>>('set'),
    setBuchungsbrief: createMutation<BuchungsbriefState, unknown>(
      'setBuchungsbrief'
    ),
  };
  static getters = {
    buchungsbrief: createGetter<BuchungsbriefState, Buchungsbrief>(
      'buchungsbrief'
    ),
  };
  static actions = {
    createBuchungsbrief: createAction<
      EntityStore.State,
      CreateBuchungsbriefParameters,
      Beleg
    >('createBuchungsbrief'),
  };
  static feature: StoreFeature<any> = {
    initialState: {},
    mutations: [
      mutate(BuchungsbriefExtension.mutations.set, ({ state, params }) => ({
        ...state,
        ...params,
      })),
      mutate(
        BuchungsbriefExtension.mutations.setBuchungsbrief,
        ({ state, params }) => ({ ...state, buchungsbrief: params })
      ),
    ],
    getters: [
      select(
        BuchungsbriefExtension.getters.buchungsbrief,
        ({ state }) => state.buchungsbrief
      ),
    ],
    actions: [
      on(
        BuchungsbriefExtension.actions.createBuchungsbrief,
        async ({ state, featureName, injector, params, commit }) => {
          const service = injector.get(BuchungsbriefService);
          const token = params.token ?? injector.get(SOFTLINE_SERVICE_UUID)();
          const subscription$ = SubscriptionStore.handleSubscriptionState(
            service.create(params.buchungsbrief),
            featureName,
            commit,
            token
          );
          const result = await lastValueFrom(
            ActionStore.handleObservableActionState(
              subscription$,
              featureName,
              commit,
              BuchungsbriefExtension.actions.createBuchungsbrief.name,
              token
            )
          );
          return result;
        }
      ),
    ],
  };
}

export const BuchungsbriefStore = new StoreBuilder(
  RemoteEntityStore.create<Beleg>()
)
  .add(RemoteEntityDetailStore.create<Beleg, { kopfwerte: Kopfwert[] }>())
  .add(BuchungsbriefExtension).value;
