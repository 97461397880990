<ng-container *ngIf="isLoading$ | async; else loadedTemplate">
  <div class="soft-card">
    <div class="c row center">
      <soft-loading-spinner></soft-loading-spinner>
    </div>
  </div>
</ng-container>

<ng-template #loadedTemplate>
  <ng-container *ngIf="files$ | async as files">
    <div *ngIf="showFilter" class="w-full">
      <soft-input
        (input)="filter$.next(filterInput.value)"
        placeholder="{{ '#APPLICATION.ARCHIVE.FILTER' | translate }}"
        #filterInput
      >
      </soft-input>
    </div>
    <ng-container *ngIf="files.length > 0; else noItemsTemplate">
      <div class="soft-card mt-3" *ngFor="let file of files">
        <div class="c row space-between no-wrap pt-2 pb-2 pl-1 pr-1 border-box">
          <div class="c column truncate">
            <span class="soft-title truncate mb-1">{{file.metaData.title ?? file.metaData.name}}</span>
            <span *ngIf="file.metaData.summary" class="soft-text xs color color-light">{{file.metaData.summary}}</span>
            <span *ngIf="file.metaData.title"  class="soft-text xs color color-light">{{file.metaData.name}}</span>
            <span class="soft-text xs color color-light"
              >{{ '#APPLICATION.ARCHIVE.FILESIZE' | translate }}
              {{ file.metaData.size | filesize }}</span
            >
            <span class="soft-text xs color color-light"
              >{{ '#APPLICATION.ARCHIVE.LAST_MODIFIED' | translate }}
              {{ file.metaData.lastModified | format: 'DATE.DEFAULT' }}</span
            >
          </div>
          <div class="flex no-wrap">
            <i
              *ngIf="{file, files} | canPreview"
              class="fas fa-eye color-light mr-4 pointer"
              (click)="preview({file, files})"
            ></i>
            <i
              *ngIf="
                !(file.fileKey | isArchiveDownloading | async);
                else fileLoadingTemplate
              "
              class="fas fa-cloud-download-alt custom-color pointer"
              (click)="download(file)"
            ></i>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #noItemsTemplate>
  <div class="soft-card mt-3">
    <div class="c row center">
      {{ '#APPLICATION.ARCHIVE.NO_FILES' | translate }}
    </div>
  </div>
</ng-template>
<ng-template #fileLoadingTemplate>
  <i class="fas fa-spinner fa-spin"></i>
</ng-template>
