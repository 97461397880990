<h2 class="text-xl font-semibold mt-3 ml-3">
  {{ title ?? '#UI_CORE.DIALOGS.SIGNATURE' | translate | interpolate: params }}
</h2>
<p *ngIf="subtitle" class="mb-1 mt-1 ml-3 mr-3 border-box color-light">
  {{ subtitle | translate | interpolate: params }}
</p>
<p *ngIf="content" class="mb-1 mt-1 ml-3 mr-3 border-box">
  <ng-container *ngTemplateOutlet="content; context: (params ?? null)"></ng-container>
</p>

<div class="mt-2 mx-3 w-full">
  <div class="signature-modal-input" #container>
    <soft-signature-pad
      #signaturepad
      [options]="{
        minWidth: 2,
        canvasWidth: container.offsetWidth - 32,
        canvasHeight: container.offsetHeight + 2
      }"
    ></soft-signature-pad>
  </div>
</div>
<div class="flex no-wrap mt-3">
  <button
    class="soft-button primary ok h-14 w-full"
    (click)="
      signaturepad.isEmpty()
        ? onSubmit(null)
        : onSubmit(signaturepad.toDataURL())
    "
  >
    {{ '#UI_CORE.DIALOGS.OK' | translate }}
  </button>
</div>
