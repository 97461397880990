import {
  CollectionStore,
  FilterCollectionStore,
  MultiSelectStore,
  RemoteQueryStore,
  RemoteStore,
  SortCollectionStore,
  StoreBuilder
} from "@softline/core";
import { Beleg } from "../types/beleg.model";

export const BelegStore = new StoreBuilder(CollectionStore.create<Beleg>())
  .add(RemoteStore)
  .add(RemoteQueryStore.create<object>())
  .add(FilterCollectionStore.create<Beleg>())
  .add(SortCollectionStore.create<Beleg>())
  .add(MultiSelectStore.create<Beleg>()).value;
