import { Entity } from '../types/entity';
import {
  createAction,
  createGetter,
  createMutation,
  mutate,
  on,
  select,
} from '../../../factories';
import { Dictionary } from '../../../../types/dictionary';
import { StoreFeature } from '../../../store';
import { SOFTLINE_STORE_TEMPLATE_SERVICE } from '../single/entity.shared';

export interface LoadActionParameters {
  queryParams?: Dictionary<unknown>;
  pathParams?: Dictionary<unknown>;
  data?: Dictionary<unknown>;
}

export interface State<T extends Entity = Entity> {
  template: Partial<T> | null;
  templateLoading: boolean;
  templateLoaded: boolean;
}

export class Store<T extends Entity = Entity> {
  mutations = {
    template: {
      setTemplate: createMutation<State<T>, Partial<T> | null>('set template'),
      loading: createMutation<State<T>>('template loading'),
      loadSuccess: createMutation<State<T>, Partial<T>>(
        'template load success'
      ),
    },
  };

  actions = {
    template: {
      load: createAction<State<T>, LoadActionParameters, Partial<T>>(
        'load template'
      ),
    },
  };

  getters = {
    template: {
      template: createGetter<State<T>, Partial<T>>('template'),
      loading: createGetter<State<T>, boolean>('template loading'),
      loaded: createGetter<State<T>, boolean>('template loaded'),
    },
  };

  feature: StoreFeature<State<T>> = {
    initialState: {
      template: {},
      templateLoading: false,
      templateLoaded: false,
    },
    mutations: [
      mutate(this.mutations.template.setTemplate, ({ state, params }) => ({
        ...state,
        template: params,
      })),
      mutate(this.mutations.template.loading, ({ state }) => ({
        ...state,
        templateLoading: true,
      })),
      mutate(this.mutations.template.loadSuccess, ({ state, params }) => ({
        ...state,
        templateLoading: false,
        templateLoaded: true,
        template: params,
      })),
    ],
    actions: [
      on(
        this.actions.template.load,
        async ({ store, featureName, params, injector }) => {
          const service = injector.get(SOFTLINE_STORE_TEMPLATE_SERVICE);
          store.commit(featureName, this.mutations.template.loading);
          const template = await service.loadTemplate(
            params?.data,
            params?.pathParams,
            params?.queryParams
          );
          store.commit(
            featureName,
            this.mutations.template.loadSuccess,
            template
          );
          return template;
        }
      ),
    ],
    getters: [
      select(
        this.getters.template.loading,
        ({ state }) => state.templateLoading
      ),
      select(this.getters.template.loaded, ({ state }) => state.templateLoaded),
      select(this.getters.template.template, ({ state }) => state.template),
    ],
  };
}

export function create<T extends Entity>(): Store<T> {
  return new Store<T>();
}

const instance = create();
export const mutations = instance.mutations;
export const getters = instance.getters;
export const actions = instance.actions;
export const feature = instance.feature;
