import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  QueryList,
} from '@angular/core';
import {ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule} from '@angular/forms';
import {ComboBoxOptionDirective} from './combo-box-option/combo-box-option.directive';
import {serializeHtmlCharacterEntitiesFunction} from "../../../functions/html-character-entities.function";
import {equals} from '@softline/core';
import {CommonModule} from '@angular/common';
import {UiCorePipesModule} from '../../../pipes/ui-core-pipes.module';
import {UiCoreDirectivesModule} from '../../../directives/ui-core-directives.module';
import {I18nModule} from '../../../i18n/i18n.module';
import {L10nModule} from '../../../l10n/l10n.module';

@Component({
  selector: 'soft-combo-box',
  standalone: true,
  templateUrl: './combo-box.component.html',
  styleUrls: ['./combo-box.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ComboBoxComponent),
      multi: true,
    },
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UiCorePipesModule,
    UiCoreDirectivesModule,
    I18nModule,
    L10nModule,
  ]
})
export class ComboBoxComponent implements AfterContentInit, ControlValueAccessor {
  isOpen = false;

  selectedOption?: ComboBoxOptionDirective;

  get value(): any {
    return this.selectedOption?.value ?? this._value;
  }
  @Input()
  set value(value: any) {
    if (value !== undefined && this._value !== value) {
      this._value = value;
      console.log('setting value: ', value);
      this.selectedOption = this.options?.find((o) => equals(o.value, value));
      this.writeValue(value);
      this.onChange(value);
    }
  }

  @Input() selectOnFocus = false;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() readonly = false;
  @Input() placeholder?: string;
  @Input() escapeHtml = false;

  @Input() updateOn: 'input' | 'blur' = 'input';

  private _value: any;

  @ContentChildren(ComboBoxOptionDirective)
  options!: QueryList<ComboBoxOptionDirective>;

  private onChange: Function = () => {};
  onTouch: Function = () => {};

  ngAfterContentInit(): void {
    this.selectedOption = this.options?.find((o) => equals(o.value, this.value));
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  onSelect(value: any): void {
    this.isOpen = false;
    this.setValue(value);
  }

  onOutsideClick(): void {
    if (this.isOpen)
      this.isOpen = false;
    this.onTouch();
  }

  selectText(target: EventTarget | null): void {
    if (this.selectOnFocus && target instanceof HTMLInputElement)
      target.select();
  }

  setValue(value: string | null): void {
    if (value && this.escapeHtml)
      value = serializeHtmlCharacterEntitiesFunction(value);

    this.value = value;
    this.valueChange.emit(this.value);
  }
}
