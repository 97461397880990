import { Store } from "@softline/core";
import { ENVIRONMENT_INITIALIZER, inject } from "@angular/core";
import { SOFTLINE_FEATURE_TRANSLATION, TranslationStore } from "@softline/ui-core";
import { default as de } from "../i18n/de.json";
import { artikelProviders } from "./artikel/artikel.providers";
import { SOFTLINE_CONFIG_FIELD_OK } from "@softline/dynamic";
import {
  ktostammKundeFieldOk,
  ktostammKundeMultiselectFieldOk, ktostammKundeQueryFieldOk
} from "./field-ok/ktostamm-kunde/ktostamm-kunde.field-ok";
import { objektFieldOk, objektMultiselectFieldOk, objektQueryFieldOk } from "./field-ok/objekt/objekt.field-ok";
import {
  kostenstelleFieldOk,
  kostenstelleMultiselectFieldOk,
  kostenstelleQueryFieldOk
} from "./field-ok/kostenstelle/kostenstelle.field-ok";
import { artehFieldOk, artehMultiselectFieldOk, artehQueryFieldOk } from "./field-ok/arteh/arteh.field-ok";
import { fahrzeugFieldOk, fahrzeugMultiselectFieldOk, fahrzeugQueryFieldOk } from "./field-ok/fahrzeug/fahrzeug.field-ok";
import {
  landeskennzeichenFieldOk,
  landeskennzeichenMultiselectFieldOk, landeskennzeichenQueryFieldOk
} from "./field-ok/landeskennzeichen/landeskennzeichen.field-ok";
import { orteFieldOk, orteMultiselectFieldOk, orteQueryFieldOk } from "./field-ok/orte/orte.field-ok";
import { vkforgFieldOk, vkforgMultiselectFieldOk, vkforgQueryFieldOk } from "./field-ok/vkforg/vkforg.field-ok";
import { faelldtFieldOk, faelldtMultiselectFieldOk, faelldtQueryFieldOk } from "./field-ok/faelldt/faelldt.field-ok";
import {
  zbewegungsartFieldOk,
  zbewegungsartMultiselectFieldOk,
  zbewegungsartQueryFieldOk
} from "./field-ok/zbewegungsart/zbewegungsart.field-ok";
import {
  wtaetigkeitFieldOk,
  wtaetigkeitMultiselectFieldOk,
  wtaetigkeitQueryFieldOk
} from "./field-ok/wtaetigkeit/wtaetigkeit.field-ok";
import {
  verkaufsartwerkstattFieldOk, verkaufsartwerkstattMultiselectFieldOk,
  verkaufsartwerkstattQueryFieldOk
} from "./field-ok/verkaufsartwerkstatt/verkaufsartwerkstatt.field-ok";
import { lagerortFieldOk, lagerortMultiselectFieldOk, lagerortQueryFieldOk } from "./field-ok/lagerort/lagerort.field-ok";
import {
  lagerstammFieldOk,
  lagerstammMultiselectFieldOk,
  lagerstammQueryFieldOk
} from "./field-ok/lagerstamm/lagerstamm.field-ok";
import {
  maschineFieldOk,
  maschineMultiselectFieldOk,
  maschineQueryFieldOk
} from "./field-ok/maschine/maschine.field-ok";
import {
  fibperFieldOk,
  fibperMultiselectFieldOk,
  fibperQueryFieldOk
} from "./field-ok/fibper/fibper.field-ok";
import {
  lagerLagerplatzFieldOk,
  lagerLagerplatzMultiselectFieldOk, lagerLagerplatzQueryFieldOk
} from "./field-ok/lager-lagerplatz/lager-lagerplatz.field-ok";
import { chargeFieldOk, chargeMultiselectFieldOk, chargeQueryFieldOk } from "./field-ok/charge/charge.field-ok";
import {
  artstammFieldOk,
  artstammMultiselectFieldOk,
  artstammQueryFieldOk
} from "./field-ok/artstamm/artstamm.field-ok";
import {
  ktostammLieferantFieldOk,
  ktostammLieferantMultiselectFieldOk, ktostammLieferantQueryFieldOk
} from "./field-ok/ktostamm-lieferant/ktostamm-lieferant.field-ok";
import {
  ktostammKundeSubnummerFieldOk,
  ktostammKundeSubnummerMultiselectFieldOk, ktostammKundeSubnummerQueryFieldOk
} from "./field-ok/ktostamm-kunde-subnummer/ktostamm-kunde-subnummer.field-ok";
import {
  kundeslaFieldOk,
  kundeslaMultiselectFieldOk,
  kundeslaQueryFieldOk
} from "./field-ok/kundesla/kundesla.field-ok";
import {
  ticketcobitkatFieldOk,
  ticketcobitkatMultiselectFieldOk, ticketcobitkatQueryFieldOk
} from "./field-ok/ticketcobitkat/ticketcobitkat.field-ok";
import {
  fehlerartFieldOk,
  fehlerartMultiselectFieldOk,
  fehlerartQueryFieldOk
} from "./field-ok/fehlerart/fehlerart.field-ok";
import { auftragFieldOk, auftragMultiselectFieldOk, auftragQueryFieldOk } from "./field-ok/auftrag/auftrag.field-ok";
import {einheiteneingabeProviders} from "./einheiteneingabe/einheiteneingabe.providers";
import { lstfixFieldOk, lstfixFieldOkMultiselectFieldOk, lstfixQueryFieldOk } from "./field-ok/lstfix/lstfix.field-ok";

export const wwsCoreProviders = [
  ...artikelProviders,
  ...einheiteneingabeProviders,
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: artehFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: artehMultiselectFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: artehQueryFieldOk},

  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: fahrzeugFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: fahrzeugMultiselectFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: fahrzeugQueryFieldOk},

  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: ktostammKundeFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: ktostammKundeMultiselectFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: ktostammKundeQueryFieldOk},

  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: objektFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: objektMultiselectFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: objektQueryFieldOk},

  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: kostenstelleFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: kostenstelleMultiselectFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: kostenstelleQueryFieldOk},

  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: landeskennzeichenFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: landeskennzeichenMultiselectFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: landeskennzeichenQueryFieldOk},

  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: orteFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: orteMultiselectFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: orteQueryFieldOk},

  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: vkforgFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: vkforgMultiselectFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: vkforgQueryFieldOk},

  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: faelldtFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: faelldtMultiselectFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: faelldtQueryFieldOk},

  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: zbewegungsartFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: zbewegungsartMultiselectFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: zbewegungsartQueryFieldOk},

  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: wtaetigkeitFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: wtaetigkeitMultiselectFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: wtaetigkeitQueryFieldOk},

  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: verkaufsartwerkstattFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: verkaufsartwerkstattMultiselectFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: verkaufsartwerkstattQueryFieldOk},

  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: lagerortFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: lagerortMultiselectFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: lagerortQueryFieldOk},

  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: lagerstammFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: lagerstammMultiselectFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: lagerstammQueryFieldOk},

  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: maschineFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: maschineMultiselectFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: maschineQueryFieldOk},

  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: fibperFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: fibperMultiselectFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: fibperQueryFieldOk},

  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: lagerLagerplatzFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: lagerLagerplatzMultiselectFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: lagerLagerplatzQueryFieldOk},

  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: chargeFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: chargeMultiselectFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: chargeQueryFieldOk},

  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: artstammFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: artstammMultiselectFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: artstammQueryFieldOk},

  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: ktostammLieferantFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: ktostammLieferantMultiselectFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: ktostammLieferantQueryFieldOk},

  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: ktostammKundeSubnummerFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: ktostammKundeSubnummerMultiselectFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: ktostammKundeSubnummerQueryFieldOk},

  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: kundeslaFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: kundeslaMultiselectFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: kundeslaQueryFieldOk},

  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: ticketcobitkatFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: ticketcobitkatMultiselectFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: ticketcobitkatQueryFieldOk},

  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: fehlerartFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: fehlerartMultiselectFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: fehlerartQueryFieldOk},

  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: auftragFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: auftragMultiselectFieldOk},
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: auftragQueryFieldOk},

  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: lstfixFieldOk },
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: lstfixFieldOkMultiselectFieldOk },
  {provide: SOFTLINE_CONFIG_FIELD_OK, multi: true, useValue: lstfixQueryFieldOk },

  {
    provide: ENVIRONMENT_INITIALIZER,
    useValue: () => {
      const store = inject(Store);
      store.commit(SOFTLINE_FEATURE_TRANSLATION, TranslationStore.mutations.add, {
        module: 'wws-core',
        language: 'de',
        translations: de
      });
    },
    multi: true
  }
]
