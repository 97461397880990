import { Routes } from '@angular/router';

export const ssoRoutes: Routes = [
  {
    path: 'sso',
    loadComponent: () =>
      import('./pages/sso/sso.page').then(
        (o) => o.SsoPage
      ),
  },
];
