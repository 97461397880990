import { ApplicationConfig } from '@angular/core';
import {
  enableProdMode,
  ENVIRONMENT_INITIALIZER,
  importProvidersFrom,
  inject,
  LOCALE_ID,
} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { routes } from './app.routes';
import {
  addDateCalculationStrategy,
  calculateDate,
  ConnectionModule,
  CoreModule,
  Store,
  StoreModule,
  subtractDateCalculationStrategy,
} from '@softline/core';
import {
  SOFTLINE_CONFIG_WIDGETS,
  SOFTLINE_FEATURE_TRANSLATION,
  TranslationStore,
  UiCoreModule,
} from '@softline/ui-core';
import { environment } from '../environments/environment';
import {
  AuthenticationModule,
  AuthorizationModule,
  JwtAuthenticationModule,
} from '@softline/auth';
import {
  AddOn,
  AppearanceModule,
  ApplicationModule,
  MasterDataModule,
  OfflineModule,
} from '@softline/application';
import { DynamicModule, SOFTLINE_CONFIG_DEFINITION } from '@softline/dynamic';
import {
  accountContextProviders,
  contextSettingsProviders,
  firmaContextProviders,
  settingsProviders,
  SOFTLINE_CONFIG_CONTEXT_ACCOUNT,
  userContextProviders,
  utilitiesProviders,
} from '@softapps/allgemein/utils';
import { pdfViewerProviders } from '@softapps/allgemein/pdf-viewer';
import { pdfEditorProviders } from '@softapps/allgemein/pdf-editor';
import { loginProviders } from '@softapps/allgemein/login';
import { ssoProviders } from '@softapps/allgemein/sso';
import { moduleProviders } from '@softapps/allgemein/modules';
import {
  ArchivbelegDownloadStrategy,
  BelegDownloadStrategy,
  DatentraegerDownloadStrategy,
  FremdbelegDownloadStrategy,
  gesamtabfrageProviders,
  SOFTLINE_CONFIG_GESAMTABFRAGE,
  SOFTLINE_DEFINITION_VOUCHER_BUSINESS_CASE,
  SOFTLINE_DEFINITION_VOUCHER_LIST,
  SOFTLINE_DEFINITION_VOUCHER_QUERY,
} from '@softapps/wws/gesamtabfrage';
import { subkundenProviders } from '@softapps/wws/subkunden';
import {
  freigabeProviders,
  SOFTLINE_DEFINITION_FREIGABE_DETAIL,
  SOFTLINE_DEFINITION_FREIGABE_LIST,
  SOFTLINE_WIDGET_SOFTAPPS_FIBU_FREIGABE,
} from '@softapps/fibu/freigabe';
import {
  haftruecklassProviders,
  SOFTLINE_CONFIG_HAFTRUECKLASS,
  SOFTLINE_DEFINITION_HAFTRUECKLASS_LIST,
  SOFTLINE_DEFINITION_HAFTRUECKLASS_QUERY,
} from '@softapps/fibu/haftruecklass';
import { offenePostenProviders } from '@softapps/fibu/offene-posten';
import { buchungsbriefProviders } from '@softapps/abau/buchungsbrief';
import { platform } from '../platforms/platform';

import { default as de } from '../i18n/de.json';
import { default as opListDefinition } from '../definitions/haftruecklass/haftruecklass-list.definition.json';
import { default as opQueryDefinition } from '../definitions/haftruecklass/haftruecklass-query.definition.json';
import { default as rechnungListDefinition } from '../definitions/gesamtabfrage/rechnung/gesamtabfrage-list.definition.json';
import { default as rechnungQueryDefinition } from '../definitions/gesamtabfrage/rechnung/gesamtabfrage-query.definition.json';
import { default as rechnungspruefungListDefinition } from '../definitions/gesamtabfrage/rechnungspruefung/gesamtabfrage-list.definition.json';
import { default as rechnungspruefungQueryDefinition } from '../definitions/gesamtabfrage/rechnungspruefung/gesamtabfrage-query.definition.json';
import { default as businessCaseDefinition } from '../definitions/gesamtabfrage/business-case-list.definition.json';
import { default as freigabeListDefinition } from '../definitions/freigabe/freigabe-list.definition.json';
import { default as freigabeDetailDefinition } from '../definitions/freigabe/freigabe-details.definition.json';

import { ChangeBaustelleAddOn } from '../add-ons/change-baustelle-addon';
import { AbauFreigabeWidget } from '../widgets/abau-freigabe/abau-freigabe.widget';
import { wwsCoreProviders } from "@softapps/wws/core";

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      BrowserAnimationsModule,
      RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' }),

      // Framework
      CoreModule.forRoot(),
      StoreModule.forRoot(),
      UiCoreModule.forRoot(),
      ConnectionModule.forRoot(environment.connection),
      AuthenticationModule.forRoot(),
      JwtAuthenticationModule.forRoot(),
      AuthorizationModule.forRoot(),
      ApplicationModule.forRoot(),
      AppearanceModule.forRoot(),
      DynamicModule.forRoot(),
      MasterDataModule.forRoot(),
      OfflineModule.forRoot()
    ),

    //Allgemein
    ...utilitiesProviders,
    ...contextSettingsProviders,
    ...userContextProviders,
    ...accountContextProviders,
    ...settingsProviders,
    // ...vkforgContextProviders,
    // ...firmaContextProviders,

    ...wwsCoreProviders,

    ...pdfViewerProviders,
    ...pdfEditorProviders,
    ...loginProviders,
    ...ssoProviders,
    ...moduleProviders,

    ...gesamtabfrageProviders,
    ...subkundenProviders,
    ...freigabeProviders,
    ...haftruecklassProviders,
    ...offenePostenProviders,
    ...buchungsbriefProviders,

    ...platform.providers,
    { provide: LOCALE_ID, useValue: 'de' },
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => {
        const store = inject(Store);
        store.commit(
          SOFTLINE_FEATURE_TRANSLATION,
          TranslationStore.mutations.add,
          {
            module: 'abau',
            language: 'de',
            translations: de,
          }
        );
      },
      multi: true,
    },
    {
      provide: SOFTLINE_CONFIG_HAFTRUECKLASS,
      useValue: {
        nurHaftRuecklaesse: true,
      },
    },
    {
      provide: SOFTLINE_CONFIG_GESAMTABFRAGE,
      useValue: {
        geschaeftsfall: true,
        download: [
          {
            belegart: 'Rechnung',
            strategies: [
              BelegDownloadStrategy,
              FremdbelegDownloadStrategy,
              DatentraegerDownloadStrategy,
            ],
          },
          {
            belegart: 'Rechnungspruefung',
            strategies: [ArchivbelegDownloadStrategy],
          },
        ],
      },
    },
    { provide: AddOn, useClass: ChangeBaustelleAddOn, multi: true },
    {
      provide: SOFTLINE_CONFIG_CONTEXT_ACCOUNT,
      useValue: {
        widgetLinkColor: '#EDCCCA !important',
      },
    },
    // {provide: SOFTLINE_CONFIG_LOAD_CUSTOM_DEFINITIONS, useValue: true},
    {
      provide: SOFTLINE_CONFIG_DEFINITION,
      useValue: {
        name: SOFTLINE_DEFINITION_HAFTRUECKLASS_QUERY,
        definition: opQueryDefinition,
        priority: 1,
      },
      multi: true,
    },
    {
      provide: SOFTLINE_CONFIG_DEFINITION,
      useValue: {
        name: SOFTLINE_DEFINITION_HAFTRUECKLASS_LIST,
        definition: opListDefinition,
        priority: 1,
      },
      multi: true,
    },
    {
      provide: SOFTLINE_CONFIG_DEFINITION,
      useValue: {
        name: `${SOFTLINE_DEFINITION_VOUCHER_LIST}+Rechnung`,
        definition: rechnungListDefinition,
        priority: 1,
      },
      multi: true,
    },
    {
      provide: SOFTLINE_CONFIG_DEFINITION,
      useValue: {
        name: `${SOFTLINE_DEFINITION_VOUCHER_QUERY}+Rechnung`,
        definition: rechnungQueryDefinition,
        priority: 1,
      },
      multi: true,
    },
    {
      provide: SOFTLINE_CONFIG_DEFINITION,
      useValue: {
        name: `${SOFTLINE_DEFINITION_VOUCHER_LIST}+Rechnungspruefung`,
        definition: rechnungspruefungListDefinition,
        priority: 1,
      },
      multi: true,
    },
    {
      provide: SOFTLINE_CONFIG_DEFINITION,
      useValue: {
        name: `${SOFTLINE_DEFINITION_VOUCHER_QUERY}+Rechnungspruefung`,
        definition: rechnungspruefungQueryDefinition,
        priority: 1,
      },
      multi: true,
    },
    {
      provide: SOFTLINE_CONFIG_DEFINITION,
      useValue: {
        name: `${SOFTLINE_DEFINITION_VOUCHER_BUSINESS_CASE}`,
        definition: businessCaseDefinition,
        priority: 1,
      },
      multi: true,
    },
    {
      provide: SOFTLINE_CONFIG_DEFINITION,
      useValue: {
        name: `${SOFTLINE_DEFINITION_FREIGABE_LIST}`,
        definition: freigabeListDefinition,
        priority: 1,
      },
      multi: true,
    },
    {
      provide: SOFTLINE_CONFIG_DEFINITION,
      useValue: {
        name: `${SOFTLINE_DEFINITION_FREIGABE_DETAIL}`,
        definition: freigabeDetailDefinition,
        priority: 1,
      },
      multi: true,
    },
    {
      provide: SOFTLINE_CONFIG_WIDGETS,
      multi: true,
      useValue: {
        outlet: SOFTLINE_WIDGET_SOFTAPPS_FIBU_FREIGABE,
        component: AbauFreigabeWidget,
      },
    },
  ],
};
