<div class="flex justify-between flex-wrap w-full mt-4">
  <h1 class="text-2xl font-bold">
    {{ '#GESAMTABFRAGE.PAGES.TYPE.HEADER' | translate }}
  </h1>
</div>
<hr class="my-4" />
<div class="grid-container">
  <div
    *ngFor="let belegart of belegarten$ | async"
    class="grid-item-container"
    [routerLink]="[belegart.id, 'find']"
  >
    <span class="application-icon">
      <i class="fas fas fa-file-invoice fa-4x"></i>
    </span>
    <div class="soft-container soft-container-center set-width">
      <span class="application-title">{{ belegart?.kundenName ?? belegart?.name }}</span>
    </div>
  </div>
</div>

<ng-template #defaultIcon>
  <i class="fas fa-ban fa-3x"></i>
</ng-template>
