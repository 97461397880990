<div class="w-100 flex flex-col flex-grow">
  <div class="app-header c mb-1">
    <soft-input
      placeholder="{{ '#APP_OVERVIEW.SEARCH' | translate }}"
      (valueChange)="search = $event ?? ''"
    ></soft-input>
    <button
      type="button"
      class="soft-button outline button-height pointer"
      title="{{ '#APP_OVERVIEW.GRID' | translate }}"
      (click)="setMode('grid')"
    >
      <i class="fas fa-grip-horizontal"></i>
    </button>
    <button
      type="button"
      class="soft-button outline button-height pointer"
      title="{{ '#APP_OVERVIEW.LIST' | translate }}"
      (click)="setMode('list')"
    >
      <i class="fas fa-list"></i>
    </button>
  </div>
  <div class="pb-4 mb-2"
    [ngClass]="
      (settings$ | async)?.display === 'list'
        ? 'grid-container-list'
        : 'grid-container'
    "
    *ngIf="permissions$ | async as permissions"
  >
    <ng-container
      *ngFor="
        let application of applications$
          | async
          | filter: filterPermissions:permissions
          | filter: filterInput:{ store: store, input: search }
      "
    >
      <soft-app-grid
        [id]="'softapps-allgemein-modules-griditem-' + application.name"
        *ngIf="(settings$ | async)?.display !== 'list'"
        [application]="application"
        (navigate)="navigate($event)"
      >
      </soft-app-grid>
      <soft-app-list
        class="mt-3"
        [id]="'softapps-allgemein-modules-listitem-' + application.name"
        *ngIf="(settings$ | async)?.display === 'list'"
        [application]="application"
        (navigate)="navigate($event)"
      >
      </soft-app-list>
    </ng-container>
  </div>
</div>
