import { ENVIRONMENT_INITIALIZER, inject, Provider } from '@angular/core';
import { Store } from '@softline/core';
import {
  SOFTLINE_FEATURE_TRANSLATION,
  TranslationStore,
} from '@softline/ui-core';
import {
  ApplicationStore,
  SOFTLINE_FEATURE_APPLICATIONS,
} from '@softline/application';
import { default as de } from '../i18n/de.json';
import {
  SOFTLINE_DEFINITION_FREIGABE_DETAIL,
  SOFTLINE_DEFINITION_FREIGABE_LIST,
  SOFTLINE_PERMISSIONS_FREIGABE,
} from './freigabe.shared';
import { SOFTLINE_CONFIG_DEFINITION } from '@softline/dynamic';
import {
  SOFTLINE_DEFINITION_HAFTRUECKLASS_LIST,
  SOFTLINE_DEFINITION_HAFTRUECKLASS_QUERY,
} from '@softapps/fibu/haftruecklass';
import { default as listDefinition } from './definitions/freigabe-list.definition.json';
import { default as detailDefinition } from './definitions/freigabe-details.definition.json';

export const freigabeProviders: Provider[] = [
  {
    provide: ENVIRONMENT_INITIALIZER,
    useValue: () => {
      const store = inject(Store);
      store.commit(
        SOFTLINE_FEATURE_TRANSLATION,
        TranslationStore.mutations.add,
        {
          module: 'freigabe',
          language: 'de',
          translations: de,
        }
      );
      store.commit(
        SOFTLINE_FEATURE_APPLICATIONS,
        ApplicationStore.mutations.add,
        {
          name: '#FREIGABE.TITLE',
          icon: 'fas fa-file-invoice-dollar',
          route: '/freigabe',
          permission: SOFTLINE_PERMISSIONS_FREIGABE,
        }
      );
    },
    multi: true,
  },
  {
    provide: SOFTLINE_CONFIG_DEFINITION,
    useValue: {
      name: SOFTLINE_DEFINITION_FREIGABE_LIST,
      definition: listDefinition,
    },
    multi: true,
  },
  {
    provide: SOFTLINE_CONFIG_DEFINITION,
    useValue: {
      name: SOFTLINE_DEFINITION_FREIGABE_DETAIL,
      definition: detailDefinition,
    },
    multi: true,
  },
];
