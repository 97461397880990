<h2 class="text-xl font-semibold mt-3 ml-3">
  {{ title ?? '#UI_CORE.DIALOGS.DRAW' | translate | interpolate: params }}
</h2>
<p *ngIf="subtitle" class="mb-1 mt-1 ml-3 mr-3 border-box color-light">
  {{ subtitle | translate | interpolate: params }}
</p>
<p *ngIf="content" class="mb-1 mt-1 ml-3 mr-3 border-box">
  <ng-container *ngTemplateOutlet="content; context: (params ?? null)"></ng-container>
</p>

<div class="w-full">
  <div class="draw-modal-input" #container>
    <soft-draw-pad
      #drawpad
      [options]="{
        canvasWidth: container.offsetWidth,
        canvasHeight: container.offsetHeight - 50
      }"
      [drawing]="drawing"
    ></soft-draw-pad>
  </div>
</div>
<div class="flex no-wrap mt-3">
  <button
    class="soft-button primary ok h-14 w-full"
    (click)="drawpad.isEmpty() ? onSubmit(null) : onSubmit(drawpad.toDataURL())"
  >
    {{ '#UI_CORE.DIALOGS.OK' | translate }}
  </button>
</div>
