<div
  class="soft-container column"
  [formGroup]="form"
  *ngIf="definition && form"
>
  <label class="soft-label top"
    >{{ definition?.title }}
    <span
      class="required-indicator"
      *ngIf="definition?.required | booleanRule: form.value"
      >*</span
    >
  </label>
  <ng-container [ngSwitch]="definition.control">
    <ng-container *ngSwitchCase="'dropdown'">
      <soft-select [formControlName]="definition.name">
        <soft-select-option
          *ngFor="let option of definition.options"
          [value]="option.value"
          [disabled]="definition.readonly | booleanRule: form.value"
          >{{ option.title }}</soft-select-option
        >
      </soft-select>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <soft-radio-group
        [formControlName]="definition.name"
        class="soft-container"
      >
        <div
          class="soft-container"
          *ngFor="let option of definition.options; let i = index"
        >
          <soft-radio-option
            [value]="option.value"
            [disabled]="definition.readonly | booleanRule: form.value"
          >
            <span class="soft-label soft-label-right">{{ option.title }}</span>
          </soft-radio-option>
        </div>
      </soft-radio-group>
    </ng-container>
  </ng-container>

  <soft-validation-container
    class="soft-property-validation"
    *ngIf="form.controls[definition.name].touched"
    [validation]="form | combineValidations: definition.name"
  ></soft-validation-container>
</div>
