<div class="container" [class.minified]="isMinified">
  <span
    *ngIf="!isMinified"
    class="close-btn side-menu-close"
    (click)="closeMenu()"
  >
    <i class="fas fa-times"></i>
  </span>
  <div
    *ngIf="isMinified"
    class="c column center side-menu-toggle"
    (click)="open.emit()"
  >
    <i class="fas fa-bars"></i>
  </div>
  <div
    *ngIf="!isMinified"
    class="c column center user-info"
    (click)="open.emit()"
  >
    <div class="side-menu-branding px-8 py-4">
    </div>
    <soft-widget-outlet
      class="w-full"
      [name]="'sideMenuUserInfo'"
    ></soft-widget-outlet>
  </div>

  <div class="menu-item-container soft-scroll vertical no-horizontal">
    <ng-container *ngFor="let command of topCommands$ | async">
      <ng-container
        *ngIf="command.isVisible ? (command.isVisible | async) : true"
      >
        <div
          [id]="'framework-application-shell-menu-commands-top-' + command.name"
          class="c row menu-item no-wrap relative"
          softToolTip="{{ command.name | translate }}"
          toolTipPosition="right"
          [toolTipVisible]="isMinified"
          *ngVar="
            command.canExecute
              ? (command.canExecute | async)
              : true as canExecute
          "
          [class.disabled]="!canExecute"
          [class.active]="command.routerLink | isActiveRoute"
          [ngClass]="command.class"
          (click)="
            onClick(
              command.execute,
              command.routerLink,
              canExecute,
              command.routerLinkParams
            )
          "
        >
          <span class="soft-badge" *ngIf="command?.badge | async as badge">{{ badge }}</span>
          <span
            *ngIf="command.icon"
            class="soft-container soft-container-center command-icon"
            [class.minified]="isMinified"
          >
            <i [ngClass]="command.icon"></i>
          </span>
          <span class="ml-2" *ngIf="!isMinified">{{
            command.name | translate
          }}</span>
        </div>
        <ng-container
          *ngIf="command.routerLink | isActiveRoute: { paths: 'subset' }"
        >
          <ng-container *ngFor="let subCommand of command.commands">
            <div
              class="c row menu-item no-wrap sub-menu-item relative"
              softToolTip="{{ subCommand.name | translate }}"
              toolTipPosition="right"
              [toolTipVisible]="isMinified"
              *ngVar="
                subCommand.canExecute
                  ? (subCommand.canExecute | async)
                  : true as canExecute
              "
              [class.disabled]="!canExecute"
              [ngClass]="subCommand.class"
              [class.active]="subCommand.routerLink | isActiveRoute"
              (click)="
                onClick(
                  subCommand.execute,
                  subCommand.routerLink,
                  canExecute,
                  subCommand.routerLinkParams
                )
              "
            >
              <span class="soft-badge" *ngIf="command?.badge | async as badge">{{ badge }}</span>
              <span
                *ngIf="subCommand.icon"
                class="soft-container soft-container-center command-icon"
                [class.minified]="isMinified"
              >
                <i [ngClass]="subCommand.icon"></i>
              </span>
              <span class="ml-2" *ngIf="!isMinified">{{
                subCommand.name | translate
              }}</span>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>

    <div class="menu-container-bottom">
      <ng-container *ngFor="let command of bottomCommands$ | async">
        <ng-container
          *ngIf="command.isVisible ? (command.isVisible | async) : true"
        >
          <div
            [id]="'framework-application-shell-menu-commands-bottom-' + command.name"
            class="c row menu-item no-wrap relative"
            softToolTip="{{ command.name | translate }}"
            toolTipPosition="right"
            *ngVar="
              command.canExecute
                ? (command.canExecute | async)
                : true as canExecute
            "
            [class.active]="command.routerLink | isActiveRoute"
            [class.disabled]="!canExecute"
            [ngClass]="command.class"
            (click)="
              onClick(
                command.execute,
                command.routerLink,
                canExecute,
                command.routerLinkParams
              )
            "
          >
            <span class="soft-badge" *ngIf="command?.badge | async as badge">{{ badge }}</span>
            <span
              *ngIf="command.icon"
              class="soft-container soft-container-center command-icon"
              [class.minified]="isMinified"
            >
              <i [ngClass]="command.icon"></i>
            </span>
            <span class="ml-2" *ngIf="!isMinified">{{
              command.name | translate
            }}</span>
          </div>
        </ng-container>
      </ng-container>
      <div
        *ngIf="(version$ | async) && !isMinified"
        class="c row center menu-version-info no-select"
      >
        {{ version$ | async }}
      </div>
    </div>
  </div>
</div>
