<div class="c column" *ngIf="form && definition" [formGroup]="form">
  <label class="soft-label top"
    >{{ definition.title }}
    <span
      class="required-indicator"
      *ngIf="definition.required | booleanRule: form.value"
      >*</span
    >
  </label>
  <soft-file-input
    [formControlName]="definition.name"
    [readonly]="definition.readonly | booleanRule: form.value"
    #value
  ></soft-file-input>
  <soft-validation-container
    class="soft-property-validation"
    *ngIf="form.controls[definition.name].touched"
    [validation]="form | combineValidations: definition.name"
  ></soft-validation-container>
</div>
