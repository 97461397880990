<div class="flex w-full items-center justify-end">
  <div class="flex gap-2 items-center">
    <span class="text-base font-medium">Nicht Fakturierte anzeigen</span>
    <soft-toggle-switch [checked]="nichtFakturierteAnzeigen$ | async" (checkedChange)="onToggleChange($event)"></soft-toggle-switch>
  </div>

</div>

<table class="soft-table mt-3 table-products border border-solid border-gray-200">
  <thead class="h-10">
  <tr class="h-10 border-b border-solid border-gray-200 p-2 text-xs" style="height: 2rem">
    <th>{{ '#OFFENE_POSTEN.PAGES.LIST.FAELLIGKEITSDATUM' | translate }}</th>
    <th>{{ '#OFFENE_POSTEN.PAGES.LIST.RECHNUNGSNUMMER' | translate }}</th>
    <th>{{ '#OFFENE_POSTEN.PAGES.LIST.BELEGDATUM' | translate }}</th>
    <th>{{ '#OFFENE_POSTEN.PAGES.LIST.SYMBOL' | translate }}</th>
    <th class="text-right">{{ '#OFFENE_POSTEN.PAGES.LIST.BETRAG' | translate }}</th>
    <th class="text-right">{{ '#OFFENE_POSTEN.PAGES.LIST.SKONTO' | translate }}</th>
    <th class="text-right">{{ '#OFFENE_POSTEN.PAGES.LIST.BATRAG_ABZGL_SKONTO' | translate }}</th>
    <td class=""></td>
  </tr>
  </thead>
  <tbody>
  <ng-container *ngIf="(loading$ | async)">
    <ng-container *softRepeat="2">
      <tr class="!h-12 border-b border-solid border-gray-200 text-xs !bg-selected">
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr *softRepeat="4" class="!h-12 border-b border-solid border-gray-200 text-xs">
        <td></td>
        <td><span class="w-24 skeleton"></span></td>
        <td><span class="w-24 skeleton"></span></td>
        <td><span class="w-12 skeleton"></span></td>
        <td class="text-right"><span class="w-24 skeleton"></span></td>
        <td class="text-right"><span class="w-24 skeleton"></span></td>
        <td class="text-right"><span class="w-24 skeleton"></span></td>
        <td class=""></td>
      </tr>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="(loading$ | async) === false">
    <ng-container *ngFor="let group of (groups$ | async) | sort:'key':'ASC'">
        <tr class="!h-12 border-b border-solid border-gray-200 text-xs !bg-selected cursor-pointer"
            (click)="toggleExpand(group.key)">
          <td class="font-semibold">{{ group.key | format:'DATE.DEFAULT' }}</td>
          <td class=""></td>
          <td class=""></td>
          <td class=""></td>
          <td class="text-right font-semibold whitespace-nowrap"
              [class.color-balance-negative]="(group.values | sum:'saldo') < 0">{{ group.values | sum:'saldo' | currencyFormat:'EUR':'code':'after' }}</td>
          <td class="text-right font-semibold whitespace-nowrap"
              [class.color-balance-negative]="(group.values | sum:'saldo') < 0">{{ group.values | sum:'skonto' | currencyFormat:'EUR':'code':'after' }}</td>
          <td class="text-right font-semibold whitespace-nowrap"
              [class.color-balance-negative]="(group.values | sum:'saldo') < 0">{{ group.values | sum:'saldoAbzglSkonto' | currencyFormat:'EUR':'code':'after' }}</td>
          <td class="">
            <svg *ngIf="(expanded$ | async | indexOf:group.key) === -1; else chevronUp" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
            </svg>
            <ng-template #chevronUp>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
              </svg>
            </ng-template>
          </td>
        </tr>
      <ng-container *ngIf="(expanded$ | async | indexOf:group.key) > -1">
        <ng-container *ngFor="let op of group.values | sort:'belegdatum':'ASC'">
          <tr class="!h-12 text-xs border-b border-solid border-gray-200">
            <td class="font-semibold"></td>
            <td class="">{{op.bezugsnummer}}</td>
            <td class="">{{op.belegdatum | format:'DATE.DEFAULT'}}</td>
            <td class="">{{op.symbol?.symbol}}</td>
            <td class="text-right whitespace-nowrap"
                      [class.color-balance-negative]="op.saldo < 0">{{ op.saldo | currencyFormat:'EUR':'code':'after' }}</td>
            <td class="text-right whitespace-nowrap"
                      [class.color-balance-negative]="op.saldo < 0">{{ op.skonto | currencyFormat:'EUR':'code':'after' }}</td>
            <td class="text-right whitespace-nowrap"
                      [class.color-balance-negative]="op.saldo < 0">{{ op.saldoAbzglSkonto | currencyFormat:'EUR':'code':'after' }}</td>
            <td class=""></td>
          </tr>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
  </tbody>
  <tfoot>
  <tr *ngIf="offenePosten$ | async as offenePosten" class="!h-12 border-b border-solid border-gray-200 text-xs  !bg-primary-50">
    <th>{{ '#OFFENE_POSTEN.PAGES.LIST.GESAMT' | translate | uppercase }}</th>
    <th></th>
    <th></th>
    <th></th>
    <td class="text-right font-semibold whitespace-nowrap">{{ offenePosten | sum:'saldo' | currencyFormat:'EUR':'code':'after' }}</td>
    <td class="text-right font-semibold whitespace-nowrap">{{ offenePosten | sum:'skonto' | currencyFormat:'EUR':'code':'after' }}</td>
    <td class="text-right font-semibold whitespace-nowrap">{{ offenePosten | sum:'saldoAbzglSkonto' | currencyFormat:'EUR':'code':'after' }}</td>
    <th></th>
  </tr>
  </tfoot>
</table>
