import { Archive, ArchiveFile } from '@softline/application';

export interface WorkflowData {
  id: number;
  belegnummer: number;
  bezugsnummer: string;
  barcode: string;
  belegartKopferw: string;
  wfprocessid: string;

  archiveKey?: string;
  fileKey?: string;
  kzfreigabe?: 'J' | 'N';
}
