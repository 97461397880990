import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import {CommonModule} from '@angular/common';
import {base64Encode, Store} from '@softline/core';
import {
  BackNavigable,
  BackNavigationService,
  Command,
  CommandStore,
  handleRequestErrors,
  SOFTLINE_FEATURE_COMMANDS,
  SOFTLINE_FEATURE_TITLE,
  TitleStore,
} from '@softline/application';
import {Observable} from 'rxjs';
import {UiCoreModule} from '@softline/ui-core';
import {DefinitionStore, DynamicFormComponent, DynamicModule, SOFTLINE_FEATURE_DEFINITIONS,} from '@softline/dynamic';
import {SOFTLINE_DEFINITION_HAFTRUECKLASS_QUERY, SOFTLINE_FEATURE_HAFTRUECKLASS,} from '../../haftruecklass.shared';
import {SOFTLINE_API_DEFINITION_HAFTRUECKLASS_QUERY} from '../../haftruecklass.api';
import {Router} from '@angular/router';
import {HaftruecklassStore} from '../../store';

@Component({
  selector: 'lib-open-item-query',
  standalone: true,
  imports: [CommonModule, UiCoreModule, DynamicModule],
  templateUrl: './haftruecklass-query.component.html',
  styleUrls: ['./haftruecklass-query.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HaftruecklassQueryComponent
  implements OnInit, AfterViewInit, OnDestroy, BackNavigable
{
  @ViewChild('form') form!: DynamicFormComponent<any>;

  loading$ = this.store.observe(
    SOFTLINE_FEATURE_DEFINITIONS,
    DefinitionStore.getters.loading,
    SOFTLINE_DEFINITION_HAFTRUECKLASS_QUERY
  );
  definition$: any = this.store.observe(
    SOFTLINE_FEATURE_DEFINITIONS,
    DefinitionStore.getters.definition,
    SOFTLINE_DEFINITION_HAFTRUECKLASS_QUERY
  );

  readonly value$: Observable<object> = this.store.observe(
    SOFTLINE_FEATURE_HAFTRUECKLASS,
    HaftruecklassStore.getters.query.query
  );

  constructor(
    private store: Store,
    private router: Router,
    private backNavigationService: BackNavigationService,
    private injector: Injector,
    private cdRef: ChangeDetectorRef
  ) {}

  async ngOnInit(): Promise<void> {
    this.backNavigationService.set(this);
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      '#HAFTRUECKLASS.TITLE'
    );
    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.addSet,
      {
        name: HaftruecklassQueryComponent,
        commands: this.createCommands(),
      }
    );
    try {
      await this.store.dispatch(
        SOFTLINE_FEATURE_DEFINITIONS,
        DefinitionStore.actions.loadOnce,
        {
          name: SOFTLINE_DEFINITION_HAFTRUECKLASS_QUERY,
          location: { path: SOFTLINE_API_DEFINITION_HAFTRUECKLASS_QUERY },
        },
        this.injector
      );
    } catch (e) {
      handleRequestErrors(this.store, e);
    }
  }

  ngOnDestroy(): void {
    this.backNavigationService.set(undefined);
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      ''
    );
    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.removeSet,
      HaftruecklassQueryComponent
    );
  }

  ngAfterViewInit(): void {
    this.form.form.updateValueAndValidity();
  }

  async navigateBack(): Promise<void> {
    this.router.navigate(['/']);
  }

  createCommands(): Command[] {
    return [
      {
        name: '#HAFTRUECKLASS.TITLE',
        class: 'menu main-menu main-menu-top title',
      },
      {
        icon: 'fas fa-search',
        name: '#HAFTRUECKLASS.MENU.QUERY',
        class: 'menu main-menu main-menu-top',
        routerLink: ['/haftruecklass'],
      },
    ];
  }

  async onSubmit(query: unknown): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_HAFTRUECKLASS,
      HaftruecklassStore.mutations.query.setQuery,
      query
    );
    const encodedQuery = base64Encode(JSON.stringify(query));
    await this.router.navigate(['/haftruecklass', 'result'], {
      queryParams: { query: encodedQuery },
    });
  }
}
