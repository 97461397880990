<div class="flex flex-row w-full flex-wrap">
  <div class="flex flex-grow flex-wrap gap-1">
    <span *ngFor="let item of displayAll ? (value ?? []) : (value | take: maxDisplayed)"
          class="select-pill fluid no-wrap">
        <ng-container
          *ngTemplateOutlet="entityTemplate; context: $any({ entity: item })"
        ></ng-container>
      <i
        *ngIf="!readonly"
        class="fas fa-times pointer ml-1"
        (click)="onDelete(item)"
      ></i>
    </span>
    <input
      class="mr-2 w-auto"
      [value]="input"
      (input)="onInput(inputComponent.value)"
      [readOnly]="readonly"
      placeholder="{{
        placeholder || '#UI_CORE.COMPONENTS.ENTITY_LIST_INPUT.PLACEHOLDER' | translate
      }}"
      #inputComponent
    />
  </div>
  <div>
    <span
    *ngIf="!displayAll && (value?.length ?? 0) > maxDisplayed + 1"
    class="soft-link small fluid no-wrap"
    (click)="displayAll = true"
  >
    {{
    '#UI_CORE.COMPONENTS.MULTI_SELECT.DISPLAY_MORE_PL'
      | translate
      | interpolate: { count: (value?.length ?? 0) - maxDisplayed }
    }}
  </span>
    <span
      *ngIf="!displayAll && (value?.length ?? 0) === maxDisplayed + 1"
      class="soft-link small fluid no-wrap"
      (click)="displayAll = true"
    >
    {{ '#UI_CORE.COMPONENTS.MULTI_SELECT.DISPLAY_MORE_SG' | translate }}
  </span>
    <span
      *ngIf="displayAll && (value?.length ?? 0) > maxDisplayed"
      class="soft-link small fluid no-wrap"
      (click)="displayAll = false"
    >
    {{ '#UI_CORE.COMPONENTS.MULTI_SELECT.DISPLAY_LESS' | translate }}
  </span>
  </div>
</div>

<ng-container *ngIf="strategy.loading()">
  <i class="fas fa-spinner fa-spin ml-4"></i>
</ng-container>
<ng-container *ngIf="strategy.loading() || inputComponent.value.length > 0 || value?.length">
  <div
    id="closeDialog"
    class="ml-4 pointer"
    (click)="onCancel(); $event.stopPropagation()"
  >
    <i class="fas fa-times"></i>
  </div>
</ng-container>
<ng-container *ngIf="!strategy.loading()">
  <div
    id="openDialog"
    class="ml-4 pointer order-100"
    *ngIf="!readonly"
    (click)="onOpen(); $event.stopPropagation()"
  >
    <i class="fas fa-database"></i>
  </div>
</ng-container>
