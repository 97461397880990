import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  BackNavigable,
  BackNavigationService,
  Command,
  CommandStore,
  handleRequestErrors,
  SOFTLINE_FEATURE_COMMANDS,
  SOFTLINE_FEATURE_TITLE,
  TitleStore,
} from '@softline/application';
import {containsText, isDefinedNotEmpty, Store} from '@softline/core';
import { Router } from '@angular/router';
import {
  SOFTLINE_FEATURE_SUBKUNDEN,
  SOFTLINE_MODULE_SUBKUNDEN,
} from '../../subkunden.shared';
import {
  BehaviorSubject,
  combineLatestWith,
  debounceTime,
  map,
  Observable,
} from 'rxjs';
import { Subkunde } from '../../types/subkunde';
import { SubkundenStore } from '../../store';
import {
  MessageBarStore,
  ModalStore,
  SOFTLINE_FEATURE_MESSAGE_BAR,
  SOFTLINE_FEATURE_MODAL,
  UiCoreModule,
} from '@softline/ui-core';
import { CreateSubkundeDialogComponent } from '../../dialogs/create-subkunde-dialog/create-subkunde-dialog.component';
import { EditSubkundeDialogComponent } from '../../dialogs/edit-subkunde-dialog/edit-subkunde-dialog.component';

@Component({
  selector: 'soft-subkunden-page',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './subkunden-page.component.html',
  styleUrls: ['./subkunden-page.component.scss'],
})
export class SubkundenPageComponent
  implements OnInit, OnDestroy, BackNavigable
{
  selected$ = new BehaviorSubject<any | undefined>(undefined);
  filter$ = new BehaviorSubject<string | undefined>(undefined);
  activeOnly$ = new BehaviorSubject<boolean>(true);
  debouncedFilter$ = this.filter$.pipe(debounceTime(400));
  subkunden$: Observable<readonly Subkunde[]> = this.store
    .observe(SOFTLINE_FEATURE_SUBKUNDEN, SubkundenStore.getters.all)
    .pipe(
      combineLatestWith(this.debouncedFilter$, this.activeOnly$),
      map(([items, filter, activeOnly]) =>
        items.filter((o) =>
          isDefinedNotEmpty(filter) ? containsText(o, filter, true) : true
        ).filter(o => !activeOnly || o.aktiv)
      )
    );
  subkundenLength$: Observable<number> = this.store
    .observe(SOFTLINE_FEATURE_SUBKUNDEN, SubkundenStore.getters.ids)
    .pipe(map((o) => o.length));
  subkundenActiveLength$: Observable<number> = this.store
    .observe(SOFTLINE_FEATURE_SUBKUNDEN, SubkundenStore.getters.all)
    .pipe(map((o) => o.filter((p) => p.aktiv).length));

  loading$: Observable<boolean> = this.store
    .observe(SOFTLINE_FEATURE_SUBKUNDEN, SubkundenStore.getters.loading)
    .pipe(debounceTime(200));

  constructor(
    private store: Store,
    private router: Router,
    private backNavigationService: BackNavigationService
  ) {}

  async ngOnInit(): Promise<void> {
    this.backNavigationService.set(this);
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      '#SUBKUNDEN.TITLE'
    );
    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.addSet,
      {
        name: SubkundenPageComponent,
        commands: this.createCommands(),
      }
    );

    try {
      await this.store.dispatch(
        SOFTLINE_FEATURE_SUBKUNDEN,
        SubkundenStore.actions.loadMany,
        {}
      );
    } catch (e) {
      handleRequestErrors(this.store, e);
    }
  }

  ngOnDestroy(): void {
    this.backNavigationService.set(undefined);
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      ''
    );
    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.removeSet,
      SubkundenPageComponent
    );
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['/']);
  }

  createCommands(): Command[] {
    return [
      {
        name: '#SUBKUNDEN.TITLE',
        class: 'menu main-menu main-menu-top title',
      },
      {
        icon: 'fas fa-list',
        name: '#SUBKUNDEN.MENU.LIST',
        class: 'menu main-menu main-menu-top',
        routerLink: ['/subkunden'],
      },
      {
        icon: 'fas fa-plus',
        name: '#SUBKUNDEN.MENU.CREATE',
        class: 'menu main-menu main-menu-top',
        execute: () => {
          this.createSubkunde();
        },
      },
      {
        icon: 'fas fa-edit',
        name: '#SUBKUNDEN.MENU.EDIT',
        class: 'menu action-menu action-menu-top',
        canExecute: this.selected$.pipe(map((o) => !!o)),
        execute: () => {
          this.editSubkunde(this.selected$.value);
        },
      },
    ];
  }

  private async createSubkunde(): Promise<void> {
    const subkunde = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open<
        Partial<Subkunde>,
        CreateSubkundeDialogComponent
      >(),
      {
        component: CreateSubkundeDialogComponent,
        dismiss: true,
      }
    );
    if (subkunde === 'DISMISSED') return;
    const uid = await this.store.dispatch(
      SOFTLINE_FEATURE_MESSAGE_BAR,
      MessageBarStore.actions.loading
    );
    try {
      await this.store.dispatch(
        SOFTLINE_FEATURE_SUBKUNDEN,
        SubkundenStore.actions.create,
        { entity: subkunde }
      );
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.success,
        '#SUBKUNDEN.MESSAGES.SAVE_SUCCESS'
      );
    } catch (e) {
      handleRequestErrors(this.store, e);
    } finally {
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.close,
        uid
      );
    }
  }

  private async editSubkunde(subkunde: Subkunde): Promise<void> {
    const changes = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open<Partial<Subkunde>, any>(),
      {
        component: EditSubkundeDialogComponent,
        dismiss: true,
        data: {
          subkunde,
        },
      }
    );
    if (changes === 'DISMISSED') return;
    const uid = await this.store.dispatch(
      SOFTLINE_FEATURE_MESSAGE_BAR,
      MessageBarStore.actions.loading
    );
    try {
      await this.store.dispatch(
        SOFTLINE_FEATURE_SUBKUNDEN,
        SubkundenStore.actions.patch,
        {
          patch: {
            id: subkunde.id,
            changes,
          },
        }
      );
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.success,
        '#SUBKUNDEN.MESSAGES.SAVE_SUCCESS'
      );
    } catch (e) {
      handleRequestErrors(this.store, e);
    } finally {
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.close,
        uid
      );
    }
  }
}
