import {
  asapScheduler,
  BehaviorSubject,
  Observable,
  observeOn,
  Subject,
} from 'rxjs';
import {
  Action,
  ActionFunc,
  ActionReg,
  Getter,
  GetterFunc,
  GetterParams,
  GetterReg,
  Mutation,
  MutationFunc,
  MutationReg,
} from './abstraction';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { Injectable, Injector, Optional, runInInjectionContext, Signal } from "@angular/core";
import { Dictionary } from '../types/dictionary';
import { isDefined } from '../functions/is-defined.function';
import { findDuplicates } from '../functions/find-duplicates.function';
import { Logger } from '../log/logger';
import { LogEntry } from '../log/log';
import { toSignal, ToSignalOptions } from "@angular/core/rxjs-interop";

export interface StoreFeature<T> {
  initialState: T;
  mutations: MutationReg<any, any>[];
  actions: ActionReg<T, any, any>[];
  getters: GetterReg<T, any, any>[];
}

@Injectable()
export class Store {
  protected emptyInjector = Injector.create({ providers: [] });
  protected injectors: Dictionary<Injector> = {};
  protected commits$ = new Subject<{
    feature: string;
    mutation: Mutation<any, any>;
    params: unknown;
    stateBefore: Dictionary<object>;
    stateAfter: Dictionary<object>;
  }>();

  protected state = new BehaviorSubject<Dictionary<any>>({});
  protected features: Dictionary<StoreFeature<any>> = {};

  constructor(@Optional() private logger?: Logger) {}

  commit<T, P>(feature: string, mutation: Mutation<T, P>, params?: P): void {
    this.log(feature, mutation, params);
    const mutationFuncs = this.getMutationFuncs(feature, mutation);
    let featureState = this.state.value[feature];
    for (const mutationFunc of mutationFuncs)
      featureState = mutationFunc({ state: featureState, params: params as P });

    const stateBefore = this.state.value[feature];
    const newState = { ...this.state.value, [feature]: featureState };
    this.state.next(newState);
    this.commits$.next({
      feature,
      mutation,
      params,
      stateBefore,
      stateAfter: featureState,
    });
  }

  async dispatch<T, P, R>(
    featureName: string,
    action: Action<T, P, R>,
    params?: P,
    injector?: Injector
  ): Promise<R> {
    this.log(featureName, action, params);
    const actionFunc = this.getActionFunc<T, P, R>(featureName, action);

    // tslint:disable-next-line:no-shadowed-variable
    const get = <T2, P2 = undefined, R2 = T2>(
      feature: string,
      name?: Getter<T2, P2, R2>,
      params?: P2
    ) => this.get(feature, name, params);
    // tslint:disable-next-line:no-shadowed-variable
    const commit = <T2, P2 = undefined>(
      feature: string,
      name: Mutation<T2, P2>,
      params?: P2
    ) => this.commit(feature, name, params);
    // tslint:disable-next-line:no-shadowed-variable
    const dispatch = <T2, P2 = undefined, R2 = T2>(
      feature: string,
      name: Action<T2, P2, R2>,
      params?: P2,
      injector?: Injector
    ) => this.dispatch(feature, name, params, injector);
    const result = runInInjectionContext(
      injector ?? this.injectors[featureName] ?? this.emptyInjector, () => {
      return actionFunc({
        state: this.state.value[featureName],
        featureName,
        store: this,
        params: params as P,
        injector: injector ?? this.injectors[featureName] ?? this.emptyInjector,
        get,
        commit,
        dispatch,
      })
    } )
    if (result instanceof Promise)
      return await result;
    return result;
  }

  get<T, P = undefined, R = T>(
    feature: string,
    getter?: Getter<T, P, R>,
    params?: P,
    injector?: Injector
  ): R {
    const getterFunc = isDefined(getter)
      ? this.getGetterFunc<T, P, R>(feature, getter)
      : (o: GetterParams<T, P>) => o.state as unknown as R;
    // tslint:disable-next-line:no-shadowed-variable
    const get = <T2, P2 = undefined, R2 = T2>(
      feature: string,
      getter?: Getter<T2, P2, R2>,
      params?: P2
    ) => this.get(feature, getter, params);
    return getterFunc({
      state: this.state.value[feature],
      params: params as P,
      get,
      featureName: feature,
      injector: injector ?? this.injectors[feature] ?? this.emptyInjector,
    });
  }

  observe<T, P = undefined, R = T>(
    feature: string,
    getter?: Getter<T, P, R>,
    params?: P,
    injector?: Injector
  ): Observable<R> {
    const getterFunc = isDefined(getter)
      ? this.getGetterFunc<T, P, R>(feature, getter)
      : (o: GetterParams<T, P>) => o.state as unknown as R;
    const options = isDefined(getter)
      ? this.getGetterOptions(feature, getter)
      : {};
    // tslint:disable-next-line:no-shadowed-variable
    const get = <T2, P2 = undefined, R2 = T2>(
      feature: string,
      getter?: Getter<T2, P2, R2>,
      params?: P2
    ) => this.get(feature, getter, params);
    return this.state.pipe(
      distinctUntilChanged((c, p) => {
        if (c[feature] !== p[feature]) return false;
        for (const dependingFeature of options.dependingFeatures ?? [])
          if (c[dependingFeature] !== p[dependingFeature]) return false;
        return true;
      }),
      map((state) =>
        getterFunc({
          state: state[feature],
          params: params as P,
          get,
          featureName: feature,
          injector: injector ?? this.injectors[feature] ?? this.emptyInjector,
        })
      ),
      distinctUntilChanged()
    );
  }

  signal<T, P = undefined, R = T>(
    feature: string,
    getter?: Getter<T, P, R>,
    params?: P,
    injector?: Injector,
  ): Signal<R>{
    return toSignal<R>(this.observe(feature, getter, params, injector), {requireSync: true});
  }

  registerFeature<T>(
    name: string,
    feature: StoreFeature<T>,
    injector?: Injector
  ): void {
    this.validateFeature(name, feature);
    const state = { ...this.state.value };
    state[name] = { ...feature.initialState, ...(state[name] ?? {}) }; // if there is a state previous Loaded do not override it with the initial state
    this.state.next(state);

    this.features[name] = feature;
    this.injectors[name] = injector;
  }

  resetFeature(name: string): void {
    const feature = this.features[name];
    if (!isDefined(feature))
      throw Error(`Store: A feature with name '${name}' is not registered`);

    const newState = { ...this.state.value, [name]: feature.initialState };
    this.state.next(newState);
  }

  isRegistered(name: string) : boolean {
    return isDefined(this.features[name]);
  }

  private getMutationFuncs<T, P>(
    feature: string,
    mutation: Mutation<T, P>
  ): MutationFunc<T, P>[] {
    const storeFeature = this.getFeature(feature);

    const mutationReg = storeFeature.mutations.filter(
      (o) => o.mutation.name === mutation.name
    );
    if (mutationReg.length === 0)
      throw Error(
        `Store: A mutation with name '${mutation.name}' in feature '${feature}' is not registered`
      );
    return mutationReg.map((o) => o.func);
  }

  private getActionFunc<T, P, R>(
    feature: string,
    action: Action<T, P, R>
  ): ActionFunc<T, P, R> {
    const storeFeature = this.getFeature(feature);

    const actionReg = storeFeature.actions.find(
      (o) => o.action.name === action.name
    );
    if (!isDefined(actionReg))
      throw Error(
        `Store: An action with name '${action.name}' in feature '${feature}' is not registered`
      );
    return actionReg.func;
  }

  private getGetterFunc<T, P, R>(
    feature: string,
    getter: Getter<T, P, R>
  ): GetterFunc<T, P, R> {
    const storeFeature = this.getFeature(feature);

    const getterReg = storeFeature.getters.find(
      (o) => o.getter.name === getter.name
    );
    if (!isDefined(getterReg))
      throw Error(
        `Store: A getter with name '${getter.name}' in feature '${feature}' is not registered`
      );
    return getterReg.func;
  }

  private getGetterOptions<T, P, R>(
    feature: string,
    getter: Getter<T, P, R>
  ): { dependingFeatures?: string[] } {
    const storeFeature = this.getFeature(feature);

    const getterReg = storeFeature.getters.find(
      (o) => o.getter.name === getter.name
    );
    if (!isDefined(getterReg))
      throw Error(
        `Store: A getter with name '${getter.name}' in feature '${feature}' is not registered`
      );
    return getterReg.options ?? {};
  }

  private getFeature(name: string): StoreFeature<any> {
    const feature = this.features[name];
    if (!isDefined(feature))
      throw Error(`Store: A feature with name '${name}' is not registered`);
    return feature;
  }

  private validateFeature(name: string, feature: StoreFeature<any>): void {
    if (isDefined(this.features[name]))
      throw Error(`Store: A feature with name '${name}' is already registered`);

    // Mutationen werden absichtlich nicht geprüft, da mehrere mutationen auf einen namen registriert werden können

    const duplicateActions = findDuplicates(
      feature.actions.map((o) => o.action.name)
    ).map((o) => `'${o}'`);
    if (duplicateActions.length === 1)
      throw new Error(
        `Store: Cannot register feature '${name}' (Action ${duplicateActions[0]} is not unique)`
      );
    else if (duplicateActions.length > 1)
      throw new Error(
        `Store: Cannot register feature '${name}' (Actions ${duplicateActions.join(
          ', '
        )} are not unique)`
      );

    const duplicateGetter = findDuplicates(
      feature.getters.map((o) => o.getter.name)
    ).map((o) => `'${o}'`);
    if (duplicateGetter.length === 1)
      throw new Error(
        `Store: Cannot register feature '${name}' (Getter ${duplicateGetter[0]} is not unique)`
      );
    else if (duplicateGetter.length > 1)
      throw new Error(
        `Store: Cannot register feature '${name}' (Getters ${duplicateGetter.join(
          ', '
        )} are not unique)`
      );
  }

  private reset(options?: { features?: string[]; excluded?: string[] }): void {
    let featureNames =
      options && isDefined(options?.features)
        ? options.features
        : Object.keys(this.features);
    if (options && options.excluded)
      featureNames = featureNames.filter(
        (o) => options.excluded && options.excluded.indexOf(o) === -1
      );

    const state = { ...this.state.value };
    for (const name of featureNames) {
      const feature = this.features[name];
      if (!feature)
        throw new Error(
          `Store: Cannot reset feature '${name}' (no feature with that name is registered)`
        );
      state[name] = feature?.initialState;
    }
    this.state.next(state);
  }

  private log(
    featureName: string,
    mutationOrAction: Mutation<any, any> | Action<any, any, any>,
    params: any
  ): void {
    const logEntry: LogEntry = {
      type: 'debug',
      source: Store,
      priority: 9,
      category: mutationOrAction.type,
      subject: `[Store]${mutationOrAction.type} ${featureName}`,
      message: mutationOrAction.name,
      params,
    };
    this.logger?.log(logEntry);
  }
}
