<div
  *ngIf="message"
  [ngClass]="'soft-validation soft-validation-' + message.type"
>
  <ng-container [ngSwitch]="message.type">
    <i
      *ngSwitchCase="'error'"
      class="fas fa-times-circle soft-validation-icon"
    ></i>
    <i
      *ngSwitchCase="'warning'"
      class="fas fa-exclamation-circle soft-validation-icon"
    ></i>
    <i
      *ngSwitchCase="'info'"
      class="fas fa-info-circle soft-validation-icon"
    ></i>
    <i
      *ngSwitchCase="'success'"
      class="fas fa-check-circle soft-validation-icon"
    ></i>
  </ng-container>
  <strong>
    {{ message.subject | translate | interpolate: message.data }}
  </strong>
  {{ message.message | translate | interpolate: message.data }}
</div>
