<ng-container *ngIf="definition; else noDefinitionTemplate">
  <ng-container *ngIf="!loading; else skeleton">
    <table *ngIf="!virtual; else virtualTemplate" [ngClass]="tableClass" class="w-full">
      <thead>
        <tr>
          <th *ngIf="selectionMode === 'multi'" class="w-8 text-center">
            <soft-checkbox
              [value]="allSelected"
              [disabled]="!rows.length"
              (checkedChange)="toggleSelectAll()"
              (click)="$event.stopPropagation()"
            >
            </soft-checkbox>
          </th>
          <th *ngFor="let column of definition.definitions"
              [ngClass]="(column.titleClass | classRule: undefined)">
            {{ column.title }}
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="!loading">
          <tr
            *ngFor="let row of rows"
            (click)="toggleSelect(row)"
            (dblclick)="rowDblClick.emit(row)"
            [class.selected]="selectionMode === 'single' && row | isSelected: selectedItem"
            [ngClass]="definition.class | classRule: row"
          >
            <td *ngIf="selectionMode === 'multi'" class="w-8 text-center">
              <soft-checkbox
                [value]="row | isSelected: selectedItem || selectedItems"
                (checkedChange)="toggleSelect(row)"
                (click)="$event.stopPropagation()"
              >
              </soft-checkbox>
            </td>
            <td
              *ngFor="let child of definition.definitions"
              [ngClass]="['soft-table-cell', 'soft-table-cell-' + child.type]"
            >
              <soft-dynamic-group-value
                *ngIf="child.type === 'group'; else valueTemplate"
                [value]="row"
                [definition]="$any(child)"
                [loading]="loading"
                [displayHeader]="false"
              >
              </soft-dynamic-group-value>
              <ng-template #valueTemplate>
                <soft-dynamic-value
                  *ngIf="child.visible | booleanRule: row:true"
                  [definition]="child"
                  [value]="row"
                  [ngClass]="child.class | classRule: row"
                ></soft-dynamic-value>
              </ng-template>
            </td>
          </tr>
        </ng-container>
      </tbody>
      <tfoot *ngIf="footer">
        <tr *ngFor="let row of footer">
          <td *ngIf="selectionMode === 'multi'"></td>
          <td
            *ngFor="let child of definition.definitions"
            [ngClass]="['soft-table-cell', 'soft-table-cell-' + child.type]"
          >
            <soft-dynamic-value
              *ngIf="child.visible | booleanRule: row:true"
              [definition]="child"
              [value]="row"
              [ngClass]="child.class | classRule: row"
            ></soft-dynamic-value>
          </td>
        </tr>
      </tfoot>
    </table>
  </ng-container>

  <ng-template #virtualTemplate>
    <cdk-virtual-scroll-viewport
      *ngIf="virtual"
      class="virtualPanel w-full"
      [itemSize]="virtual.itemHeight"
      [ngClass]="virtual.class"
      [ngStyle]="virtual.style"
    >
      <table [ngClass]="tableClass">
        <thead>
          <tr>
            <th *ngIf="selectionMode === 'multi'" class="w-8 text-center">
              <soft-checkbox
                [value]="allSelected"
                [disabled]="!rows.length"
                (checkedChange)="toggleSelectAll()"
                (click)="$event.stopPropagation()"
              >
              </soft-checkbox>
            </th>
            <th *ngFor="let column of definition.definitions"
                [ngClass]="(column.titleClass | classRule: undefined)">
              {{ column.title }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *cdkVirtualFor="let row of rows"
            [ngStyle]="{ height: virtual.itemHeight + 'px' }"
            (click)="toggleSelect(row)"
            [ngClass]="
              (selectionMode === 'single' && row | isSelected: selectedItem)
                ? 'selected'
                : ''
            "
          >
            <td *ngIf="selectionMode === 'multi'" class="w-8 text-center">
              <soft-checkbox
                [value]="row | isSelected: selectedItem || selectedItems"
                (click)="$event.stopPropagation()"
              >
              </soft-checkbox>
            </td>
            <td
              *ngFor="let child of definition.definitions"
              [ngClass]="['soft-table-cell', 'soft-table-cell-' + child.type]"
            >
              <soft-dynamic-group-value
                *ngIf="child.type === 'group'; else valueTemplate"
                [value]="row"
                [definition]="$any(child)"
                [loading]="loading"
                [displayHeader]="false"
              >
              </soft-dynamic-group-value>
              <ng-template #valueTemplate>
                <soft-dynamic-value
                  *ngIf="child.visible | booleanRule: row:true"
                  [definition]="child"
                  [value]="row"
                  [ngClass]="child.class | classRule: row"
                ></soft-dynamic-value>
              </ng-template>
            </td>
          </tr>
        </tbody>
        <tfoot *ngIf="footer">
          <tr *cdkVirtualFor="let row of footer">
            <td
              *ngFor="let child of definition.definitions"
              [ngClass]="['soft-table-cell', 'soft-table-cell-' + child.type]"
            >
              <soft-dynamic-value
                *ngIf="child.visible | booleanRule: row:true"
                [definition]="child"
                [value]="row"
                [ngClass]="child.class | classRule: row"
              ></soft-dynamic-value>
            </td>
          </tr>
        </tfoot>
      </table>
    </cdk-virtual-scroll-viewport>
  </ng-template>

  <ng-template #skeleton>
    <table *ngIf="!virtual; else virtualTemplate" [ngClass]="tableClass">
      <thead>
        <tr>
          <th *ngIf="selectionMode === 'multi'"></th>
          <th *ngFor="let column of definition.definitions">
            {{ column.title }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *softRepeat="skeletonRows">
          <td *ngIf="selectionMode === 'multi'" class="w-8 text-center">
            <soft-checkbox [disabled]="true"></soft-checkbox>
          </td>
          <td
            *ngFor="let child of definition.definitions"
            [ngClass]="['soft-table-cell', 'soft-table-cell-' + child.type]"
          >
            <soft-dynamic-skeleton
              [definition]="child"
              [ngClass]="child.class | classRule: {}"
            >
            </soft-dynamic-skeleton>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-template>
</ng-container>

<ng-template #noDefinitionTemplate>{{
  '#DYNAMIC.NO_DEFINITION' | translate
}}</ng-template>
