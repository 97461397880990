import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';
import {DateService, Store} from '@softline/core';
import {SOFTLINE_FEATURE_MODAL} from '../../modal/modal.shared';
import * as ModalStore from '../../modal/modal.store';

@Component({
  selector: 'soft-date-navigator',
  templateUrl: './date-navigator.component.html',
  styleUrls: ['./date-navigator.component.scss'],
})
export class DateNavigatorComponent implements OnInit {
  isPickerOpen = false;

  @Input() date: string = this.dateService.today();
  @Output() dateChange = new EventEmitter<string>();

  constructor(
    private dateService: DateService,
    private readonly store: Store
  ) {}

  ngOnInit(): void {}

  onChange(date: string, days: number = 0): void {
    const m = moment(date).add(days, 'day');
    this.date = m.toISOString(true);
    this.dateChange.emit(m.toISOString(true));
  }

  async openDatePickerDialog(): Promise<void> {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.date,
    );

    if (!result || result === 'DISMISSED')
      return;

    this.onChange(result);
  }
}
