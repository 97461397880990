import {Component, ContentChild, HostBinding, Input, TemplateRef, ViewChild} from '@angular/core';
import { AccordionItemHeaderDirective } from '../../directives/accordion-item-header/accordion-item-header.directive';
import { AccordionItemContentDirective } from '../../directives/accordion-item-content/accordion-item-content.directive';
import { AccordionComponent } from '../../accordion.component';
@Component({
  selector: 'soft-accordion-item',
  templateUrl: './accordion-item.component.html',
  styleUrls: ['./accordion-item.component.scss'],
})
export class AccordionItemComponent {

  @ViewChild('accordion') template!: TemplateRef<any>

  @Input() class = '';

  @ContentChild(AccordionItemHeaderDirective) header!: AccordionItemHeaderDirective;
  @ContentChild(AccordionItemContentDirective) content!: AccordionItemContentDirective;

  get expanded(): boolean {
    return this.accordion.isExpanded(this);
  }

  constructor(readonly accordion: AccordionComponent) { }

  toggle(): void {
    this.accordion.toggle(this);
  }
}
