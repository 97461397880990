import { Component, Input, OnInit } from '@angular/core';
import { DateService, Store } from '@softline/core';
import * as DynamicActionStore from '../../../../dynamic-action.store';
import { SOFTLINE_FEATURE_DYNAMIC_ACTIONS } from '../../../../dynamic.shared';
import { Definition } from '../../../../data/definitions';

@Component({
  selector: 'soft-dynamic-action-value',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss'],
})
export class ActionComponent implements OnInit {
  @Input() value?: unknown;
  @Input() action = '';
  @Input() context?: object;
  @Input() params?: unknown;
  @Input() definition?: Definition;
  @Input() control?: 'button' | 'link';

  constructor(private store: Store, private dateService: DateService) {}

  ngOnInit(): void {}

  onClick(): void {
    this.store.commit(
      SOFTLINE_FEATURE_DYNAMIC_ACTIONS,
      DynamicActionStore.mutations.add,
      {
        action: this.action,
        timestamp: this.dateService.now(),
        params: {
          value: this.value,
          context: this.context,
          definition: this.params,
        },
      }
    );
  }
}
