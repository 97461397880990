import { CommonModule } from '@angular/common';
import {
  ErrorHandler,
  Inject,
  ModuleWithProviders,
  NgModule,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { UiCoreModule } from '@softline/ui-core';
import { ConsoleSettingsComponent } from './settings/console-settings.component';
import {
  SOFTLINE_DATA_CONSOLE,
  SOFTLINE_SETTINGS_CONSOLE,
} from './console.shared';
import { BehaviorSubject } from 'rxjs';
import { DateService, Store } from "@softline/core";
import { ConsoleEntry } from './console.entry';
import { SOFTLINE_CONFIG_SETTINGS } from '../application.shared';
import { SOFTLINE_SETTINGS_SCANNER } from '../scanner/scanner.shared';
import { ScannerSettingsComponent } from '../scanner/settings/scanner-settings.component';
import { CustomErrorHandlerService } from './services/custom-error-handler.service';
import { JsonObjectTreeComponent } from './settings/json-object-tree/json-object-tree.component';
import { SOFTLINE_FEATURE_DEVELOPER } from "../developer/developer.shared";
import { DeveloperStore } from "../developer/developer.store";

@NgModule({
  declarations: [ConsoleSettingsComponent, JsonObjectTreeComponent],
  imports: [CommonModule, ReactiveFormsModule, UiCoreModule],
  exports: [],
  providers: [],
})
export class ConsoleModule {
  static forRoot(): ModuleWithProviders<ConsoleRootModule> {
    return {
      ngModule: ConsoleRootModule,
      providers: [
        {
          provide: SOFTLINE_CONFIG_SETTINGS,
          multi: true,
          useFactory: (store: Store) => ({
            key: SOFTLINE_SETTINGS_CONSOLE,
            component: ConsoleSettingsComponent,
            title: '#APPLICATION.CONSOLE.TITLE',
            default: {},
            icon: 'fas fa-terminal',
            visible: store.observe(SOFTLINE_FEATURE_DEVELOPER, DeveloperStore.getters.isDeveloper)
          }),
          deps: [Store]
        },
        {
          provide: SOFTLINE_DATA_CONSOLE,
          useValue: new BehaviorSubject<ConsoleEntry[]>([]),
        },
        { provide: ErrorHandler, useClass: CustomErrorHandlerService },
      ],
    };
  }
}

@NgModule({
  imports: [ConsoleModule],
})
export class ConsoleRootModule {
  constructor(
    dateService: DateService,
    @Inject(SOFTLINE_DATA_CONSOLE) consoleData: BehaviorSubject<ConsoleEntry[]>
  ) {
    const nativeConsole = console;
    const newConsole = { ...console };

    const log = (
      type: 'error' | 'warn' | 'debug' | 'info' | 'log',
      data: any[]
    ) => {
      nativeConsole[type](...data);
      const currentValue = consoleData.value;
      const nextValue: ConsoleEntry[] = [
        ...currentValue,
        {
          timestamp: dateService.now(),
          type,
          data,
        },
      ];
      consoleData.next(nextValue);
    };
    newConsole.error = (...data: any[]) => log('error', data);
    newConsole.warn = (...data: any[]) => log('warn', data);
    newConsole.info = (...data: any[]) => log('info', data);
    newConsole.log = (...data: any[]) => log('log', data);
    newConsole.debug = (...data: any[]) => log('debug', data);

    window.console = newConsole;
  }
}
