import {ChangeDetectionStrategy, Component, Inject, Injector, OnDestroy, OnInit, ViewContainerRef} from "@angular/core";
import {
  AddOnHost,
  AddOnService,
  BackNavigable,
  BackNavigationService,
  Command,
  CommandStore,
  FilterAndSortComponent,
  handleRequestErrors,
  Refreshable,
  SOFTLINE_FEATURE_COMMANDS,
  SOFTLINE_FEATURE_TITLE,
  TitleStore
} from "@softline/application";
import {
  base64Decode,
  base64Encode,
  Dictionary,
  Filter,
  FilterService,
  QueryStore,
  SOFTLINE_SERVICE_UUID,
  Sort,
  SortService,
  Store
} from "@softline/core";
import {
  asapScheduler,
  BehaviorSubject,
  combineLatestWith,
  Observable,
  observeOn,
  of,
  Subscription,
  switchMap
} from "rxjs";
import {map, skip} from "rxjs/operators";
import {
  Definition,
  DefinitionFilterPipe,
  DefinitionSortPipe,
  DefinitionStore,
  DynamicActionStore,
  DynamicModule,
  ObjectDefinition,
  SOFTLINE_FEATURE_DEFINITIONS,
  SOFTLINE_FEATURE_DYNAMIC_ACTIONS
} from "@softline/dynamic";
import {SOFTLINE_DEFINITION_VOUCHER_LIST, SOFTLINE_DEFINITION_VOUCHER_QUERY} from "../../gesamtabfrage.api";
import {Beleg} from "../../types/beleg.model";
import {ActivatedRoute, Router} from "@angular/router";
import {Belegart} from "../../types/belegart.model";
import {
  GesamtabfrageConfig,
  SOFTLINE_CONFIG_GESAMTABFRAGE,
  SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG,
  SOFTLINE_FEATURE_GESAMTABFRAGE_BELEGART
} from "../../gesamtabfrage.shared";
import {UiCoreModule} from "@softline/ui-core";
import {CommonModule} from "@angular/common";
import {BelegStore} from "../../store/beleg.store";
import {BelegartStore} from "../../store";
import {DownloadCommand} from "../../commands/download.command";
import {SOFTLINE_CONST_DYNAMIC_ACTION_DOWNLOAD} from '@softapps/migel/core';

@Component({
  standalone: true,
  // tslint:disable-next-line:component-selector
  selector: 'soft-gesamtabfrage-list',
  templateUrl: './gesamtabfrage-list.component.html',
  styleUrls: ['./gesamtabfrage-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, UiCoreModule, DynamicModule, FilterAndSortComponent, DefinitionFilterPipe, DefinitionSortPipe]
})
export class GesamtabfrageListComponent
  implements OnInit, OnDestroy, Refreshable, BackNavigable, AddOnHost
{
  private token?: string;
  private querySubscription?: Subscription;
  private paramSubscription?: Subscription;
  private actionSubscription?: Subscription;

  private clickedBeleg$ = new BehaviorSubject<Beleg | null>(null)

  private downloadCommand = new DownloadCommand(
    this.store,
    this.clickedBeleg$.pipe(map(o => o ? [o] : [])),
    this.uuid,
    this.config,
    this.injector
  );

  sort$ = this.store.observe(
    SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG,
    BelegStore.getters.sort
  )
  filter$ = this.store.observe(
    SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG,
    BelegStore.getters.filter
  )

  all$ = this.store.observe(
    SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG,
    BelegStore.getters.all
  );

  data$ = this.all$
    .pipe(
      combineLatestWith(this.sort$, this.filter$),
      map(([values, sort, filter]) =>
        this.sortService.sort(this.filterService.filter(values, filter), sort)
      )
    )

  footer$ = this.data$
    .pipe(
      map( o => [o.reduce((p, c: any) => ({...p, gesamt: p.gesamt + (c.gesamt ?? 0), gesamtIncl: p.gesamtIncl + (c.gesamtIncl ?? 0)}), {konto: {name: "Gesamt"}, gesamt: 0, gesamtIncl: 0})])
  )

  belegLength$ = this.store
    .observe(SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG, BelegStore.getters.ids)
    .pipe(map((o) => o.length));

  loading$ = this.store
    .observe(SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG, BelegStore.getters.querying)
    .pipe(observeOn(asapScheduler));

  loaded$ = this.store.observe(
    SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG,
    BelegStore.getters.queried
  );

  total$ = this.store
    .observe(SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG, BelegStore.getters.all)
    .pipe(map((o) => o?.length));

  selected$ = this.store.observe(
    SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG,
    BelegStore.getters.selected
  );

  selectedLength$ = this.selected$.pipe(map((o) => o.length));

  activeType$ = this.route.paramMap.pipe(
    map((o) => o.get('type')),
    combineLatestWith(
      this.store.observe(
        SOFTLINE_FEATURE_GESAMTABFRAGE_BELEGART,
        BelegartStore.getters.entities
      )
    ),
    map(([type, entities]) => entities[type ?? -1])
  );

  definition$: Observable<Definition> = this.activeType$.pipe(
    switchMap(o => this.store.observe(
      SOFTLINE_FEATURE_DEFINITIONS,
      DefinitionStore.getters.definition,
      [
        `${SOFTLINE_DEFINITION_VOUCHER_LIST}+${o?.id}`,
        SOFTLINE_DEFINITION_VOUCHER_LIST
      ])
    ));

  query$ = this.store.observe(
    SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG,
    QueryStore.getters.query.query
  );
  queryDefinition$ = this.activeType$.pipe(
    switchMap(o => this.store.observe(
      SOFTLINE_FEATURE_DEFINITIONS,
      DefinitionStore.getters.definition,
      [
        `${SOFTLINE_DEFINITION_VOUCHER_QUERY}+${o?.id}`,
        SOFTLINE_DEFINITION_VOUCHER_QUERY
      ])
    ),
    map((o) => o as ObjectDefinition)
  );

  constructor(
    private store: Store,
    protected router: Router,
    private backNavigationService: BackNavigationService,
    private addOnService: AddOnService,
    public route: ActivatedRoute,
    public vcRef: ViewContainerRef,
    private injector: Injector,
    private sortService: SortService,
    private filterService: FilterService,
    @Inject(SOFTLINE_SERVICE_UUID) private uuid: () => string,
    @Inject(SOFTLINE_CONFIG_GESAMTABFRAGE) private config: GesamtabfrageConfig
  ) {}

  async ngOnInit(): Promise<void> {
    this.backNavigationService.set(this);
    await this.addOnService.attachTo(this);

    this.actionSubscription = this.store.observe(
      SOFTLINE_FEATURE_DYNAMIC_ACTIONS,
      DynamicActionStore.getters.action,
      SOFTLINE_CONST_DYNAMIC_ACTION_DOWNLOAD
    )
    .pipe(skip(1))
    .subscribe(o => {
      this.download(o?.params.context as Beleg);
    });

    this.paramSubscription = this.activeType$.subscribe((o) => {
      if (!o)
        return;
      this.store.commit(
        SOFTLINE_FEATURE_TITLE,
        TitleStore.mutations.setTitle,
        o?.kundenName
      );
      this.store.commit(
        SOFTLINE_FEATURE_GESAMTABFRAGE_BELEGART,
        BelegartStore.mutations.select,
        o
      );
      this.store.commit(
        SOFTLINE_FEATURE_COMMANDS,
        CommandStore.mutations.addSet,
        {
          name: GesamtabfrageListComponent,
          commands: this.createCommands(o),
        }
      );
      try {
        this.store.dispatch(
          SOFTLINE_FEATURE_DEFINITIONS,
          DefinitionStore.actions.loadOnce,
          { name: `${SOFTLINE_DEFINITION_VOUCHER_LIST}+${o.id}`  })
      }
      catch (e) {
        handleRequestErrors(this.store, e);
      }
    });

    this.querySubscription = this.route.queryParamMap
      .pipe(map((o) => o.get('query')))
      .subscribe(async (encodedQuery) => {
        if (!encodedQuery)
          return;

        try {
          const query = JSON.parse(base64Decode(encodedQuery));

          this.store.commit(
            SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG,
            BelegStore.mutations.query.setQuery,
            query
          );

          if (this.token)
            await this.store.dispatch(
              SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG,
              BelegStore.actions.subscription.cancel,
              this.token
            );

          this.store.commit(SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG, BelegStore.mutations.clear);

          this.token = this.uuid();

          await this.store.dispatch(
            SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG,
            BelegStore.actions.query,
            {
              query,
              clear: true,
              token: this.token,
            }
          );
        } catch (e) {
          handleRequestErrors(this.store, e);
        } finally {
          this.token = undefined;
        }
      });
  }

  async ngOnDestroy(): Promise<void> {
    if (this.token)
      await this.store.dispatch(
        SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG,
        BelegStore.actions.subscription.cancel,
        this.token
      );

    this.backNavigationService.set(undefined);
    await this.addOnService.detachFrom(this);

    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      ''
    );

    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.removeSet,
      GesamtabfrageListComponent
    );

    if (this.querySubscription && !this.querySubscription.closed)
      this.querySubscription.unsubscribe();
    this.querySubscription = undefined;

    if (this.paramSubscription && !this.paramSubscription.closed)
      this.paramSubscription.unsubscribe();
    this.paramSubscription = undefined;

    if (this.actionSubscription && !this.actionSubscription.closed)
      this.actionSubscription.unsubscribe();
    this.actionSubscription = undefined;
  }

  async refresh(): Promise<void> {
    const query = this.store.get(
      SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG,
      BelegStore.getters.query.query
    ) as any;
    const queryParams: Dictionary<any> = { ...query };
    queryParams['konto'] = query.customer?.nummer
      ? {
          id: query.customer.id,
          number: query.customer.nummer,
          name: query.customer.kurzbez,
        }
      : query.konto;
    await this.store.dispatch(
      SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG,
      BelegStore.actions.query,
      { query: queryParams, clear: true }
    );
  }

  onSelectedItemsChange(entities: Beleg[]): void {
    this.store.commit(
      SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG,
      BelegStore.mutations.setSelected,
      entities.map((o) => o.id)
    );
  }

  async onQueryChange(query: unknown, type: Belegart): Promise<void> {
    // this.store.commit(SOFTLINE_FEATURE_BELEG, BelegStore.mutations.query.setQuery, query as any);
    await this.router.navigate(['/gesamtabfrage', type.id, 'list'], {
      queryParams: { query: base64Encode(JSON.stringify(query)) },
    });
  }

  protected createCommands(type: Belegart): Command[] {
    if (!type) return [];
    const commands: Command[] = [
      {
        name: type.kundenName,
        class: 'menu main-menu main-menu-top title',
      },
      {
        icon: 'fas fa-search',
        name: '#GESAMTABFRAGE.MENU.FIND',
        class: 'menu main-menu main-menu-top',
        routerLink: ['/gesamtabfrage', type.id, 'find'],
      },
      {
        icon: 'fas fa-list',
        name: '#GESAMTABFRAGE.MENU.RESULTS',
        class: 'menu main-menu main-menu-top',
        routerLink: ['/gesamtabfrage', type.id, 'list'],
      },
      new DownloadCommand(
        this.store,
        this.selected$,
        this.uuid,
        this.config,
        this.injector
      ),
    ];
    if (this.config.geschaeftsfall)
      commands.push({
        icon: 'fas fa-briefcase',
        name: '#GESAMTABFRAGE.ACTIONS.BUSINESS_CASE',
        class: 'menu action-menu action-menu-top',
        canExecute: this.selectedLength$.pipe(map((o) => o === 1)),
        execute: async () => {
          const selected = this.store.get(
            SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG,
            BelegStore.getters.selected
          );
          if (selected.length === 1)
            await this.router.navigate([
              '/gesamtabfrage',
              type.id,
              'business-case',
              selected[0].id,
            ]);
        },
      });
    return commands;
  }

  async navigateBack(): Promise<void> {
    const type = this.route.snapshot.paramMap.get('type');
    await this.router.navigate(['/gesamtabfrage', type, 'find']);
  }

  async onFilterChange(filter: Filter | null): Promise<void> {
    this.store.commit(SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG,
      BelegStore.mutations.setFilter,
      filter
    )
  }

  async onSortChange(sort: Sort | null): Promise<void> {
    this.store.commit(SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG,
      BelegStore.mutations.setSort,
      sort
    )
  }

  async download(beleg: Beleg): Promise<void> {
    this.clickedBeleg$.next(beleg);

    try {
      await this.downloadCommand.execute();
    } catch (e) {
      handleRequestErrors(this.store, e);
    }
  }
}
