import {EnvironmentProviders, importProvidersFrom, Provider,} from '@angular/core';
import {SOFTLINE_FEATURE_USER_CONTACT, SOFTLINE_SETTINGS_USER_CONTACT, UserContactStore,} from '../../utilities.shared';
import {SOFTLINE_CONFIG_SETTINGS} from '@softline/application';
import {SettingsComponent} from './components/settings.component';
import {
  ConnectionHttpService,
  SOFTLINE_SERVICE_HTTP,
  SOFTLINE_STORE_REMOTE_OBJECT_SERVICE,
  SoftlineObjectService,
  Store,
  StoreModule
} from "@softline/core";
import {SOFTLINE_API_USER_CONTACT} from '../../user-contact/user-contact.api';
import {AuthenticationContextStore, SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT} from "@softline/auth";
import {map} from "rxjs";

export const SOFTLINE_SETTINGS_USER_CONTEXT = 'userContext';

export const contextSettingsProviders: (
  | Provider
  | EnvironmentProviders
)[] = [
  {
    provide: SOFTLINE_CONFIG_SETTINGS,
    multi: true,
    useFactory: (store: Store) => {
      return {
        key: SOFTLINE_SETTINGS_USER_CONTEXT,
        component: SettingsComponent,
        title: '#UTILITIES.SETTINGS.CONTEXT.TITLE',
        icon: 'fas fa-briefcase',
        visible: store.observe(SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT, AuthenticationContextStore.getters.data)
          .pipe(map(o => !!o))
      }
    },
    deps: [Store]
  }
];
