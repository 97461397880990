import {Component, Inject, OnInit, Optional, TemplateRef, Type, ViewChild} from "@angular/core";
import {CommonModule} from "@angular/common";
import {Store} from "@softline/core";
import {EditContextDialogComponent,} from "../../../dialogs/edit-context-dialog/edit-context-dialog.component";

import {switchMap} from "rxjs/operators";
import {AuthenticationContextStore, SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT} from "@softline/auth";
import {ModalOption, ModalStore, SOFTLINE_FEATURE_MODAL} from "@softline/ui-core";
import {RefreshService} from "@softline/application";
import {
  AccountContextConfiguration,
  SOFTLINE_CONFIG_CONTEXT_ACCOUNT, SOFTLINE_CONFIG_EDIT_CONTEXT_COMPONENT,
  SOFTLINE_FEATURE_UTILITIES_ACCOUNT, SOFTLINE_FEATURE_UTILITIES_PATCH_CONTEXT, SOFTLINE_FEATURE_UTILITIES_VKFORGS,
  UtilitiesAccountStore
} from "../../../../utilities.shared";
import {ContextOption} from '../../../types/context-option';
import {Account} from '../../data/account';
import {PatchContextService} from '../../../services/patch-context.service';
import {PatchContextStore, VkforgStore} from '@softapps/allgemein/utils';

@Component({
  selector: 'soft-account-context-widget',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './account-context-widget.component.html',
  styleUrls: ['./account-context-widget.component.scss'],
})
export class AccountContextWidgetComponent implements OnInit {

  userAccounts$ = this.store.observe(
    SOFTLINE_FEATURE_UTILITIES_ACCOUNT,
    UtilitiesAccountStore.getters.all
  );

  context$ = this.store.observe(
    SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
    AuthenticationContextStore.getters.data
  );

  activeUserAccount$ = this.context$
    .pipe(
      switchMap(context => this.store.observe(
        SOFTLINE_FEATURE_UTILITIES_ACCOUNT,
        UtilitiesAccountStore.getters.entity,
        (context as any)?.accountid)
      )
    );

  constructor(
    private store: Store,
    private refreshService: RefreshService,
    private patchContextService: PatchContextService,
    @Inject(SOFTLINE_CONFIG_CONTEXT_ACCOUNT) readonly accountContextConfig: AccountContextConfiguration,
    @Optional()
    @Inject(SOFTLINE_CONFIG_EDIT_CONTEXT_COMPONENT)
    public components?: Type<Component>[]
  ) {}

  async ngOnInit(): Promise<void> {
    await this.store.dispatch(
      SOFTLINE_FEATURE_UTILITIES_ACCOUNT,
      UtilitiesAccountStore.actions.loadMany,
      { clear: true }
    );
  }

  async onChangeAccount(accounts: Account[]): Promise<void> {
    const options = accounts.map<ModalOption<string>>(o => ({
      value: o?.id + '',
      label: o?.number ? `${o?.number} - ${o?.name}` : `${o?.name ?? ''}`,
    }));

    if (this.components && this.components.length > 1) {
      options.push({
        value: 'FIRMENWECHSEL_OEFFNEN',
        label: '#UTILITIES.CONTEXT.DETAIL_CONTEXT_SETTINGS',
        icon: 'fas fa-user-cog'
      })
    }

    await this.patchContextService.patchContextOptionsDialog<string>({
      title: 'Konto auswählen',
      options,
      editContextDialogOptionValue: 'FIRMENWECHSEL_OEFFNEN',
      setOnSelect: (contextResult) => {
        return {
          accountid: +contextResult,
        }
      }
    });
  }
}
