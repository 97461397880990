<div
  class="c row center soft-pull-to-refresh-indicator"
  [ngStyle]="{ opacity: getOpacity }"
>
  <span
    class="fas fa-arrow-down transition-opacity-rotation"
    style="align-self: center; justify-self: center"
    [ngStyle]="{ opacity: getOpacity, transform: getTransform }"
    *ngIf="!showSpinner"
  >
  </span>
  <soft-loading-spinner
    style="align-self: center; justify-self: center"
    [ngStyle]="{ opacity: getOpacity }"
    *ngIf="showSpinner"
    @enterLeave
  >
  </soft-loading-spinner>
</div>
