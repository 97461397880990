import { InjectionToken } from '@angular/core';

export const SOFTLINE_PERMISSIONS_FREIGABE = 'FREIGABE';

export const SOFTLINE_FEATURE_FREIGABE = 'freigabe';
export const SOFTLINE_DEFINITION_FREIGABE_LIST = 'freigabe-list';
export const SOFTLINE_DEFINITION_FREIGABE_DETAIL = 'freigabe-detail';
export const SOFTLINE_CONST_WORKFLOW_DATA_DETAIL = 'detail';
export const SOFTLINE_WIDGET_SOFTAPPS_FIBU_FREIGABE = 'softappsFibuFreigabe'
export const SOFTLINE_MESSAGE_SOFTAPPS_FIBU_FREIGABE_NEXT = 'SOFTLINE_MESSAGE_SOFTAPPS_FIBU_FREIGABE_NEXT';
export const SOFTLINE_MESSAGE_SOFTAPPS_FIBU_FREIGABE_REQUEST_NEXT = 'SOFTLINE_MESSAGE_SOFTAPPS_FIBU_FREIGABE_REQUEST_NEXT';
export const SOFTLINE_MESSAGE_SOFTAPPS_FIBU_FREIGABE_LOADED = 'SOFTLINE_MESSAGE_SOFTAPPS_FIBU_FREIGABE_LOADED';
