<div class="soft-carousel overflow-hidden">
  <span
    class="soft-carousel-indicator left pointer"
    [class.disabled]="(canPrevious$ | async) === false"
    (click)="onPrevious(container)"
  >
    <i class="fas fa-chevron-left"></i>
  </span>

  <div #container class="soft-carousel" [class.whitespace-nowrap]="fluid">
    <div
      *ngFor="let item of items$ | async; let i = index; trackBy: trackByFn"
      class="w-full"
    >
      <ng-container
        *ngTemplateOutlet="template; context: { $implicit: item, index: i }"
      ></ng-container>
    </div>
  </div>

  <span
    class="soft-carousel-indicator right pointer"
    [class.disabled]="(canNext$ | async) === false"
    (click)="onNext(container)"
  >
    <i class="fas fa-chevron-right"></i>
  </span>
</div>
