import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@softline/core';
import {
  Command,
  CommandStore,
  handleRequestErrors,
  Refreshable,
  RefreshService,
  SOFTLINE_FEATURE_COMMANDS,
  SOFTLINE_FEATURE_TITLE,
  TitleStore,
} from '@softline/application';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import {
  GesamtabfrageConfig,
  SOFTLINE_CONFIG_GESAMTABFRAGE,
  SOFTLINE_FEATURE_GESAMTABFRAGE_BELEGART,
} from '../gesamtabfrage.shared';
import {
  DefinitionStore,
  SOFTLINE_FEATURE_DEFINITIONS,
} from '@softline/dynamic';
import {
  SOFTLINE_DEFINITION_VOUCHER_BUSINESS_CASE,
  SOFTLINE_DEFINITION_VOUCHER_LIST,
  SOFTLINE_DEFINITION_VOUCHER_QUERY,
} from '../gesamtabfrage.api';
import { BelegartStore } from '../store';

@Component({
  standalone: true,
  // tslint:disable-next-line:component-selector
  selector: 'soft-gesamtabfrage',
  templateUrl: './gesamtabfrage.component.html',
  styleUrls: ['./gesamtabfrage.component.scss'],
  imports: [RouterModule],
})
export class GesamtabfrageComponent implements OnInit, OnDestroy, Refreshable {
  constructor(
    private store: Store,
    private refreshService: RefreshService,
    @Inject(SOFTLINE_CONFIG_GESAMTABFRAGE) private config: GesamtabfrageConfig
  ) {}

  async ngOnInit(): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      '#GESAMTABFRAGE.TITLE'
    );
    this.refreshService.add(this);
    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.addSet,
      {
        name: GesamtabfrageComponent,
        commands: this.createCommands(),
      }
    );

    try {
      await this.store.dispatch(
        SOFTLINE_FEATURE_GESAMTABFRAGE_BELEGART,
        BelegartStore.actions.loadMany,
        {}
      );
    } catch (e) {
      handleRequestErrors(this.store, e);
    }

    await this.loadDefinitions();
  }

  ngOnDestroy() {
    this.refreshService.remove(this);
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      ''
    );
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setSubTitle,
      ''
    );
    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.removeSet,
      GesamtabfrageComponent
    );
  }

  async refresh(): Promise<void> {
    await this.store.dispatch(
      SOFTLINE_FEATURE_GESAMTABFRAGE_BELEGART,
      BelegartStore.actions.loadMany,
      {}
    );
  }

  private createCommands(): Command[] {
    return [
      {
        icon: 'fas fas fa-file-invoice',
        name: '#GESAMTABFRAGE.MENU.TITLE',
        class: 'menu main-menu main-menu-top',
        routerLink: ['/gesamtabfrage'],
      },
    ];
  }

  async loadDefinitions(): Promise<void> {
    try {
      const promises: Promise<any>[] = [
        this.store.dispatch(
          SOFTLINE_FEATURE_DEFINITIONS,
          DefinitionStore.actions.loadOnce,
          { name: SOFTLINE_DEFINITION_VOUCHER_LIST }
        ),
        this.store.dispatch(
          SOFTLINE_FEATURE_DEFINITIONS,
          DefinitionStore.actions.loadOnce,
          { name: SOFTLINE_DEFINITION_VOUCHER_QUERY }
        ),
      ];
      if (this.config.geschaeftsfall)
        promises.push(
          this.store.dispatch(
            SOFTLINE_FEATURE_DEFINITIONS,
            DefinitionStore.actions.loadOnce,
            { name: SOFTLINE_DEFINITION_VOUCHER_BUSINESS_CASE }
          )
        );

      await Promise.all(promises);
    }
    catch (e) {
      handleRequestErrors(this.store, e);
    }
  }
}
