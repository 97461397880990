import { Definition } from './data/definitions';
import {
  ConnectionResourceLocation,
  createAction,
  createGetter,
  createMutation,
  Dictionary,
  mutate,
  on,
  StoreFeature,
  select, isDefined
} from "@softline/core";
import { DefinitionService } from './services/definition.service';
import { SOFTLINE_API_DEFINITION } from "./dynamic.api";
import { SOFTLINE_CONFIG_LOAD_CUSTOM_DEFINITIONS, SOFTLINE_CONFIG_DEFINITION } from "./dynamic.shared";

export interface State {
  loading: string[];
  definitions: Dictionary<Definition | null>;
}

export const mutations = {
  setLoading: createMutation<State, string>('setLoading'),
  removeLoading: createMutation<State, string>('removeLoading'),
  loadSuccess: createMutation<State, { name: string; definition: Definition }>(
    'loadSuccess'
  ),
  add: createMutation<State, { name: string; definition: Definition; }>('add'),
};

export const actions = {
  load: createAction<
    State,
    { name: string; location?: ConnectionResourceLocation },
    Definition
  >('load'),
  loadOnce: createAction<
    State,
    { name: string; location?: ConnectionResourceLocation },
    Definition
  >('load once'),
};

export const getters = {
  definition: createGetter<State, Definition, string | string[]>('definition'),
  loading: createGetter<State, boolean, string>('loading'),
};

export const feature: StoreFeature<State> = {
  initialState: {
    loading: [],
    definitions: {},
  },
  mutations: [
    mutate(mutations.setLoading, ({ state, params }) => ({
      ...state,
      loading: [...state.loading, params],
    })),
    mutate(mutations.removeLoading, ({ state, params }) => ({
      ...state,
      loading: state.loading.filter((o) => o !== params),
    })),
    mutate(mutations.loadSuccess, ({ state, params }) => {
      const loading = state.loading.filter((o) => o !== params.name);
      const definitions = { ...state.definitions };
      definitions[params.name] = params.definition ?? null;
      return { ...state, definitions, loading };
    }),
    mutate(mutations.add, ({ state, params }) => {
      const definitions = { ...state.definitions };
      definitions[params.name] = params.definition ?? null;
      return { ...state, definitions };
    }),
  ],
  actions: [
    on(
      actions.load,
      async ({ state, params, injector, featureName, store }) => {
        const service = injector.get(DefinitionService);
        store.commit(featureName, mutations.setLoading, params.name);

        let definition: Definition | undefined;
        const loadCustomDefinitions = injector.get(SOFTLINE_CONFIG_LOAD_CUSTOM_DEFINITIONS);

        if(loadCustomDefinitions || isDefined(params.location)) {
          try {
            let location = params.location;
            if(!location)
              location = {path: SOFTLINE_API_DEFINITION, pathParams: {name: params.name}};

            definition = await service.load(location);
            if(!definition)
              throw new Error('API returned an empty definition');
            store.commit(featureName, mutations.loadSuccess, {
              name: params.name,
              definition,
            });
            return definition;
          } catch (e) {
            store.commit(featureName, mutations.removeLoading, params.name);
          }
        }

        const registrations = injector.get(SOFTLINE_CONFIG_DEFINITION, []);
        definition = registrations
          .sort((f, s) =>
            (f.priority ?? Number.NEGATIVE_INFINITY) < (s.priority ?? Number.NEGATIVE_INFINITY)
              ? 1
              : (f.priority?? Number.NEGATIVE_INFINITY) > (s.priority ?? Number.NEGATIVE_INFINITY) ? -1 : 0
          )
          .find(o => o.name === params.name)?.definition;

        store.commit(featureName, mutations.loadSuccess, {
          name: params.name,
          definition,
        });
        return definition;
      }
    ),
    on(
      actions.loadOnce,
      async ({ state, params, injector, featureName, store, dispatch }) => {
        const existing = state.definitions[params.name];
        if (existing)
          return existing;
        return await dispatch(featureName, actions.load, params, injector);
      }
    ),
  ],
  getters: [
    select(
      getters.definition,
      ({ state, params }) => {
        if(typeof params === 'string')
          return state.definitions[params]

        for(const name of params)
          if(state.definitions[name])
            return state.definitions[name];
        return undefined;
      }
    ),
    select(
      getters.loading,
      ({ state, params }) => state.loading.indexOf(params) > -1
    ),
  ],
};
