<ng-container>
  <div [ngClass]="config.class" class="soft-message-bar" @fadeInOut *ngIf="config">
    <div class="start">
      <i
        *ngIf="config.icon"
        [ngClass]="config.icon"
        class="soft-message-bar-icon"
      ></i>
      <div class="flex flex-col w-full">
        <div class="flex flex-col">
          <strong *ngIf="config.title" class="mr-1">{{
            config.title | translate | interpolate: config.params
            }}</strong>
          <span class="mr-1">{{
            config.message | translate | interpolate: config.params
            }}</span>
        </div>

        <div class="flex items-center gap-3 mt-1" *ngIf="(config?.actions?.length || 0) > 0">
          <button
            *ngFor="let button of config.actions | filter: actionFilter:'button'"
            class="soft-button bg-[rgba(0,0,0,0.175)] rounded soft-primary-button-small mr-2"
            (click)="button?.onExecute()"
          >
            {{ button.title | translate }}
          </button>
          <a
            *ngFor="let link of config.actions | filter: actionFilter:'link'"
            class="soft-link mr-1"
            (click)="link?.onExecute()"
          >{{ link.title | translate }}</a
          >
        </div>
      </div>
    </div>

    <div class="end">
      <i
        *ngIf="config.dismiss?.canDismiss !== false"
        class="fas fa-times dismiss mr-2"
        (click)="onDismiss(config.id, config.onDismiss)"
      ></i>
    </div>
  </div>
</ng-container>
