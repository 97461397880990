<form
  [formGroup]="form"
  class="soft-form"
  [ngClass]="definition?.class"
  (ngSubmit)="onSubmit()"
  (submit)="onNativeSubmit($event)"
  (reset)="onReset($event)"
  #formRef="ngForm"
>
  <ng-container
    *ngFor="let propertyDefinition of definition?.definitions ?? []"
  >
    <soft-dynamic-group-input
      *ngIf="propertyDefinition.type === 'group'; else valueInput"
      [ngClass]="propertyDefinition.class | classRule: form.value"
      [definition]="$any(propertyDefinition)"
      [form]="form"
    >
    </soft-dynamic-group-input>
    <ng-template #valueInput>
      <div
        *ngIf="propertyDefinition.visible | booleanRule: form.value:true"
        [ngClass]="propertyDefinition.class | classRule: form.value"
        class="soft-dynamic-form-field"
      >
        <ng-container
          softDynamicFormField
          [definition]="propertyDefinition"
          [form]="form"
        ></ng-container>
      </div>
    </ng-template>
  </ng-container>
</form>
