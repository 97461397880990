<h2 class="text-xl font-semibold mt-3 ml-3">
  {{ title ?? '#UI_CORE.DIALOGS.FILE' | translate | interpolate: params }}
</h2>
<p *ngIf="subtitle" class="mb-1 mt-1 ml-3 mr-3 border-box color-light">
  {{ subtitle | translate | interpolate: params }}
</p>
<p *ngIf="content" class="mb-1 mt-1 ml-3 mr-3 border-box">
  <ng-container *ngTemplateOutlet="content; context: (params ?? null)"></ng-container>
</p>

<div class="mt-2 mr-3 w-full">
  <div>
    <soft-file-input
      [sources]="sources ?? 'all'"
      [accept]="accept"
      [inputView]="inputView ?? 'dragAndDrop'"
      [selectionMode]="selectionMode ?? 'single'"
      [valueView]="valueView ?? 'list'"
      (valueChange)="onValueChange($event)"
      #fileInput></soft-file-input>
  </div>
</div>
<div class="flex no-wrap mt-3">
  <button
    class="soft-button primary ok h-14 w-full"
    [disabled]="!fileInput.value?.length"
    (click)="onSubmit(fileInput.value)"
  >
    {{ '#UI_CORE.DIALOGS.OK' | translate }}
  </button>
</div>
