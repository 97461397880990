<ng-container *ngIf="offenePosten$ | async as offenePosten">
  <div class="flex flex-col soft-card rounded w-full">
    <div class="flex flex-row w-full justify-between">
      <div class="flex flex-col w-full">
        <div class="text-xl soft-overline">{{'#OFFENE_POSTEN.TITLE' | translate}}</div>
        <div class="text-sm text-light">{{'#OFFENE_POSTEN.SUB_TITLE' | translate}}</div>
        <div *ngIf="faelligkeitenInTotal$ | async as faelligkeitenInTotal" class="text-xl font-bold text-danger-600 mt-2">
          {{faelligkeitenInTotal | currencyFormat:'EUR':'symbol':'before':'':'de-AT'}}
        </div>
      </div>
      <div class="flex flex-col w-full">
        <div class="flex flex-row self-end gap-4">
          <div class="flex flex-row gap-3 justify-center items-center">
            <div class="flex w-[14px] h-[14px] soft-card-rounded-s bg-success-700"></div>
            <span>{{'#OFFENE_POSTEN.DIAGRAMM.LABEL_GUTSCHRIFTEN' | translate}}</span>
          </div>
          <div class="flex flex-row gap-3 justify-center items-center">
            <div class="w-[14px] h-[14px] soft-card-rounded-s bg-danger-600"></div>
            <span>{{'#OFFENE_POSTEN.DIAGRAMM.LABEL_FAELLIGKEITEN' | translate}}</span>
          </div>
          <div class="flex w-10 h-10 rounded-3xl bg-tertiary-300 justify-center ml-5">
            <i class="flex self-center fas fa-file-invoice-dollar text-tertiary-800"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full mt-2" *ngIf="chartData$ | async as chartData" #container2Ref>
      <ngx-charts-bar-horizontal-stacked
        class="bg-white block mt-4"
        [view]="[container2Ref.offsetWidth, 300]"
        [scheme]="colorScheme"
        [legend]="false"
        [gradient]="false"
        [xAxis]="true"
        [yAxis]="true"
        [results]="chartData"
        [showDataLabel]="true"
        [dataLabelFormatting]="formatDataLabel"
        [barPadding]="4">
      </ngx-charts-bar-horizontal-stacked>
    </div>
  </div>
</ng-container>

