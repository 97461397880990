<div class="g gtc-3 gtr-1">
  <div class="gc-1 w-1">
    <div class="soft-container soft-container-column">
      <label class="soft-label soft-label-top">{{
        '#UI_CORE.PAGINATOR.PAGESIZE' | translate
      }}</label>
      <soft-select
        class="soft-container w-1"
        [value]="pageSize"
        (valueChange)="setPageSize(select.value)"
        #select
      >
        <soft-select-option *ngFor="let size of pageSizes" [value]="size">{{
          size
        }}</soft-select-option>
      </soft-select>
    </div>
  </div>
  <span class="gc-2 gc-center gr-center">
    {{
      '#UI_CORE.PAGINATOR.INFO'
        | translate
        | interpolate
          : [
              1 + (page - 1) * pageSize,
              [page * pageSize, elements] | min,
              elements
            ]
    }}
  </span>
  <div class="gc-3 gc-right gr-center">
    <i
      class="fas fa-angle-double-left fa-2x pointer"
      [class.disabled]="!isPreviousPageEnabled"
      (click)="onFirstPage()"
    ></i>
    <i
      class="fas fa-angle-left fa-2x pointer"
      [class.disabled]="!isPreviousPageEnabled"
      (click)="onPreviousPage()"
    ></i>
    <i
      class="fas fa-angle-right fa-2x pointer"
      [class.disabled]="!isNextPageEnabled"
      (click)="onNextPage()"
    ></i>
    <i
      class="fas fa-angle-double-right fa-2x pointer"
      [class.disabled]="!isNextPageEnabled"
      (click)="onLastPage()"
    ></i>
  </div>
</div>
