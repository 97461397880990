<div
  class="soft-container soft-container-column"
  *ngIf="form && definition"
  [formGroup]="form"
>
  <label class="soft-label soft-label-top">
    {{ definition.title }}
    <span
      class="required-indicator"
      *ngIf="definition.required | booleanRule: form.value"
      >*</span
    >
  </label>
  <soft-duration-input
    [formControlName]="definition.name"
    [format]="definition.format | formatRule: form.value"
  ></soft-duration-input>
  <soft-validation-container
    class="soft-property-validation"
    *ngIf="form.controls[definition.name].touched"
    [validation]="form | combineValidations: definition.name"
  ></soft-validation-container>
</div>
