import { BulkEntityService, EntityService, UploadEntityService } from "./entity.service";
import { Dictionary } from '../types/dictionary';
import { mergeMap, Observable, of, switchMap, toArray } from "rxjs";
import { ResourceService } from '../data/abstraction';
import { ConnectionResourceLocation } from '../data/specialized/http/connection.location';
import { SOFTLINE_SERVICE_HTTP } from '../core.shared';
import { Inject } from '@angular/core';
import { Entity } from '../store/specialized/entity/types/entity';
import { Patch } from '../store/specialized/entity/types/patch';
import { RequestEvent } from '../data/request';
import { catchError, map } from "rxjs/operators";
import { isDefined } from '../functions/is-defined.function';
import { Change, LazySaveResult } from "../store/specialized/entity/types/change";
import { fromArrayLike } from "rxjs/internal/observable/innerFrom";
import { HttpErrorResponse } from "@angular/common/http";
import { RequestError } from "../types/errors";

export class SoftlineEntityService<T extends Entity, TCreate = T>
  implements EntityService<T, TCreate>, UploadEntityService<TCreate, T>, BulkEntityService<T> {
  entityPath = "";

  constructor(
    @Inject(SOFTLINE_SERVICE_HTTP)
    protected service: ResourceService<ConnectionResourceLocation>,
    protected path: string
  ) {
    if (!this.path.endsWith("/")) this.path = this.path + "/";
    this.entityPath = this.path + "{{id}}";
  }

  get(
    id: string,
    pathParams: Dictionary<unknown> | undefined,
    queryParams: Dictionary<unknown> | undefined,
    body: unknown | undefined
  ): Observable<T> {
    const location = {
      path: this.entityPath,
      pathParams: { ...pathParams, id },
      queryParams,
    };
    return this.service.get<T, unknown>(location, body);
  }

  getMany(
    pathParams: Dictionary<unknown> | undefined,
    queryParams: Dictionary<unknown> | undefined
  ): Observable<T[]> {
    const location = { path: this.path, pathParams, queryParams };
    return this.service.get<T[]>(location);
  }

  create(
    entity: TCreate,
    pathParams: Dictionary<unknown> | undefined
  ): Observable<T> {
    const location = { path: this.path, pathParams };
    entity = { ...entity, id: undefined };
    return this.service.create<TCreate, T>(location, entity);
  }

  update(
    entity: T,
    pathParams: Dictionary<unknown> | undefined
  ): Observable<T> {
    const location = {
      path: this.entityPath,
      pathParams: { ...pathParams, id: entity.id },
    };
    entity = { ...entity, id: +entity.id };
    return this.service.update<T, T>(location, entity);
  }

  patch(
    patch: Patch<T>,
    pathParams: Dictionary<unknown> | undefined
  ): Observable<T> {
    const location = {
      path: this.entityPath,
      pathParams: { ...pathParams, id: patch.id },
    };
    return this.service.patch<T, T>(location, patch.changes);
  }

  delete(
    entity: T,
    pathParams: Dictionary<unknown> | undefined
  ): Observable<T> {
    const location = {
      path: this.entityPath,
      pathParams: { ...pathParams, id: entity.id },
    };
    return this.service.delete<T>(location);
  }

  upload(
    entity: TCreate,
    pathParams?: Dictionary<unknown>
  ): Observable<RequestEvent<T>> {
    const location = { path: this.path, pathParams };
    return this.service.upload<TCreate, T>(location, entity).pipe(
      map((o) => {
        if (o.type === 'response')
          return {
            ...o,
            response: o.response ? { ...o.response, id: o.response.id } : null,
          };
        return o;
      })
    );
  }

  save(changes: readonly Change<T>[], pathParams?: Dictionary<unknown> | undefined): Observable<LazySaveResult<T>> {
    return of(...changes)
      .pipe(
        mergeMap((change: Change<T>) => {
          let returnValue: Observable<T>;
          switch (change.action) {
            case "create":
              returnValue = this.create(change.entity as unknown as TCreate, pathParams);
              break;
            case "update":
              returnValue = this.update(change.entity, pathParams);
              break;
            case "patch":
              returnValue = this.patch(change, pathParams);
              break;
            case "delete":
              returnValue = this.delete(change as unknown as T, pathParams);
              break;
            default:
              throw new Error(`[SoftlineEntityService] - save: ${(change as any)?.action} is not an valid action`)
          }
          return returnValue.pipe(
            map(entity => ({responseType: 'success', action: change.action, entity})),
            catchError((e: RequestError) => of({responseType: 'error', change, messages: [e.message]}))
          )
        }),
        toArray(),
        map(responses => {
          const returnValue: LazySaveResult<T> = {failed: [], succeeded: []};
          for(const response of responses){
            if(response.responseType === 'success')
              returnValue.succeeded.push(response as any);
            else
              returnValue.failed.push(response as any);
          }
          return returnValue;
        })
      )
  }
}
