<ng-container *ngVar="activeType$ | async as activeType">
  <div class="flex w-full justify-between flex-wrap mt-4">
    <div class="flex flex-col">
      <h1 class="text-2xl font-bold">
        {{ '#GESAMTABFRAGE.PAGES.LIST.HEADER' | translate }}
      </h1>
      <span class="text-sm color-light"
        >{{ belegLength$ | async }}
        {{ '#GESAMTABFRAGE.PAGES.LIST.RESULTS' | translate }}</span
      >
    </div>
    <soft-filter-and-sort class="w-full sm:w-[30vw]"
                          [values]="(all$ | async) ?? []"
                          [filter]="filter$ | async"
                          [filterConfig]="definition$ | async | definitionFilter"
                          [sort]="sort$ | async"
                          [sortConfig]="definition$ | async | definitionSort"
                          (filterChange)="onFilterChange($event)"
                          (sortChange)="onSortChange($event)"></soft-filter-and-sort>
  </div>
  <div class="flex w-full flex-row mt-4 items-center">
    <span class="text-sm mr-3 mb-2 font-bold">{{
      '#GESAMTABFRAGE.PAGES.LIST.FILTER' | translate
    }}</span>

    <soft-dynamic-filter
      [definition]="$any(queryDefinition$ | async)"
      [value]="$any(query$ | async)"
      (valueChange)="onQueryChange($event, activeType)"
    >
    </soft-dynamic-filter>
  </div>
  <hr class="my-4" />

  <div class="w-full overflow-x-auto">
    <soft-dynamic-table
      selectionMode="multi"
      tableClass="soft-table no-border"
      class="body-border divided"
      [definition]="$any(definition$ | async)"
      [rows]="(data$ | async) ?? []"
      [loading]="(loading$ | async) ?? false"
      (selectedItemsChange)="onSelectedItemsChange($event)"
      [selectedItems]="(selected$ | async) ?? []"
      [footer]="(footer$ | async) ?? []"
    ></soft-dynamic-table>
  </div>

  <div
    *ngIf="!(loading$ | async) && (loaded$ | async) && (total$ | async) === 0"
    class="soft-container center mt-5"
  >
    {{ '#GESAMTABFRAGE.PAGES.LIST.NO_DATA' | translate }}
  </div>
</ng-container>
