<div class="max-h-[90vh] overflow-y-auto">
  <ng-container *ngIf="filterConfig">
    <h1 class="text-xl font-semibold">{{'#APPLICATION.FILTER_AND_SORT.FILTER.TITLE' | translate}}</h1>
    <soft-filter-list [filters]="filters"
                      [values]="values"
                      (filtersChange)="filters = $event"
                      [config]="filterConfig"></soft-filter-list>
  </ng-container>
  <div *ngIf="filterConfig && sortConfig" class="px-4 sm:px-0 w-full">
    <hr class="soft-separator" />
  </div>
  <ng-container *ngIf="sortConfig">
    <h1 class="text-xl font-semibold">{{'#APPLICATION.FILTER_AND_SORT.SORT.TITLE' | translate}}</h1>
    <soft-sort-form [sort]="sort"
                    [config]="sortConfig"
                    (sortChange)="sort = $event"></soft-sort-form>
  </ng-container>
  <div class="w-full mt-4 p-2 sm:p-0">
    <button class="soft-button primary h-16 w-full rounded"
            (click)="onSubmit()">
      {{'#APPLICATION.FILTER_AND_SORT.SUBMIT' | translate}}
    </button>
  </div>
</div>
