import {
  createAction,
  createGetter,
  createMutation,
  IndexedDbService,
  isDefined,
  mutate,
  on,
  saveAs,
  select,
  StoreFeature,
} from '@softline/core';
import { fabric } from 'fabric';
import { Canvas, ITextboxOptions } from 'fabric/fabric-impl';
import { PDFArray, PDFDocument, PDFName, PDFString } from 'pdf-lib';
import {
  PdfEditorTool,
  PdfEditorToolGroup,
  PdfEditorToolGroupName,
  PdfEditorToolType,
} from '../data/pdf-editor-tool';
import { v4 as uuid } from 'uuid';
import {
  SOFTLINE_CONST_CUSTOM_TOOLS_OBJECT_STORE,
  SOFTLINE_DB_PDF_EDITOR,
  SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
} from '../pdf-editor.shared';

export interface State {
  loading: boolean;
  saving: boolean;
  downloading: boolean;
  document?: Blob;
  originalData: any[];
  canvases: Canvas[];
  activeCanvas?: Canvas;
  toolGroups: PdfEditorToolGroup[];
  selectedTool?: PdfEditorTool;
  hasSelectedObjects: boolean;
}

export const initialState: State = {
  loading: false,
  saving: false,
  downloading: false,
  document: undefined,
  originalData: [],
  canvases: [],
  activeCanvas: undefined,
  toolGroups: [
    {
      name: PdfEditorToolGroupName.Drawing,
      title: 'Zeichnen',
      allowCustomTool: false,
      tools: [
        {
          id: uuid(),
          type: PdfEditorToolType.Pen,
          title: 'Stift',
          icon: 'fas fa-pen',
          options: {
            width: 5,
            color: '#000000',
          },
          customizations: {
            width: { min: 1, max: 16 },
            color: {},
          },
        },
        {
          id: uuid(),
          type: PdfEditorToolType.Marker,
          title: 'Marker',
          icon: 'fas fa-i-cursor',
          options: {
            width: 16,
            color: 'rgba(255, 0, 0, 0.3)',
          },
          customizations: {
            color: {},
          },
        },
        {
          id: uuid(),
          type: PdfEditorToolType.TextBox,
          title: 'Text Box',
          icon: 'fas fa-font',
          options: {
            text: 'TEXT',
            width: 200,
            left: 50,
            top: 50,
            padding: 0,
            textAlign: 'left',
            fontSize: 16,
            backgroundColor: '#ffffff',
            fill: '#000000',
          },
          customizations: {
            fontSize: { min: 16, max: 32 },
            backgroundColor: {},
            fill: {},
          },
        },
        {
          id: uuid(),
          type: PdfEditorToolType.Note,
          title: 'Notiz',
          icon: 'fas fa-comment-alt',
          options: {
            text: '\uf27a Note',
            width: 200,
            left: 50,
            top: 50,
            padding: 5,
            fontSize: 16,
            fontWeight: 700,
            fontFamily: 'Font Awesome 5 Free',
            backgroundColor: '#ffffff',
            fill: '#b29000',
          },
          customizations: false,
        },
      ],
    },
   /*{
      name: PdfEditorToolGroupName.Stamp,
      title: 'Stempel',
      allowCustomTool: false,
      tools: [
        {
          id: uuid(),
          type: PdfEditorToolType.Image,
          url: '/assets/stamps/stamp-1.png',
        },
        {
          id: uuid(),
          type: PdfEditorToolType.Image,
          url: '/assets/stamps/stamp-2.png',
        },
        {
          id: uuid(),
          type: PdfEditorToolType.Image,
          url: '/assets/stamps/stamp-3.png',
        },
        {
          id: uuid(),
          type: PdfEditorToolType.Image,
          url: '/assets/stamps/stamp-4.png',
        },
      ],
    },*/
    {
      name: PdfEditorToolGroupName.Signature,
      title: 'Unterschriften',
      allowCustomTool: true,
      tools: [
        /*{
          id: uuid(),
          type: PdfEditorToolType.Image,
          url: '/assets/signatures/signature-1.png',
        },
        {
          id: uuid(),
          type: PdfEditorToolType.Image,
          url: '/assets/signatures/signature-2.png',
        },*/
      ],
    },
  ],
  selectedTool: undefined,
  hasSelectedObjects: false,
};

export interface LoadDocumentParams {
  url: string;
  container: HTMLElement;
}

export interface SaveDocumentParams {
  format?: string;
}

export interface DownloadDocumentParams {
  filename?: string;
}

export interface ManageToolParams {
  groupName: PdfEditorToolGroupName;
  tool: PdfEditorTool;
}

export interface DynamicParams {
  [key: string]: any;
}

export interface UpdateToolOptionsParams {
  tool?: PdfEditorTool;
  options: DynamicParams;
}

export interface RenderTextBoxParams extends ITextboxOptions {
  text?: string;
}

export interface RenderImageParams {
  canvas?: Canvas;
  left: number;
  top: number;
  width?: number;
  height?: number;
}

// tslint:disable-next-line:no-namespace
export namespace PdfEditorComponentStore {
  export const mutations = {
    reset: createMutation<State>('pdf editor reset to initial state'),
    loading: createMutation<State, boolean>('pdf editor loading'),
    saving: createMutation<State, boolean>('pdf editor saving'),
    downloading: createMutation<State, boolean>('pdf editor downloading'),
    setDocument: createMutation<State, Blob>('pdf editor set document'),
    addOriginalData: createMutation<State, any>(
      'pdf editor add original types'
    ),
    addCanvas: createMutation<State, Canvas>('pdf editor add canvas'),
    setActiveCanvas: createMutation<State, Canvas>(
      'pdf editor set active canvas'
    ),
    clearCanvases: createMutation<State>('pdf editor clear canvases'),
    addTool: createMutation<State, ManageToolParams>('pdf editor add tool'),
    deleteTool: createMutation<State, ManageToolParams>(
      'pdf editor delete tool'
    ),
    selectTool: createMutation<State, PdfEditorTool | undefined>(
      'pdf editor select tool'
    ),
    updateToolOptions: createMutation<State, UpdateToolOptionsParams>(
      'pdf editor update tool options'
    ),
    enableFreeDrawing: createMutation<State, boolean | DynamicParams>(
      'pdf editor enable free drawing'
    ),
    renderTextBox: createMutation<State, RenderTextBoxParams>(
      'pdf editor render text box'
    ),
    renderNote: createMutation<State, RenderTextBoxParams>(
      'pdf editor render note'
    ),
    renderImage: createMutation<State, RenderImageParams>(
      'pdf editor render image'
    ),
    setHasSelectedObjects: createMutation<State, boolean>(
      'pdf editor set has selected objects'
    ),
    discardSelectedObjects: createMutation<State>(
      'pdf editor discard selected objects'
    ),
    removeSelectedObjects: createMutation<State>(
      'pdf editor remove selected objects'
    ),
  };

  export const actions = {
    prepareDocument: createAction<State, undefined, Promise<PDFDocument>>(
      'pdf editor prepare pdf document'
    ),
    saveDocument: createAction<
      State,
      SaveDocumentParams,
      string | Blob | Uint8Array | undefined
    >('pdf editor save document'),
    downloadDocument: createAction<State, DownloadDocumentParams, void>(
      'pdf editor download document'
    ),
    loadCustomTools: createAction<State, undefined, void>(
      'pdf editor load custom tool'
    ),
    saveCustomTool: createAction<State, ManageToolParams, void>(
      'pdf editor save custom tool'
    ),
    deleteCustomTool: createAction<State, ManageToolParams, void>(
      'pdf editor delete custom tool'
    ),
  };

  export const getters = {
    loading: createGetter<State, boolean>('pdf editor loading'),
    saving: createGetter<State, boolean>('pdf editor saving'),
    downloading: createGetter<State, boolean>('pdf editor downloading'),
    canvases: createGetter<State, Canvas[]>('pdf editor canvases'),
    activeCanvas: createGetter<State, Canvas | undefined>(
      'pdf editor active canvas'
    ),
    toolGroups: createGetter<State, PdfEditorToolGroup[]>(
      'pdf editor tool groups'
    ),
    selectedTool: createGetter<State, PdfEditorTool | undefined>(
      'pdf editor selected tool'
    ),
    selectedToolGroup: createGetter<State, PdfEditorToolGroup | undefined>(
      'pdf editor selected tool group'
    ),
    hasSelectedObjects: createGetter<State, boolean>(
      'pdf editor has selected objects'
    ),
  };

  export const feature: StoreFeature<State> = {
    initialState,
    mutations: [
      mutate(mutations.reset, ({ state, params }) => {
        // TODO: find a better way to deep clone the initialState
        return JSON.parse(JSON.stringify(initialState));
      }),
      mutate(mutations.loading, ({ state, params }) => ({
        ...state,
        loading: params,
      })),
      mutate(mutations.saving, ({ state, params }) => ({
        ...state,
        saving: params,
      })),
      mutate(mutations.downloading, ({ state, params }) => ({
        ...state,
        downloading: params,
      })),
      mutate(mutations.setDocument, ({ state, params }) => ({
        ...state,
        document: params,
      })),
      mutate(mutations.addOriginalData, ({ state, params }) => ({
        ...state,
        originalData: [...state.originalData, params],
      })),
      mutate(mutations.addCanvas, ({ state, params }) => ({
        ...state,
        canvases: [...state.canvases, params],
      })),
      mutate(mutations.setActiveCanvas, ({ state, params }) => ({
        ...state,
        activeCanvas: params,
      })),
      mutate(mutations.clearCanvases, ({ state, params }) => {
        let i = 0;

        for (const canvas of state.canvases) {
          canvas.clear();
          canvas.loadFromJSON(state.originalData[i++], () =>
            canvas.renderAll()
          );
        }

        return { ...state, hasSelectedObjects: false, selectedTool: undefined };
      }),
      mutate(mutations.addTool, ({ state, params }) => {
        const toolGroup = state.toolGroups.find(
          (tg) => tg.name === params.groupName
        );

        if (toolGroup) {
          toolGroup.tools.push(params.tool);
        }
        return state;
      }),
      mutate(mutations.deleteTool, ({ state, params }) => {
        const toolGroup = state.toolGroups.find(
          (tg) => tg.name === params.groupName
        );

        if (toolGroup) {
          toolGroup.tools.splice(toolGroup.tools.indexOf(params.tool), 1);
        }
        return state;
      }),
      mutate(mutations.selectTool, ({ state, params }) => ({
        ...state,
        selectedTool: params,
      })),
      mutate(mutations.updateToolOptions, ({ state, params }) => {
        const tool = params.tool ?? state.selectedTool;
        if (!tool) {
          throw new Error('No tool selected.');
        }

        tool.options = { ...tool.options, ...params.options };

        // Update all canvases
        state.canvases.forEach((canvas) => {
          // Free drawing
          if (tool.options.width) {
            canvas.freeDrawingBrush.width = tool.options.width;
          }
          if (tool.options.color) {
            canvas.freeDrawingBrush.color = tool.options.color;
          }

          // Selected objects
          canvas
            .getActiveObjects()
            .forEach((object: fabric.Object & DynamicParams) => {
              if (object['toolType'] === tool.type) {
                object.set(params.options);
              }
            });

          canvas.renderAll();
        });

        return state;
      }),
      mutate(mutations.enableFreeDrawing, ({ state, params }) => {
        if (params === false) {
          state.canvases.forEach((canvas) => (canvas.isDrawingMode = false));
        } else {
          let options = state.selectedTool?.options ?? {};
          if (params instanceof Object) {
            options = { ...options, ...params };
          }

          state.canvases.forEach((canvas) => {
            canvas.isDrawingMode = true;
            canvas.freeDrawingBrush.width = options.width;
            canvas.freeDrawingBrush.color = options.color;
          });
        }

        return state;
      }),
      mutate(mutations.renderTextBox, ({ state, params }) => {
        const canvas = state.activeCanvas;

        if (canvas) {
          let options = state.selectedTool?.options ?? {};
          if (params instanceof Object) {
            options = { ...options, ...params };
          }

          const textBox = new fabric.Textbox(options.text, options);

          canvas.add(textBox);
          canvas.setActiveObject(textBox);
          canvas.renderAll();
          state = { ...state, hasSelectedObjects: true };
        }

        return state;
      }),
      mutate(mutations.renderNote, ({ state, params }) => {
        const canvas = state.activeCanvas;

        if (canvas) {
          let options = state.selectedTool?.options ?? {};
          if (params instanceof Object) {
            options = { ...options, ...params };
          }

          const textBox = new fabric.Textbox(options.text, options);
          textBox.setControlsVisibility({
            mtr: false,
            mt: false,
            mb: false,
            tl: false,
            bl: false,
            tr: false,
            br: false,
          });

          canvas.add(textBox);
          canvas.setActiveObject(textBox);
          canvas.renderAll();
          state = { ...state, hasSelectedObjects: true };
        }

        return state;
      }),
      mutate(mutations.renderImage, ({ state, params }) => {
        const canvas = state.activeCanvas;
        const selectedTool = state.selectedTool;

        if (canvas && selectedTool && selectedTool.url) {
          fabric.Image.fromURL(selectedTool.url, (image) => {
            const width = params.width ?? 300;
            const height = params.height ?? 300;

            image.scaleToHeight(width);
            image.scaleToWidth(height);
            image.set({
              left: params.left - width / 2,
              top: params.top - height / 2,
            });
            canvas.add(image);
            canvas.setActiveObject(image);
            canvas.renderAll();
            state = { ...state, hasSelectedObjects: true };
          });
        }

        return state;
      }),
      mutate(mutations.setHasSelectedObjects, ({ state, params }) => ({
        ...state,
        hasSelectedObjects: params,
      })),
      mutate(mutations.discardSelectedObjects, ({ state, params }) => {
        state.canvases.forEach((canvas) => {
          canvas.discardActiveObject().renderAll();
        });

        return { ...state, hasSelectedObjects: false };
      }),
      mutate(mutations.removeSelectedObjects, ({ state, params }) => {
        state.canvases.forEach((canvas) => {
          canvas.getActiveObjects().forEach((obj) => canvas.remove(obj));
          canvas.discardActiveObject().renderAll();
        });

        return { ...state, hasSelectedObjects: false, selectedTool: undefined };
      }),
    ],
    actions: [
      on(
        actions.prepareDocument,
        async ({ featureName, commit, get, params, state }) => {
          if (!state.document)
            throw Error('[PdfEditorStore] actions.prepareDocument:  no document to prepare');

          const canvases = state.canvases;
          const pdfBytes = await blobToArrayBuffer(state.document);
          const pdfDocument = await PDFDocument.load(pdfBytes);

          const annotations = [] as any;

          let i = 0;
          for (const canvas of canvases) {
            const page = pdfDocument.getPage(i);

            for (const obj of canvas.getObjects()) {
              switch (obj['toolType']) {
                case PdfEditorToolType.Note:
                  const left = obj.left;
                  const bottom = page.getHeight() - (obj.top ?? 0);
                  const textAnnotation = pdfDocument.context.obj({
                    Type: PDFName.of('Annot'),
                    Subtype: PDFName.of('Text'),
                    Name: PDFString.of('Note'),
                    Rect: [left, bottom, left, bottom],
                    Contents: PDFString.of(
                      (obj as any).text.replace('\uf27a', '').trim()
                    ),
                  });
                  const annots = page.node.lookup(PDFName.of('Annots'), PDFArray);
                  annots.push(textAnnotation);

                  //Remove for rendering
                  annotations.push({
                    pageIndex: i,
                    canvasObject: obj,
                  });
                  canvas.remove(obj);
                  canvas.renderAll();
                  break;
                default:
                  break;
              }
            }

            // Draw the cansas on the pdf page
            // Draw the updated PDF page
            const canvasData = canvas.toDataURL({
              format: 'png'
            });
            const image = await pdfDocument.embedPng(canvasData);
            page.drawImage(image, {
              x: 0,
              y: 0,
              width: image.width,
              height: image.height,
            });
            i++;
          }

          //Add back Notes
          for (const annotation of annotations) {
            const canvas = canvases[annotation.pageIndex];
            canvas.add(annotation.canvasObject);
            canvas.renderAll();
          }
          return pdfDocument as any;
        }
      ),
      on(
        actions.saveDocument,
        async ({ featureName, injector, get, commit, dispatch, params }) => {
          commit(featureName, mutations.saving, true);

          const url = 'get(featureName, getters.url)';
          const pdfDocument = await dispatch(
            featureName,
            actions.prepareDocument
          );
          let result: string | Uint8Array = '';

          if (!isDefined(params)) {
            params = { format: 'base64' };
          }
          if (params.format === 'base64') {
            result = await pdfDocument.saveAsBase64();
          } else if (params.format === 'arrayBuffer') {
            result = await pdfDocument.save();
          }

          const indexedDbService = injector.get(IndexedDbService);
          const indexedDbResourceLocation = {
            databaseName: SOFTLINE_DB_PDF_EDITOR,
            objectStoreName: 'documents',
            key: url,
          };
          const existingResource = indexedDbService.get(
            indexedDbResourceLocation
          );

          if (isDefined(existingResource)) {
            indexedDbService.update(indexedDbResourceLocation, {
              url,
              value: result,
            });
          } else {
            indexedDbService.create(indexedDbResourceLocation, {
              url,
              value: result,
            });
          }

          commit(featureName, mutations.saving, false);

          return result;
        }
      ),
      on(
        actions.downloadDocument,
        async ({ featureName, get, commit, dispatch, params }) => {
          commit(featureName, mutations.downloading, true);

          const pdfDocument = await dispatch(
            featureName,
            actions.prepareDocument
          );
          const result = await pdfDocument.save();
          const blob = new Blob([result], { type: 'application/pdf' });
          let filename =
            params?.filename ?? pdfDocument.getTitle() ?? 'updated.pdf'; // TODO change the default name
          if (!filename.endsWith('.pdf')) filename = filename + '.pdf';

          saveAs(blob, filename);

          commit(featureName, mutations.downloading, false);
        }
      ),

      on(
        actions.loadCustomTools,
        async ({ featureName, commit, injector, params }) => {
          const indexedDbService = injector.get(IndexedDbService);
          const customTools = (await indexedDbService.getAsync(
            SOFTLINE_DB_PDF_EDITOR,
            SOFTLINE_CONST_CUSTOM_TOOLS_OBJECT_STORE,
            undefined
          )) as any;
          for (const { groupName, value } of customTools) {
            for (const tool of value) {
              commit(featureName, mutations.addTool, { groupName, tool });
            }
          }
        }
      ),
      on(
        actions.saveCustomTool,
        async ({ featureName, commit, injector, params }) => {
          const indexedDbService = injector.get(IndexedDbService);
          const indexedDbResourceLocation = {
            databaseName: SOFTLINE_DB_PDF_EDITOR,
            objectStoreName: SOFTLINE_CONST_CUSTOM_TOOLS_OBJECT_STORE,
            key: params.groupName,
          };
          const existingResource = (await indexedDbService.getAsync(
            indexedDbResourceLocation.databaseName,
            indexedDbResourceLocation.objectStoreName,
            indexedDbResourceLocation.key
          )) as any;

          if (isDefined(existingResource)) {
            const value = existingResource.value;
            value.push(params.tool);
            indexedDbService.update(indexedDbResourceLocation, {
              groupName: params.groupName,
              value,
            });
          } else {
            const value = [params.tool];
            indexedDbService.create(indexedDbResourceLocation, {
              groupName: params.groupName,
              value,
            });
          }
        }
      ),
      on(
        actions.deleteCustomTool,
        async ({ featureName, commit, injector, params }) => {
          const indexedDbService = injector.get(IndexedDbService);
          const indexedDbResourceLocation = {
            databaseName: SOFTLINE_DB_PDF_EDITOR,
            objectStoreName: SOFTLINE_CONST_CUSTOM_TOOLS_OBJECT_STORE,
            key: params.groupName,
          };
          const existingResource = (await indexedDbService.getAsync(
            indexedDbResourceLocation.databaseName,
            indexedDbResourceLocation.objectStoreName,
            indexedDbResourceLocation.key
          )) as any;

          if (isDefined(existingResource)) {
            const customTool = existingResource.value.find(
              (ct: PdfEditorTool) => ct.id === params.tool.id
            );
            if (!customTool) {
              throw new Error('The tool was not found.');
            }

            const value = existingResource.value;
            if (value.length === 1) {
              indexedDbService.delete(indexedDbResourceLocation);
            } else {
              value.splice(value.indexOf(customTool), 1);
              indexedDbService.update(indexedDbResourceLocation, {
                groupName: params.groupName,
                value,
              });
            }
          }
        }
      ),
    ],
    getters: [
      select(getters.loading, ({ state }) => state.loading),
      select(getters.saving, ({ state }) => state.saving),
      select(getters.downloading, ({ state }) => state.downloading),
      select(getters.canvases, ({ state }) => state.canvases),
      select(getters.activeCanvas, ({ state }) => state.activeCanvas),
      select(getters.toolGroups, ({ state }) => state.toolGroups),
      select(getters.selectedTool, ({ state }) => state.selectedTool),
      select(getters.selectedToolGroup, ({ state }) => {
        if (state.selectedTool) {
          return state.toolGroups.find((tg) =>
            state.selectedTool && tg.tools.includes(state.selectedTool)
          );
        }
        return undefined;
      }),
      select(
        getters.hasSelectedObjects,
        ({ state }) => state.hasSelectedObjects
      ),
    ],
  };
}

function blobToArrayBuffer(blob: Blob): Promise<string> {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.readAsArrayBuffer(blob);
  });
}

/*
      on(actions.init, async ({featureName, injector, get, commit, params}) => {
        commit(featureName, mutations.reset);

        // Load tools from config
        const pdfEditorConfig = injector.get(SOFTLINE_CONFIG_PDF_EDITOR);
        const pdfEditorToolsConfig = pdfEditorConfig.filter(config => config.key === 'tools');
        if (pdfEditorToolsConfig) {
          pdfEditorToolsConfig.forEach(config => {
            for (const [groupName, tools] of Object.entries(config.value)) {
              if (Array.isArray(tools)) {
                tools.forEach(tool => {
                  commit(featureName, mutations.addTool, {groupName, tool});
                });
              }
            }
          });
        }

        // Load custom tools from storage
        const toolGroups = get(featureName, getters.toolGroups);
        const indexedDbService = injector.get(IndexedDbService);
        for (const toolGroup of toolGroups) {
          const indexedDbResourceLocation = {
            databaseName: SOFTLINE_DB_PDF_EDITOR,
            objectStoreName: SOFTLINE_CONST_CUSTOM_TOOLS_OBJECT_STORE,
            key: toolGroup.name,
          };
          const existingResource = await indexedDbService.getAsync(
            indexedDbResourceLocation.databaseName,
            indexedDbResourceLocation.objectStoreName,
            indexedDbResourceLocation.key
          ) as any;

          if (isDefined(existingResource) && Array.isArray(existingResource.value)) {
            existingResource.value.forEach(tool => {
              commit(featureName, mutations.addTool, {groupName: toolGroup.name, tool});
            });
          }
        }

        // Preload custom font family
        const text = new fabric.Text('', {fontFamily: 'Font Awesome 5 Free'});
      }),



      on(actions.loadDocument, async ({featureName, injector, get, commit, params}) => {
        commit(featureName, mutations.loading, true);
        commit(featureName, mutations.setUrl, params.url);

        // TODO: use the node_modules file for the worker...
        GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.15.349/pdf.worker.min.js';

        const pdf = await getDocument(params.url).promise;
        const renderer = injector.get(RendererFactory2).createRenderer(null, null);

        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const viewport = page.getViewport({
            scale: 1.5,
          });

          const canvas = renderer.createElement('canvas') as HTMLCanvasElement;
          canvas.id = `page-${i}`;
          canvas.width = viewport.width;
          canvas.height = viewport.height;
          renderer.appendChild(params.container, canvas);

          const canvasContext = canvas.getContext('2d') as CanvasRenderingContext2D;
          await page.render({
            canvasContext,
            viewport,
          }).promise;

          const canvasBg = canvas.toDataURL('image/jpeg', 1.0);

          const fCanvas = new fabric.Canvas(canvas, {
            selection: true,
            preserveObjectStacking: true,
          });
          fCanvas.setBackgroundImage(canvasBg, () => {
            fCanvas.renderAll();
            commit(featureName, mutations.addOriginalData, fCanvas.toJSON());
          });

          fCanvas.on('object:added', (e) => {
            // console.log('object:added', e);
            const selectedTool = get(featureName, getters.selectedTool);
            if (e?.target && selectedTool) {
              (e.target as fabric.Object & DynamicParams).toolType = selectedTool.type;
            }
          });
          fCanvas.on('object:modified', (e) => {
            // console.log('object:modified', e);
          });
          fCanvas.on('selection:created', (e: IEvent & { selected: fabric.Object[] }) => {
            // console.log('selection:created', e);
            commit(featureName, mutations.setHasSelectedObjects, true);
            commit(featureName, mutations.selectTool, undefined);

            if (e.selected?.length === 1) {
              const canvasObject = e.selected[0] as fabric.Object & DynamicParams;

              if (canvasObject?.toolType === PdfEditorToolType.TextBox) {
                const toolGroups = get(featureName, getters.toolGroups);
                for (const toolGroup of toolGroups) {
                  const tool = toolGroup.tools.find(t => t.type === canvasObject.toolType);
                  if (tool) {
                    commit(featureName, mutations.selectTool, tool);
                    break;
                  }
                }
              }
            }
          });
          fCanvas.on('selection:updated', (e: IEvent & { selected: fabric.Object[] }) => {
            // console.log('selection:updated', e);
            commit(featureName, mutations.setHasSelectedObjects, true);
            commit(featureName, mutations.selectTool, undefined);

            if (e.selected?.length === 1) {
              const canvasObject = e.selected[0] as fabric.Object & DynamicParams;

              if (canvasObject?.toolType === PdfEditorToolType.TextBox) {
                const toolGroups = get(featureName, getters.toolGroups);
                for (const toolGroup of toolGroups) {
                  const tool = toolGroup.tools.find(t => t.type === canvasObject.toolType);
                  if (tool) {
                    commit(featureName, mutations.selectTool, tool);
                    break;
                  }
                }
              }
            }
          });
          fCanvas.on('selection:cleared', (e) => {
            // console.log('selection:cleared', e);
            commit(featureName, mutations.setHasSelectedObjects, false);
            commit(featureName, mutations.selectTool, undefined);
          });
          fCanvas.on('mouse:up', (e) => {
            // console.log('mouse:up', e);
            commit(featureName, mutations.setActiveCanvas, fCanvas);

            const selectedTool = get(featureName, getters.selectedTool);
            const hasSelectedObjects = get(featureName, getters.hasSelectedObjects);

            if (!hasSelectedObjects) {
              if (selectedTool?.type === PdfEditorToolType.TextBox) {
                commit(featureName, mutations.renderTextBox, {
                  left: e.pointer?.x || 50,
                  top: e.pointer?.y || 50,
                });
              } else if (selectedTool?.type === PdfEditorToolType.Note) {
                commit(featureName, mutations.renderNote, {
                  left: e.pointer?.x || 50,
                  top: e.pointer?.y || 50,
                });
              } else if (selectedTool?.type === PdfEditorToolType.Image) {
                commit(featureName, mutations.renderImage, {
                  left: e.pointer?.x || 50,
                  top: e.pointer?.y || 50,
                });
              }
            }
          });

          commit(featureName, mutations.addCanvas, fCanvas);
        }

        commit(featureName, mutations.loading, false);
      }),
 */
