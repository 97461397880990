import { ENVIRONMENT_INITIALIZER, inject, Provider } from '@angular/core';
import { Store } from '@softline/core';
import { CommandStore, SOFTLINE_FEATURE_COMMANDS } from '@softline/application';

export const settingsProviders: Provider[] = [
  {
    provide: ENVIRONMENT_INITIALIZER,
    multi: true,
    useValue: () => {
      const store = inject(Store);
      store.commit(SOFTLINE_FEATURE_COMMANDS, CommandStore.mutations.addSet, {
        name: 'settings',
        commands: [
          {
            name: '#UTILITIES.SETTINGS.TITLE',
            routerLink: ['settings'],
            icon: 'fas fa-cog',
            class: 'menu main-menu main-menu-bottom',
          },
        ],
      });
    },
  },
];
