import { Injectable } from "@angular/core";
import { DateService, Store } from "@softline/core";
import { ModalStore, SOFTLINE_FEATURE_MODAL } from "@softline/ui-core";
import { LabelType } from "../../../data/label-type";
import { ScannerService } from "../../../services/scanner.service";
import { Scan } from "../../../data/scan";
import { ScanDialog } from "../../../dialogs/scan.dialog";
import { stringToScan } from "../../../functions/string-to-scan.function";
import {
  ScannerStore,
  SettingsStore,
  SOFTLINE_FEATURE_SCANNER,
  SOFTLINE_FEATURE_SETTINGS
} from "@softline/application";
import { BehaviorSubject, combineLatestWith, skip, Subscription } from "rxjs";
import { FormControl, FormGroup } from "@angular/forms";
import { IntegratedScannerSettings } from "../types/integrated-scanner-settings";
import { SOFTLINE_SETTINGS_INTEGRATED_SCANNER } from "../integrated-scanner.shared";

const MODAL_ID = 'IntegratedScannerModal';

@Injectable()
export class IntegratedScannerService extends ScannerService {

  private active$ = new BehaviorSubject(false);
  private registered = false;

  private scanValue: string = '';
  private scanStarted: boolean = false;
  private listener = async (event: KeyboardEvent) => {
    if(event.key ==='Control' || event.key ==='Shift')
      return;
    let scanCompleted = false
    if(event.key === ']')
      this.scanStarted = true;
    if (this.scanStarted && event.key !== 'Enter') {
      if(event.key !== 'Alt' && event.key !== 'AltRight')
        this.scanValue += event.key
    }
    else if (this.scanStarted && this.scanValue) {
      scanCompleted = true;
      const scan = stringToScan(this.scanValue, this.dateService.now());
      const modal = this.store.get(SOFTLINE_FEATURE_MODAL, ModalStore.getters.items)
        .find(o => o.id === MODAL_ID);
      if(modal)
        await this.store.dispatch(SOFTLINE_FEATURE_MODAL, ModalStore.actions.resolve<Scan>(),
          {id: MODAL_ID, result: scan})
      else
        this.store.commit(SOFTLINE_FEATURE_SCANNER, ScannerStore.mutations.add, scan);
      this.scanValue = '';
      this.scanStarted = false;
    } else
      this.scanStarted = false;

    if(this.scanStarted || scanCompleted) {
      event.preventDefault();
      event.stopPropagation();
    }
  };


  constructor(private store: Store, private dateService: DateService) {
    super();
  }

  override async init(): Promise<void> {
    this.isAvailable = true;
    this.store
      .observe(
        SOFTLINE_FEATURE_SETTINGS,
        SettingsStore.getters.values<IntegratedScannerSettings>(),
        SOFTLINE_SETTINGS_INTEGRATED_SCANNER
      )
      .pipe(
        combineLatestWith(
          this.active$,
          this.store.observe(SOFTLINE_FEATURE_SCANNER, ScannerStore.getters.scanning)
        ),
      )
      .subscribe(([settings, active, scanning]) => {
        if((settings?.scanEverywhere || scanning) && active && !this.registered) {
          document.addEventListener('keydown', this.listener, true);
          this.registered = true;
        }
        else if(this.registered && ((!settings?.scanEverywhere && !scanning) || !active)) {
          document.removeEventListener('keydown', this.listener, true);
          this.registered = false;
        }
      });
  }

  override async activate(): Promise<void> {
    this.active$.next(true);
  }

  override async deactivate(): Promise<void> {
    this.active$.next(false);
  }

  async scan(labelType?: LabelType | LabelType[]): Promise<Scan> {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open<Scan, any>(),
      {
        id: MODAL_ID,
        component: ScanDialog,
        priority: 50,
        dismiss: true,
      }
    );
    if (result === 'DISMISSED')
      throw new Error('InternalScannerService: canceled scan');

    return result;
  }

  async cancel(): Promise<void> {
    await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.close,
      MODAL_ID
    );
  }
}
