<ng-container *ngIf="definition" [ngSwitch]="definition.control">
  <soft-tab-group
    *ngSwitchCase="'tab-group'"
    [ngClass]="definition.class | classRule: form.value"
  >
    <ng-container *ngFor="let tab of definition.definitions">
      <soft-tab
        *ngIf="tab.visible | booleanRule: form.value:true"
        [ngClass]="tab.class | classRule: form.value"
        [title]="tab.title"
      >
        <ng-container
          *ngTemplateOutlet="
            valueTemplate;
            context: { $implicit: $any(tab).definitions }
          "
        ></ng-container>
      </soft-tab>
    </ng-container>
  </soft-tab-group>
  <div
    *ngSwitchCase="'card'"
    [ngClass]="definition.class | classRule: form.value"
    class="soft-card"
  >
    <div *ngIf="definition.title" class="soft-card-title">
      {{ definition.title }}
    </div>
    <ng-container
      *ngTemplateOutlet="
        valueTemplate;
        context: { $implicit: definition.definitions }
      "
    ></ng-container>
  </div>
  <section
    *ngSwitchCase="'section'"
    class="soft-section"
    [ngClass]="definition.class | classRule: form.value"
  >
    <div *ngIf="definition.title">{{ definition.title }}</div>
    <ng-container
      *ngTemplateOutlet="
        valueTemplate;
        context: { $implicit: definition.definitions }
      "
    ></ng-container>
  </section>
</ng-container>

<ng-template let-children #valueTemplate>
  <ng-container *ngFor="let child of children">
    <div
      [ngClass]="child.class | classRule: form.value"
      class="soft-dynamic-form-field"
    >
      <ng-container
        *ngIf="child.visible | booleanRule: form.value:true"
        softDynamicFormField
        [definition]="child"
        [form]="form"
      ></ng-container>
    </div>
  </ng-container>
</ng-template>
