<div *ngIf="components && components.length === 0">
  {{ '#UTILITIES.CONTEXT.NO_COMPONENTS' | translate }}
</div>
<div *ngFor="let component of components ?? []" class="mt-4">
  <ng-container *ngComponentOutlet="component"></ng-container>
  <hr class="soft-separator" />
</div>
<div class="c row end">
  <button
    class="soft-button primary"
    (click)="onSave()"
    [disabled]="loading$ | async"
  >
    <i *ngIf="loading$ | async" class="fas fa-spinner fa-spin"></i>
    {{ '#UTILITIES.CONTEXT.SAVE' | translate }}
  </button>
</div>
