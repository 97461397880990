import {animate, keyframes, style, transition, trigger,} from '@angular/animations';
import {ChangeDetectionStrategy, Component, OnDestroy, OnInit,} from '@angular/core';
import {Store} from '@softline/core';
import {
  BreakpointStore,
  MessageBarStore,
  SOFTLINE_FEATURE_BREAKPOINTS,
  SOFTLINE_FEATURE_MESSAGE_BAR,
  UiCoreModule,
} from '@softline/ui-core';
import {delay, Subscription} from 'rxjs';
import * as ShellStore from '../shell.store';
import {SOFTLINE_FEATURE_COMMANDS, SOFTLINE_FEATURE_SHELL,} from '../../application.shared';
import * as CommandStore from '../../program/command.store';
import {RefreshService} from '../../program/services/refresh.service';
import {SideActionsComponent} from './side-actions/side-actions.component';
import {SideMenuComponent} from './side-menu/side-menu.component';
import {TitleBarComponent} from './title-bar/title-bar.component';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {BottomBarComponent} from "./bottom-bar/bottom-bar.component";

@Component({
  standalone: true,
  selector: 'soft-shell',
  animations: [
    trigger('slideInOut_Main', [
      transition(':enter', [
        animate(
          '555ms ease-out',
          keyframes([
            style({ transform: 'translateX(-100%)', offset: 0 }),
            style({ transform: 'translateX(0%)', offset: 0.6 }),
            style({ transform: 'translateX(0%)', offset: 0.97 }),
          ])
        ),
      ]),
      transition(':leave', [
        animate(
          '444ms ease-in',
          keyframes([
            style({ transform: 'translateX(0%)', offset: 0 }),
            style({ transform: 'translateX(-100%)', offset: 0.97 }),
          ])
        ),
      ]),
    ]),
    trigger('slideInOut_Actions', [
      transition(':enter', [
        animate(
          '555ms ease-out',
          keyframes([
            style({ transform: 'translateX(100%)', offset: 0 }),
            style({ transform: 'translateX(0%)', offset: 0.6 }),
            style({ transform: 'translateX(0%)', offset: 0.97 }),
          ])
        ),
      ]),
      transition(':leave', [
        animate(
          '444ms ease-in',
          keyframes([
            style({ transform: 'translateX(0%)', offset: 0 }),
            style({ transform: 'translateX(100%)', offset: 0.97 }),
          ])
        ),
      ]),
    ]),
  ],
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    UiCoreModule,
    RouterModule,
    SideActionsComponent,
    SideMenuComponent,
    TitleBarComponent,
    BottomBarComponent
  ]
})
export class ShellComponent implements OnInit, OnDestroy {
  private sizeSubscription?: Subscription;

  isTabletScreen = false;
  isDesktopScreen = false;

  hasSideActions$ = this.store.observe(
    SOFTLINE_FEATURE_COMMANDS,
    CommandStore.getters.hasCommands,
    'action-menu'
  );
  isSideMenuOpen$ = this.store.observe(
    SOFTLINE_FEATURE_SHELL,
    ShellStore.getters.sideMenuOpen
  );
  isSideMenuMinified$ = this.store.observe(
    SOFTLINE_FEATURE_SHELL,
    ShellStore.getters.sideMenuMinified
  );
  isActionMenuOpen$ = this.store.observe(
    SOFTLINE_FEATURE_SHELL,
    ShellStore.getters.actionMenuOpen
  );
  isActionMenuMinified$ = this.store.observe(
    SOFTLINE_FEATURE_SHELL,
    ShellStore.getters.actionMenuMinified
  );
  messages$ = this.store.observe(
    SOFTLINE_FEATURE_MESSAGE_BAR,
    MessageBarStore.getters.container
  );

  constructor(private store: Store, public refreshService: RefreshService) {}

  ngOnInit(): void {
    // Aufgrund der Subscription im store, welche augenblicklich wieder den State ändert
    // muss hier ein kleines Delay gegeben werden.
    // Sonst passiert folgendes:
    // 1. BreakpointStore gibt einen neuen StoreState auf den Stream
    // 2. Dadurch wird die Subscription hier unter aufgerufen
    // 3. Diese schreibt wieder einen neuen State auf den Stream
    // 4. Der Stream vom BreakpointStore wird mit dem alten Wert abgeschlossen (vmtl. noch durch den alten Kontext)
    // 5. Jetzt ist fälschlicherweise der Wert von Punkt 1 am Stream
    this.sizeSubscription = this.store
      .observe(SOFTLINE_FEATURE_BREAKPOINTS, BreakpointStore.getters.breakpoint)
      .pipe(delay(1))
      .subscribe((o) => {
        this.isTabletScreen = o === 'sm' || o === 'md';
        this.isDesktopScreen = o === 'lg' || o === 'xl' || o === '2xl';
        this.onWidthChanged();
      });
  }

  ngOnDestroy(): void {
    if (this.sizeSubscription && !this.sizeSubscription.closed)
      this.sizeSubscription.unsubscribe();
    this.sizeSubscription = undefined;
  }

  onWidthChanged(): void {
    if (this.isDesktopScreen) {
      this.openSideMenu();
      this.openActionMenu();
    } else if (this.isTabletScreen) {
      this.minimizeSideMenu();
      this.minimizeActionMenu();
      this.openSideMenu();
      this.openActionMenu();
    } else {
      this.closeSideMenu();
      this.closeActionMenu();
    }
  }

  openSideMenu(): void {
    this.store.commit(
      SOFTLINE_FEATURE_SHELL,
      ShellStore.mutations.openSideMenu
    );
  }
  closeSideMenu(): void {
    this.store.commit(
      SOFTLINE_FEATURE_SHELL,
      ShellStore.mutations.closeSideMenu
    );
  }
  minimizeSideMenu(): void {
    this.store.commit(
      SOFTLINE_FEATURE_SHELL,
      ShellStore.mutations.minimizeSideMenu
    );
  }
  maximizeSideMenu(): void {
    this.store.commit(
      SOFTLINE_FEATURE_SHELL,
      ShellStore.mutations.maximizeSideMenu
    );
  }
  openActionMenu(): void {
    this.store.commit(
      SOFTLINE_FEATURE_SHELL,
      ShellStore.mutations.openActionMenu
    );
  }
  closeActionMenu(): void {
    this.store.commit(
      SOFTLINE_FEATURE_SHELL,
      ShellStore.mutations.closeActionMenu
    );
  }
  minimizeActionMenu(): void {
    this.store.commit(
      SOFTLINE_FEATURE_SHELL,
      ShellStore.mutations.minimizeActionMenu
    );
  }
  maximizeActionMenu(): void {
    this.store.commit(
      SOFTLINE_FEATURE_SHELL,
      ShellStore.mutations.maximizeActionMenu
    );
  }
}
