import { ENVIRONMENT_INITIALIZER, inject, StaticProvider } from "@angular/core";
import { Store } from "@softline/core";
import { SOFTLINE_FEATURE_TRANSLATION, TranslationStore } from "@softline/ui-core";
import { default as de } from "../i18n/de.json";

export const ssoProviders: StaticProvider[] = [
  {
    provide: ENVIRONMENT_INITIALIZER,
    useValue: () => {
      const store = inject(Store);
      store.commit(
        SOFTLINE_FEATURE_TRANSLATION,
        TranslationStore.mutations.add,
        {
          module: 'sso',
          language: 'de',
          translations: de,
        }
      );
    },
    multi: true,
  },
];
