import {
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  Renderer2,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Store } from '@softline/core';
import * as ScannerStore from '../scanner.store';
import { LabelType } from '../data/label-type';
import { Scan } from '../data/scan';
import { SOFTLINE_FEATURE_SCANNER } from '../../application.shared';

@Directive({
  selector: '[softScan]',
})
export class ScanDirective implements OnInit {
  private child?: HTMLElement;
  private initialized = false;

  private _canScan: boolean = true;
  @Input('softScan')
  get canScan(): boolean {
    return this._canScan;
  }
  set canScan(value: boolean) {
    const hasChanges = value !== this._canScan;
    this._canScan = value;
    if (hasChanges && !this.child && this.initialized) this.addScanChild();
    else if (this.child) this.removeScanChild();
  }
  @Input() labelTypes?: LabelType | LabelType[];

  @Output() scan: EventEmitter<void> = new EventEmitter<void>();

  @Output() scanSuccess: EventEmitter<Scan> = new EventEmitter<Scan>();
  @Output() scanFailure: EventEmitter<void> = new EventEmitter<void>();
  @Output() scanCancel: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private store: Store,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    @Optional()
    @Inject(NG_VALUE_ACCESSOR)
    private accessors: ControlValueAccessor[]
  ) {}

  ngOnInit(): void {
    this.initialized = true;
    if (this.canScan) this.addScanChild();
  }

  private async onScan(): Promise<void> {
    const scan = await this.store.dispatch(
      SOFTLINE_FEATURE_SCANNER,
      ScannerStore.actions.scan,
      { labelType: this.labelTypes }
    );

    if (this.accessors)
      for (const accessor of this.accessors) accessor.writeValue(scan.data);
    this.scanSuccess.emit(scan);
  }

  private addScanChild(): void {
    this.child = document.createElement('i');
    this.child.setAttribute('class', `fas fa-barcode pointer ml-4`);
    this.child.addEventListener('click', this.onScan.bind(this));
    this.renderer.appendChild(this.elementRef.nativeElement, this.child);
  }

  private removeScanChild(): void {
    this.renderer.removeChild(this.elementRef.nativeElement, this.child);
    this.child = undefined;
  }
}
