import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@softline/core";
import {
  SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG,
  SOFTLINE_FEATURE_GESAMTABFRAGE_BELEGART
} from "../../gesamtabfrage.shared";
import { Router, RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { UiCoreModule } from "@softline/ui-core";
import { BackNavigable, BackNavigationService, SOFTLINE_FEATURE_TITLE, TitleStore } from "@softline/application";
import { BelegartStore } from "../../store";
import { BelegStore } from "../../store/beleg.store";

@Component({
  standalone: true,
  // tslint:disable-next-line:component-selector
  selector: 'soft-belegarten',
  templateUrl: './belegarten.component.html',
  styleUrls: ['./belegarten.component.scss'],
  imports: [CommonModule, RouterModule, UiCoreModule],
})
export class BelegartenComponent implements OnInit, OnDestroy, BackNavigable {
  loading$ = this.store.observe(
    SOFTLINE_FEATURE_GESAMTABFRAGE_BELEGART,
    BelegartStore.getters.loading
  );
  belegarten$ = this.store.observe(
    SOFTLINE_FEATURE_GESAMTABFRAGE_BELEGART,
    BelegartStore.getters.all
  );

  constructor(
    private store: Store,
    private router: Router,
    private backNavigationService: BackNavigationService
  ) {}

  ngOnInit(): void {
    this.backNavigationService.set(this);
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      '#GESAMTABFRAGE.TITLE'
    );
    this.store.commit(SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG, BelegStore.mutations.query.setQuery, undefined);
  }

  ngOnDestroy(): void {
    this.backNavigationService.set(undefined);
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['/']);
  }
}
