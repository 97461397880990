<div class="c column mt-3">
  <label class="soft-overline color-light mb-1">
    {{ '#CONNECTION_SETTINGS.SELECTED_CONNECTION' | translate }}
  </label>
  <soft-select
    class="border-primary"
    [value]="selectedConnection$ | async"
    (valueChange)="onSelectionChange($event)"
    #select
  >
    <soft-select-option
      *ngFor="let connection of connections$ | async"
      [value]="connection"
    >
      <ng-container *ngIf="connection.name; else connectionSting">
        <span class="font-semibold">{{ connection.name }}</span>
        <span class="color-light mr-2">
          (<span>{{ connection.host }}</span>
          <span *ngIf="connection.port">:{{ connection.port }}</span>
          <span *ngIf="connection.basePath">{{ connection.basePath }}</span
          >)
        </span>
      </ng-container>
      <ng-template #connectionSting>
        <span>{{ connection.host }}</span>
        <span *ngIf="connection.port">:{{ connection.port }}</span>
        <span *ngIf="connection.basePath">{{ connection.basePath }}</span>
      </ng-template>
    </soft-select-option>
  </soft-select>
</div>
<div
  (click)="onAdd()"
  class="border border-secondary h-8 pointer hover:bg-selected flex mt-4 justify-center items-center connection-actions"
>
  <div>
    <i class="fas fa-plus"></i>
    {{ '#CONNECTION_SETTINGS.ADD' | translate }}
  </div>
</div>
<div class="flex mt-2">
  <div
    class="flex justify-center pointer w-full h-8 border border-secondary mr-1 items-center connection-actions"
    (click)="onEdit()"
  >
    <i class="fas fa-edit mr-2"></i>
    {{ '#CONNECTION_SETTINGS.EDIT' | translate }}
  </div>
  <div
    class="flex justify-center pointer w-full h-8 text-danger border border-secondary ml-1 items-center connection-actions"
    (click)="onDelete()"
  >
    <i class="fas fa-trash mr-2"></i>
    {{ '#CONNECTION_SETTINGS.DELETE' | translate }}
  </div>
</div>
<div class="mt-2">
  <hr class="soft-separator" />
</div>
<div
  (click)="onScan()"
  class="border border-primary p-2 pointer mt-3 hover:bg-selected flex bg-primary-50 text-primary justify-center connection-actions"
>
  <div>
    <i class="fas fa-barcode"></i>
    {{ '#CONNECTION_SETTINGS.SCAN' | translate }}
  </div>
</div>

<!--
<div class="c row space-between mt-3">
  <button class="soft-button secondary" (click)="onTest(form.value)">
    {{'#CONNECTION_SETTINGS.TEST' | translate}}
  </button>
  <span>
    <button class="soft-button link" (click)="onDelete(select.value)">
      {{'#CONNECTION_SETTINGS.DELETE' | translate}}
    </button>
    <button class="soft-button primary ml-4"
            [disabled]="!(this.form | isValid)"
            (click)="onSave(select.value, form.value)">
      {{'#CONNECTION_SETTINGS.SAVE' | translate}}
    </button>
  </span>
</div>
-->
