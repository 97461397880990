import {
  ENVIRONMENT_INITIALIZER,
  ImportedNgModuleProviders,
  importProvidersFrom,
  inject,
  Provider,
} from '@angular/core';
import {
  ConnectionHttpService,
  SOFTLINE_SERVICE_HTTP,
  SOFTLINE_STORE_DETAIL_SERVICE_FACTORY,
  SOFTLINE_STORE_ENTITY_SERVICE,
  SoftlineEntityDetailService,
  SoftlineEntityService,
  Store,
  StoreModule,
} from '@softline/core';
import {
  SOFTLINE_FEATURE_TRANSLATION,
  TranslationStore,
} from '@softline/ui-core';
import { AddOn } from '@softline/application';
import { default as de } from '../i18n/de.json';
import { SOFTLINE_FEATURE_BUCHUNGSBRIEF } from './buchungsbrief.shared';
import { GesamtabfrageListAddOn } from './add-ons/gesamtabfrage-list.add-on';
import { BuchungsbriefStore } from './store';
import { SOFTLINE_API_BELEGE } from './buchungsbrief.api';
import { Kopfwert } from './types/kopfwert';
import { GeschaeftsfallAddOn } from './add-ons/geschaeftsfall.add-on';
import { FreigabeListAddOn } from './add-ons/freigabe-list.add-on';
import { BuchungsbriefService } from './services/buchungsbrief.service';

export const buchungsbriefProviders: (Provider | ImportedNgModuleProviders)[] =
  [
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => {
        const store = inject(Store);
        store.commit(
          SOFTLINE_FEATURE_TRANSLATION,
          TranslationStore.mutations.add,
          {
            module: 'buchungsbrief',
            language: 'de',
            translations: de,
          }
        );
      },
      multi: true,
    },
    importProvidersFrom(
      StoreModule.forFeature({
        name: SOFTLINE_FEATURE_BUCHUNGSBRIEF,
        feature: BuchungsbriefStore.feature,
        providers: [
          {
            provide: SOFTLINE_STORE_ENTITY_SERVICE,
            useFactory: (service: ConnectionHttpService) =>
              new SoftlineEntityService(service, SOFTLINE_API_BELEGE),
            deps: [SOFTLINE_SERVICE_HTTP],
          },
          {
            provide: SOFTLINE_STORE_DETAIL_SERVICE_FACTORY,
            useFactory: (service: ConnectionHttpService) => () =>
              new SoftlineEntityDetailService<Kopfwert[]>(
                service,
                SOFTLINE_API_BELEGE,
                'kopfwert'
              ),
            deps: [SOFTLINE_SERVICE_HTTP],
          },
        ],
      })
    ),
    { provide: BuchungsbriefService, useClass: BuchungsbriefService },
    { provide: AddOn, useClass: GesamtabfrageListAddOn, multi: true },
    { provide: AddOn, useClass: GeschaeftsfallAddOn, multi: true },
    { provide: AddOn, useClass: FreigabeListAddOn, multi: true },

  ];
