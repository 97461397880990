<ng-container *ngIf="tabs.length > 0 && !hideSelection">
  <div class="soft-tab-group" [ngClass]="customClass">
    <div
      *ngFor="let tab of tabs; let i = index"
      class="soft-tab"
      (click)="activateIndex(i)"
    >
      <ng-template [ngIf]="tab.templateTitle">
        <ng-template [cdkPortalOutlet]="tab.templateTitle"></ng-template>
      </ng-template>
      <ng-template [ngIf]="!tab.templateTitle" class="soft-tab-title">{{
        tab.title
      }}</ng-template>
      <div *ngIf="activatedIndex === i" class="soft-tab-indicator"></div>
    </div>
  </div>
</ng-container>
<div class="soft-tab-content" [cdkPortalOutlet]="activatedTab?.content"></div>
