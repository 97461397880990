<soft-master-detail
  *ngIf="settings"
  [selected]="selected"
  (selectedChange)="onSelect($event)"
  [data]="(settings$ | async) ?? []"
  [masterTemplate]="masterTemplate"
  [detailTemplate]="detailTemplate"
  class="mt-4"
>
</soft-master-detail>
<div
  class="flex flex-row items-center mt-5"
  *ngIf="!(settings).length"
>
  {{ '#APPLICATION.SETTINGS.NO_SETTINGS' | translate }}
</div>
<ng-template #masterTemplate let-data="data">
  <div class="flex flex-row justify-between items-center flex-nowrap w-full">
    <div class="flex flex-row items-center">
      <div class="icon mr-2">
        <i *ngIf="data.icon" [ngClass]="data.icon"></i>
      </div>
      {{ data.title | translate }}
    </div>
    <i class="fas fa-chevron-right color-lighter"></i>
  </div>
</ng-template>
<ng-template #detailTemplate let-data="data">
  <div class="settings-page">
    <ng-container *ngComponentOutlet="data.component"></ng-container>
  </div>
</ng-template>
