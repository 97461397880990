import {
  Component,
  EventEmitter,
  forwardRef, HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TimeParser } from '../../../services/time.parser';
import { UserAgentService } from '../../../services/user-agent.service';

@Component({
  selector: 'soft-time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeInputComponent),
      multi: true,
    },
  ],
})
export class TimeInputComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  @Input() format?: string;
  @Input() value?: string | null;
  @Output() valueChange = new EventEmitter<string | null>();

  @Input() placeholder?: string;
  @Input() @HostBinding('class.readonly') readonly = false;
  @Input() utcOffset?: string | null;

  // tslint:disable:no-empty
  private onChange: Function = () => {};
  onTouch: Function = () => {};

  constructor(
    readonly userAgentService: UserAgentService,
    private timeParser: TimeParser<string>
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  setValue(value: string): void {
    if (!value) this.value = value;
    else this.value = this.timeParser.parse(value, this.utcOffset);
    this.onChange(this.value);
    this.valueChange.emit(this.value);
  }
}
