<span class="hover:underline cursor-pointer"
      (click)="onValueClick()">
  <span *ngIf="!$any(filter).value?.length"
        class="italic !text-light soft-label required">
    {{'#APPLICATION.FILTER_AND_SORT.FILTER.MULTI.PLACEHOLDER' | translate}}
  </span>
  <div *ngIf="$any(filter).value?.length" class="flex flex-col"
       [class.p-2]="$any(filter).value?.length > 1">
      <span *ngFor="let value of ($any(filter).value ?? [])">{{value | transform:(config?.params?.formatFn ?? defaultTransformFn)}}</span>
  </div>
<ng-template let-close="close" #dialogRef>
  <div class="flex flex-col" [formGroup]="form">
    <label class="soft-label top required">{{'#APPLICATION.FILTER_AND_SORT.FILTER.MULTI.LABEL' | translate}}</label>
    <soft-multi-select formControlName="value"
                       softFocus>
      <soft-select-option *ngFor="let option of (values | multiFilterSelectOptions:config?.params?.formatFn) ?? []"
                          [value]="option.value">
        {{option.label | translate}}
      </soft-select-option>
    </soft-multi-select>
    <soft-validation-container
      *ngIf="form.controls['value'].touched"
      [validation]="form | validationMessage: 'value'"
      class="soft-property-validation"
    ></soft-validation-container>
    <button [disabled]="form.invalid"
            class="soft-button primary h-16 rounded mt-4"
            (click)="close(form.value)">{{'#APPLICATION.FILTER_AND_SORT.FILTER.MULTI.SUBMIT' | translate}}</button>
  </div>
</ng-template>
