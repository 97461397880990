<soft-chip-group *ngIf="definition">
  <soft-chip
    *ngFor="let child of showAll ? definition.definitions : (definition.definitions | filterDefinedValues: value)"
    class="mr-2 mb-2"
    [class.hidden]="!(($any(child).visible ?? true) | booleanRule: value)"
    [canDelete]="!($any(child).required | booleanRule: value)"
    (delete)="onDelete($any(child))"
  >
    <span>
      <span *ngIf="child.title" class="text-xs font-bold mr-1"
        >{{ child.title }}:</span
      >
      <a class="hover:underline text-xs pointer" (click)="onClick(child)">
        <soft-dynamic-value
          [definition]="child"
          [value]="value"
        ></soft-dynamic-value>
      </a>
    </span>
  </soft-chip>
</soft-chip-group>
