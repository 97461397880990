import { createGetter, createMutation, mutate, select } from '../factories';
import { StoreFeature } from '../store';

export interface State<T = unknown> {
  items: T[];
}

export class Store<T> {
  mutations = {
    set: createMutation<State<T>, State<T>>('set'),
    add: createMutation<State<T>, T>('add'),
    addMany: createMutation<State<T>, T[]>('addMany'),
    remove: createMutation<State<T>, T>('remove'),
    removeMany: createMutation<State<T>, T[]>('removeMany'),
    clear: createMutation<State<T>>('clear'),
  };
  getters = {
    items: createGetter<State<T>, T[]>('all'),
    count: createGetter<State<T>, number>('count'),
  };

  feature: StoreFeature<State<T>> = {
    initialState: {
      items: [],
    },
    mutations: [
      mutate(this.mutations.set, ({ state, params }) => ({
        ...state,
        ...params,
      })),
      mutate(this.mutations.add, ({ state, params }) => {
        const items = [...state.items, params];
        return { ...state, items };
      }),
      mutate(this.mutations.addMany, ({ state, params }) => {
        const items = [...state.items, ...params];
        return { ...state, items };
      }),
      mutate(this.mutations.remove, ({ state, params }) => {
        const index = state.items.indexOf(params);
        if (index === -1)
          throw new Error(
            `ListStore: Can not process mutation 'remove'. Can not find item '${JSON.stringify(
              params
            )}'`
          );

        const items = state.items.filter((o) => o !== params);
        return { ...state, items };
      }),
      mutate(this.mutations.removeMany, ({ state, params }) => {
        const items = [...state.items];

        const notFoundItems: unknown[] = [];
        for (const item of params) {
          const index = state.items.indexOf(item);
          if (index === -1) notFoundItems.push(item);
          else items.splice(index, 1);
        }
        if (notFoundItems.length > 0)
          throw new Error(
            `ListStore: Can not process mutation 'removeMany'. Can not find items '${JSON.stringify(
              notFoundItems
            )}'`
          );

        return { ...state, items };
      }),
      mutate(this.mutations.clear, ({ state, params }) => ({
        ...state,
        items: [],
      })),
    ],
    getters: [
      select(this.getters.items, ({ state }) => state.items),
      select(this.getters.count, ({ state }) => state.items.length),
    ],
    actions: [],
  };
}

export function create<T extends unknown>(): Store<T> {
  return new Store<T>();
}

const instance = create();
export const mutations = instance.mutations;
export const getters = instance.getters;
export const feature = instance.feature;
