import { TemplateRef } from '@angular/core';
import { DismissConfig } from './modal-config';

export interface ModalOption<T> {
  value: T;
  icon?: string;
  label?: string;
  disabled?: boolean;
}

export interface OptionModalConfig<T> {
  title?: string;
  subtitle?: string;
  content?: string | TemplateRef<any>;
  options: ModalOption<T>[];
  class?: string;
  params?: object;
  dismiss?: boolean | DismissConfig;
}
