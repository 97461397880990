<div class="outline-1 outline-secondary outline-dashed w-full">
  <div #content></div>
</div>

<div class="flex flex-row w-full items-center mt-3 gap-2">
  <div
    class="flex justify-center items-center h-8 w-full border border-solid border-secondary rounded-full cursor-pointer"
    [ngClass]="{
      'bg-primary hover:bg-primary-600 text-primary-contrast': tool === 'select'
    }"
    (click)="onToolChange('select')"
  >
    <i class="fas fa-arrows-alt"></i>
  </div>
  <div
    class="flex justify-center items-center h-8 w-full border border-solid border-secondary rounded-full cursor-pointer"
    [ngClass]="{
      'bg-primary hover:bg-primary-600 text-primary-contrast': tool === 'pen'
    }"
    (click)="onToolChange('pen')"
  >
    <i class="fas fa-pen"></i>
  </div>

  <div class="w-full"></div>
  <soft-color-input
    class="rounded center"
    [value]="color"
    (valueChange)="onColorChange($event ?? '#000000')"
    #input
  ></soft-color-input>
  <div class="w-full"></div>

  <div class="relative w-full">
    <div
      class="flex justify-center items-center h-8 w-full border border-solid border-secondary rounded-full cursor-pointer stroke-text"
      (click)="strokes.hidden = !strokes.hidden; $event.stopPropagation()"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="32"
        width="32"
        viewBox="0 0 800 600"
      >
        <path
          d="m423,121c0,0 -442,377 -108,226c334,-151 46,75 250,81"
          opacity="NaN"
          [attr.stroke-width]="strokeWidth"
          fill="transparent"
        />
      </svg>
    </div>

    <div
      class="flex flex-col absolute bottom-10 gap-2 justify-center bg-white shadow -left-2 -right-2 p-2"
      hidden
      (softClickOutside)="strokes.hidden = true"
      [delayClickOutsideInit]="true"
      #strokes
    >
      <div
        class="flex justify-center items-center h-8 w-full border border-solid border-secondary rounded-full cursor-pointer stroke-text"
        [ngClass]="{
          'bg-primary hover:bg-primary-600 stroke-primary-contrast':
            stroke === 'light'
        }"
        (click)="onStrokeChange('light'); strokes.hidden = !strokes.hidden"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="32"
          width="32"
          viewBox="0 0 800 600"
        >
          <path
            d="m423,121c0,0 -442,377 -108,226c334,-151 46,75 250,81"
            opacity="NaN"
            stroke-width="25"
            fill="transparent"
          />
        </svg>
      </div>
      <div
        class="flex justify-center items-center h-8 w-full border border-solid border-secondary rounded-full cursor-pointer stroke-text"
        [ngClass]="{
          'bg-primary hover:bg-primary-600 stroke-primary-contrast':
            stroke === 'medium'
        }"
        (click)="onStrokeChange('medium'); strokes.hidden = !strokes.hidden"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="32"
          width="32"
          viewBox="0 0 800 600"
        >
          <path
            d="m423,121c0,0 -442,377 -108,226c334,-151 46,75 250,81"
            opacity="NaN"
            stroke-width="50"
            fill="transparent"
          />
        </svg>
      </div>
      <div
        class="flex justify-center items-center h-8 w-full border border-solid border-secondary rounded-full cursor-pointer stroke-text"
        [ngClass]="{
          'bg-primary hover:bg-primary-600 stroke-primary-contrast':
            stroke === 'bold'
        }"
        (click)="onStrokeChange('bold'); strokes.hidden = !strokes.hidden"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="32"
          width="32"
          viewBox="0 0 800 600"
        >
          <path
            d="m423,121c0,0 -442,377 -108,226c334,-151 46,75 250,81"
            opacity="NaN"
            stroke-width="75"
            fill="transparent"
          />
        </svg>
      </div>
      <div
        class="flex justify-center items-center h-8 w-full border border-solid border-secondary rounded-full cursor-pointer stroke-text"
        [ngClass]="{
          'bg-primary hover:bg-primary-600 stroke-primary-contrast':
            stroke === 'extrabold'
        }"
        (click)="onStrokeChange('extrabold'); strokes.hidden = !strokes.hidden"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="32"
          width="32"
          viewBox="0 0 800 600"
        >
          <path
            d="m423,121c0,0 -442,377 -108,226c334,-151 46,75 250,81"
            opacity="NaN"
            stroke-width="100"
            fill="transparent"
          />
        </svg>
      </div>
    </div>
  </div>

  <div class="w-full"></div>

  <button
    class="flex justify-center items-center h-8 w-full border border-solid border-secondary rounded-full cursor-pointer"
    (click)="onTakePicture()"
  >
    <i class="fas fa-camera"></i>
  </button>
  <button
    class="flex justify-center items-center h-8 w-full border border-solid border-secondary rounded-full disabled:text-light disabled:cursor-not-allowed disabled:opacity-50"
    [disabled]="tool !== 'select' || !canvas._activeObject"
    (click)="onDeleteSelected()"
  >
    <i class="fas fa-trash"></i>
  </button>
</div>
