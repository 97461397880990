import { SOFTLINE_CONFIG_APP_INFO } from "@softline/application";
import { BrowserModule } from "@softline/browser";
import { importProvidersFrom } from "@angular/core";
import { default as appInfo } from "../app-info.json";

export const platform = {
  providers: [
    importProvidersFrom(
      BrowserModule.forRoot(),
    ), {
      provide: SOFTLINE_CONFIG_APP_INFO,
      useValue: appInfo
    }
  ]
};
