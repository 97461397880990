<div
  *ngIf="backNavigationService.canNavigateBack$ | async"
  class="soft-container title-bar-command"
  (click)="backNavigationService.navigateBack()"
>
  <span class="pt-3 pb-3 ml-3 mr-3">
    <i class="fas fa-arrow-left"></i>
  </span>
</div>
<div
  *ngIf="isMobileScreen$ | async"
  class="soft-container title-bar-command relative"
  (click)="maximizeSideMenu()"
>
  <span class="soft-badge" *ngIf="mainMenuBadges$ | async as badge">{{ badge }}</span>
  <span class="pt-3 pb-3 ml-3 mr-3">
    <i class="fas fa-bars"></i>
  </span>
</div>

<div class="soft-container column ml-3 center-v h-12 truncate">
  <h4 id="framework-application-titlebar-title"
      class="program-title font-semibold truncate">
    {{ title$ | async | translate }}
  </h4>
  <h6 id="framework-application-titlebar-subtitle"
      class="text-sm opacity-70 truncate" *ngIf="subTitle$ | async as subTitle">
    {{ subTitle | translate }}
  </h6>
</div>

<div class="title-bar-branding"></div>

<div class="soft-container row auto-width title-bar-commands ml-3">
  <div
    *ngFor="let command of leftCommands$ | async"
    class="soft-container soft-container-column soft-container-center title-bar-command pt-3 pb-3"
  >
    <i
      [ngClass]="command.icon"
      *ngVar="
        command.canExecute ? (command.canExecute | async) : true as canExecute
      "
      (click)="
        onClick(
          canExecute,
          command.routerLink,
          command.execute,
          command.routerLinkParams
        )
      "
    >
    </i>
    <span class="title-bar-command-name">{{ command.name | translate }}</span>
  </div>
</div>

<div
  class="soft-container row auto-width title-bar-commands title-bar-commands-right no-wrap"
>
  <div *ngFor="let command of rightCommands$ | async" class="title-bar-command">
    <span class="soft-badge" *ngIf="command?.badge | async as badge">{{ badge }}</span>
    <span
      class="flex flex-col items-center justify-center px-3 relative h-full w-full"
      [ngClass]="command.class"
      *ngVar="
        command.canExecute ? (command.canExecute | async) : true as canExecute
      "
      (click)="
        onClick(
          canExecute,
          command.routerLink,
          command.execute,
          command.routerLinkParams
        )
      "
    >
      <img
        *ngIf="command?.src"
        [attr.src]="command.src"
        class="absolute top-1 title-bar-image"
        alt="image"
      />
      <i class="absolute top-2" [ngClass]="command?.icon"></i>
      <span class="title-bar-command-name absolute bottom-2">{{
        command.name | translate
      }}</span>
    </span>
  </div>
</div>

<div
  class="action-menu-controls h-full"
  *ngIf="(isMobileScreen$ | async) && (hasSideActions$ | async)"
>
  <div class="title-bar-command" (click)="maximizeActionMenu()">
    <span class="c column ml-3 mr-3 center">
      <span class="soft-badge" *ngIf="actionMenuBadges$ | async as badge">{{ badge }}</span>
      <i class="fas fa-ellipsis-v actions-icon"></i>
      <span class="title-bar-command-name">{{
        '#SHELL.ACTIONS' | translate
      }}</span>
    </span>
  </div>
</div>
