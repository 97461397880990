<div  [ngStyle]="transformWhileDragging" class="flex flex-grow h-full w-full">
    <soft-pull-to-refresh-indicator
      *ngIf="canRefresh"
      [dragDistance]="dragDistance"
    >
    </soft-pull-to-refresh-indicator>
  <div class="flex flex-grow h-full custom-width">
    <ng-content></ng-content>
  </div>
</div>
