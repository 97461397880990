import { Inject, Injectable } from '@angular/core';
import { Buchungsbrief } from '../types/buchungsbrief';
import { Observable } from 'rxjs';
import { ConnectionHttpService, SOFTLINE_SERVICE_HTTP } from '@softline/core';
import { SOFTLINE_API_BUCHUNGSBRIEF } from '../buchungsbrief.api';
import { Beleg } from '../types/beleg';

@Injectable()
export class BuchungsbriefService {
  constructor(
    @Inject(SOFTLINE_SERVICE_HTTP) private service: ConnectionHttpService
  ) {}

  create(buchungsbrief: Buchungsbrief): Observable<Beleg> {
    return this.service.create<Buchungsbrief, Beleg>(
      { path: SOFTLINE_API_BUCHUNGSBRIEF },
      buchungsbrief
    );
  }
}
