import {
  Component,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import {FormGroup, FormGroupDirective, UntypedFormControl, UntypedFormGroup, ValidatorFn} from "@angular/forms";
import {Dictionary, isDefinedNotEmpty} from '@softline/core';
import {ControlDefinition, Definition, isControlDefinition, ObjectDefinition,} from '../../data/definitions';
import {RuleHelper} from '../../utilities/rule.helper';
import {BehaviorSubject, Subscription} from 'rxjs';
import {DateParser, Validators} from '@softline/ui-core';
import {AffectedHelper} from "../../utilities/affected.helper";

@Component({
  selector: 'soft-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent<T> implements OnInit, OnDestroy {
  private subscription?: Subscription;
  private initSubscription?: Subscription;
  private _definition: ObjectDefinition | undefined | null = undefined;

  private _runAfterInit?: (form: FormGroup) => void

  readonly formInitialized$ = new BehaviorSubject(false);

  @Input()
  get definition(): ObjectDefinition | undefined | null {
    return this._definition;
  }
  set definition(value: ObjectDefinition | undefined | null) {
    this._definition = value;
    if (value) {
      this.form = this.buildForm(value);
      this.formInitialized$.next(true);
      this._runAfterInit?.(this.form);
    } else
      this.form = new UntypedFormGroup({});
  }

  private _value?: T;
  @Input()
  get value(): T | undefined {
    return this._value;
  }
  set value(value: T | undefined) {
    if (value === this._value) return;

    this._value = value;
    if (value) this.form.patchValue(value);
  }
  @Output() valueChange: EventEmitter<T> = new EventEmitter<T>();
  @Output() submit: EventEmitter<T> = new EventEmitter<T>();
  @Output() reset: EventEmitter<T> = new EventEmitter<T>();

  @ViewChild('formRef') formRef?: FormGroupDirective;

  form = new UntypedFormGroup({});

  constructor(private injector: Injector, private dateParser: DateParser<any>) {}

  ngOnInit(): void {
    this.subscription = this.form.valueChanges.subscribe((_) => {
      this._value = this.form.value;
      this.valueChange.emit(this.form.value);
    });
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();

    if (this.initSubscription && !this.initSubscription.closed)
      this.initSubscription.unsubscribe();

    this.subscription = undefined;
    this.initSubscription = undefined;
  }

  buildForm(definition: ObjectDefinition): UntypedFormGroup {
    const formGroup = new UntypedFormGroup({}, { updateOn: 'blur' });
    const stack: Definition[] = [...definition.definitions];
    const groups: { formGroup: UntypedFormGroup; endOn: Definition }[] = [];
    while (stack.length > 0) {
      const current = stack.pop();

      if (!current) continue;

      if (groups.length > 0 && groups[groups.length - 1]?.endOn === current)
        groups.pop();

      const currentFormGroup =
        groups.length > 0 ? groups[groups.length - 1]?.formGroup : formGroup;

      if (current.type === 'object') {
        const newFormGroup = new UntypedFormGroup({}, { updateOn: 'blur' });
        currentFormGroup.addControl(current.name, newFormGroup);
        groups.push({
          formGroup: newFormGroup,
          endOn: stack[stack.length - 1],
        });
        stack.push(...current.definitions);
      } else if (isControlDefinition(current) && currentFormGroup)
        currentFormGroup.addControl(current.name, this.createControl(current));
      else if (current.type === 'group') stack.push(...current.definitions);
    }

    if (this.value) formGroup.patchValue(this.value);

    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();
    this.subscription = formGroup.valueChanges.subscribe((_) => {
      this._value = formGroup.value;
      this.valueChange.emit(formGroup.value);
    });
    return formGroup;
  }

  createControl(definition: ControlDefinition): UntypedFormControl {
    let updateOn: 'blur' | 'change' | 'submit' = 'blur';
    let defaultValue: unknown = definition.default ?? null;
    switch (definition.type) {
      case 'select':
        updateOn = 'change';
        break;
      case 'boolean':
        updateOn = 'change';
        defaultValue = defaultValue ?? false;
        break;
      case 'date':
        updateOn = 'change';
        if(typeof defaultValue === 'string' && defaultValue !== "")
          defaultValue = this.dateParser.parse(defaultValue)
        break;
      case 'string':
        updateOn = 'blur';
        break;
    }
    const validators: ValidatorFn[] = [];
    if (definition.required) validators.push(Validators.required());
    if (definition.validations)
      validators.push(RuleHelper.getValidator(definition.validations, this.injector));

    const control = new UntypedFormControl(defaultValue, {
      updateOn,
      validators,
    });

    if (definition.affects)
      control.valueChanges.subscribe(o => {
        const parent = control.parent
        if(!(parent instanceof FormGroup))
          return;
        const patch = AffectedHelper.getAffectedPatch(definition.affects, {...parent.value, [definition.name]: o}, this.injector);
        if(parent instanceof FormGroup)
          parent.patchValue(patch, {emitEvent: false});
      })
    return control;
  }

  private getDefaultValue(definition: ObjectDefinition): T {
    const defaultValue: Dictionary<any> = {};
    for (const propertyDefinition of definition?.definitions?.filter(
      isControlDefinition
    ))
      defaultValue[propertyDefinition.name] =
        propertyDefinition.default ??
        (propertyDefinition.name === 'boolean' ? false : undefined);
    return defaultValue as T;
  }

  @HostListener('window:keydown.enter', ['$event'])
  onSubmit(): void {
    if(!this.form.valid || this.form.disabled)
      return;
    this._value = this.form.value;
    this.submit.emit(this.form.value);
  }

  onNativeSubmit(event: Event): void {
    event.stopPropagation();
  }

  async waitForInit(): Promise<void> {
    return new Promise(resolve => {
      this.initSubscription = this.formInitialized$.subscribe(done => {
        if (!done) return;
        resolve();
        this.initSubscription?.unsubscribe();
        this.initSubscription = undefined;
      })
    })
  }

  afterInit(callback: (form: FormGroup) => void) {
    this._runAfterInit = callback
  }

  onReset(event: Event): void {
    if (!this.definition) return;
    const defaultValue = this.getDefaultValue(this.definition);
    this.form.reset(defaultValue);
    this.reset.emit(defaultValue);
    event.stopPropagation();
  }
}
