<div
  (click)="goToApplication(application)"
  class="grid-item-container overflow-clip"
>
  <span class="application-icon">
    <i
      *ngIf="application?.icon !== ''; else defaultIcon"
      [class]="application?.icon"
      class="fa-3x mt-1"
    ></i>
  </span>
  <div class="soft-container soft-container-center set-width">
    <span class="application-title">{{ application?.name | translate }}</span>
  </div>
</div>

<ng-template #defaultIcon>
  <i class="fas fa-ban fa-3x"></i>
</ng-template>
