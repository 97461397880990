<header class="flex flex-col w-full bg-surface">
  <div class="text-center w-full flex flex-col">
    <span *ngIf="overline" class="color-light font-semibold text-xs mt-2">
      {{overline}}
    </span>
    <span *ngIf="title" class="font-semibold text-xl mt-1">
      {{title}}
    </span>
    <span *ngIf="subtitle" class="color-light text-sm mt-1 opacity-90">
      {{subtitle}}
  </span>
  </div>
  <ng-content></ng-content>
</header>
