<div class="date-preselection-container">
  <soft-select
    *ngIf="calendarService.months$ | async as months"
    [(value)]="month"
  >
    <soft-select-option
      *ngFor="let month of months; let i = index"
      [value]="i"
      >{{ month }}</soft-select-option
    >
  </soft-select>
  <soft-combo-box
    updateOn="blur"
    [selectOnFocus]="true"
    *ngIf="calendarService.years$ | async as years"
    [(value)]="year"
  >
    <soft-combo-box-option
      *ngFor="let year of years"
      [value]="year"
    ></soft-combo-box-option>
  </soft-combo-box>
</div>

<div class="flex flex-col md:grid md:grid-cols-[1.25fr_1fr] gap-4">
  <div class="flex relative items-center justify-center">
    <table class="unselectable w-full relative" *ngIf="monthView">
      <thead>
      <tr>
        <th
          colspan="7"
          class="header-cell h-8 bg-gray-100 w-8 sm:h-8 sm:w-8"
        >
          <div class="flex justify-between items-center">
            <span class="flex items-center justify-center w-8 p-1 cursor-pointer" (click)="previousMonth()">
              <i class="fas fa-chevron-left text-primary"></i>
            </span>
            <span *ngIf="calendarService.months$ | async as months">{{ months[month] ?? '' }}</span>
            <span class="flex items-center justify-center w-8 p-1 cursor-pointer" (click)="nextMonth()">
              <i class="fas fa-chevron-right text-primary"></i>
            </span>
          </div>
        </th>
      </tr>
      <tr>
        <th
          *ngFor="let weekday of calendarService.shortWeekdays$ | async"
          class="header-cell h-8 bg-gray-100 w-12 sm:h-8 sm:h-8"
        >
            <span class="tbl-header">{{ weekday }}</span>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let week of monthView?.weeks">
        <td
          *ngFor="let day of week.days"
          class="calender-cell h-12 w-12 sm:h-16 sm:w-16 hover:bg-gray-100"
          (click)="onSelect(day.date)"
          (dblclick)="onSubmit(day.date)"
          [ngClass]="{
          today: today === day.date.toISOString() && !hasSelected,
          selected: _value.toISOString() === day.date.toISOString(),
          lastMonth: month !== day.date.month()
        }"
        >
          {{ day.day | number: '2.0' }}
        </td>
      </tr>
      </tbody>
    </table>
  </div>


  <div class="flex w-full flex-col md:mt-3 mb-1 pb-2">
    <h6 class="soft-overline mb-1">Shortcuts</h6>
    <ul>
      <li class="py-3 px-2 border-t cursor-pointer hover:bg-hover" (click)="selectShortcut({ value: 'h', label: '' })">Heute</li>
      <li class="md:hidden py-3 px-2 border-t cursor-pointer hover:bg-hover" (click)="openShortcutsList()">Weitere Shortcuts</li>
      <div class="hidden md:flex md:flex-col">
        @for (shortcut of shortcuts; track shortcut.value) {
          <li class="py-3 px-2 border-t cursor-pointer hover:bg-hover" (click)="selectShortcut(shortcut)">{{ shortcut.label | translate }}</li>
        }
      </div>
    </ul>
  </div>
</div>

