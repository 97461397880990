<div class="pdf-editor">
  <div class="pdf-editor-loading" *ngIf="loading$ | async">Loading...</div>

  <div class="pdf-editor-container" [hidden]="loading$ | async">
    <div class="pdf-editor-toolbar" #toolbar>
      <header class="pdf-editor-toolbar-header">
        <h3>Werkzeuge</h3>
        <div class="flex flex-row">
          <button
            class="soft-button small link danger mr-4"
            title="Remove Selection"
            (click)="removeSelectedObjects()"
            *ngIf="hasSelectedObjects$ | async"
          >
            <span class="fas fa-trash"></span> Element(e) löschen
          </button>
          <button class="lg:hidden" (click)="toolboxToggle = !toolboxToggle">
            <svg
              *ngIf="toolboxToggle"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M4.5 15.75l7.5-7.5 7.5 7.5"
              />
            </svg>
            <svg
              *ngIf="!toolboxToggle"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
              />
            </svg>
          </button>
        </div>
      </header>
      <main
        [ngClass]="{ 'toolbox-toggle': !toolboxToggle }"
        class="pdf-editor-toolbar-content"
      >
        <soft-pdf-editor-tools></soft-pdf-editor-tools>
      </main>
      <footer
        [ngClass]="{ 'toolbox-toggle': !toolboxToggle }"
        class="flex flex-col pdf-editor-toolbar-footer"
      >
        <div class="flex flex-row w-full">
          <button
            type="button"
            class="soft-button link w-full"
            (click)="download()"
            [disabled]="downloading$ | async"
          >
            Download
          </button>
          <button
            type="button"
            class="soft-button secondary w-full"
            (click)="clear()"
          >
            Verwerfen
          </button>
        </div>
        <div class="flex flex-row">
          <button
            type="button"
            class="soft-button primary w-full"
            (click)="onSubmit()"
            [disabled]="saving$ | async"
          >
            Speichern
          </button>
        </div>
      </footer>
    </div>
    <div class="flex w-full justify-center">
      <div class="pdf-editor-content" #content></div>
    </div>
  </div>
</div>
