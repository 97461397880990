<div class="c row space-between no-wrap">
  <div class="c row start fixed-height">
    <a class="colored custom-size hover-bg" (click)="onPrevious()">
      <ng-container *ngIf="showIcons"><i class="fas fa-arrow-left mr-1"></i></ng-container>&nbsp;
      {{ previousLabel$ | async }}
    </a>
  </div>
  <div *ngIf="canNext$ | async" class="c row end fixed-height">
    <a class="colored custom-size hover-bg" (click)="onNext()">
      {{ nextLabel$ | async }}&nbsp;
      <ng-container *ngIf="showIcons"><i class="fas fa-arrow-right ml-1"></i></ng-container>
    </a>
  </div>
</div>
<div class="current-data c column center fill">
  <h3 class="text-lg md:text-xl lg:text-2xl font-semibold">{{ title }} {{ currentLabel$ | async }}</h3>
  <p class="text-xs text-light"> {{ subtitle }}</p>
</div>
