import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule, formatDate } from '@angular/common';
import { CurrencyFormatPipe, TranslatePipe, UiCoreModule, UiCorePipesModule } from '@softline/ui-core';
import { groupBy, Store } from '@softline/core';
import { SOFTLINE_FEATURE_OFFENE_POSTEN_JOPOSTEN } from '@softapps/fibu/offene-posten';
import { JopostenStore } from '../../store';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { map } from 'rxjs';
import { BarChartData } from '../../types/chart-data.model';
import { handleRequestErrors } from '@softline/application';

@Component({
  selector: 'soft-offene-posten-widget',
  standalone: true,
  imports: [CommonModule, UiCoreModule, NgxChartsModule, UiCorePipesModule],
  templateUrl: './offene-posten.widget.html',
  styleUrl: './offene-posten.widget.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CurrencyFormatPipe]
})
export class OffenePostenWidget implements OnInit, OnDestroy {

  readonly offenePosten$ = this.store.observe(
    SOFTLINE_FEATURE_OFFENE_POSTEN_JOPOSTEN,
    JopostenStore.getters.all
  );

  readonly chartData$ = this.offenePosten$.pipe(
    map(posten => {
      const today = this.resetHoursToStartOfDay();

      const alleNachHeute = posten
        .sort((a, b) => (a.faelldatum > b.faelldatum) ? 1 : ((b.faelldatum > a.faelldatum) ? -1 : 0))
        .filter(a => (this.resetHoursToStartOfDay(a.faelldatum) > today));

      const alleHeuteFaellig = posten.filter(a => (this.resetHoursToStartOfDay(a.faelldatum) <= today));
      return { alleNachHeute, alleHeuteFaellig };
    }),
    map(({ alleNachHeute, alleHeuteFaellig }) => {
      const postenInKw = groupBy(alleNachHeute, p => formatDate(p.faelldatum, 'ww yyyy', 'de-AT'));
      const returnValue: BarChartData[] = [{
        name: this.translatePipe.transform('#OFFENE_POSTEN.DIAGRAMM.LABEL_ALLE_PER_HEUTE') ?? '',
        series: [
          {
            name: this.translatePipe.transform('#OFFENE_POSTEN.DIAGRAMM.LABEL_FAELLIGKEITEN') ?? '',
            value: ((alleHeuteFaellig.reduce((value, currentValue) => value + (currentValue.saldoAbzglSkonto < 0 ? currentValue.saldoAbzglSkonto : 0), 0)) ?? 0) * (-1),
          },
          {
            name: this.translatePipe.transform('#OFFENE_POSTEN.DIAGRAMM.LABEL_GUTSCHRIFTEN') ?? '',
            value: alleHeuteFaellig.reduce((value, currentValue) => value + (currentValue.saldoAbzglSkonto > 0 ? currentValue.saldoAbzglSkonto : 0), 0)
          }
        ]
      }];

      for (const group of postenInKw) {
        if (returnValue.length >= 4)
          break;

        returnValue.push({
          name: (group.key === (formatDate(new Date(), 'ww yyyy', 'de-AT'))) ? (this.translatePipe.transform('#OFFENE_POSTEN.DIAGRAMM.LABEL_DERZEITIGE_KW') ?? '') : `KW ${formatDate(Array.from(group)[0].faelldatum,'ww', 'de-AT')}`,
          series: [
            {
              name: this.translatePipe.transform('#OFFENE_POSTEN.DIAGRAMM.LABEL_FAELLIGKEITEN') ?? '',
              value: (([...group].reduce((value, currentValue) => value + (currentValue.saldoAbzglSkonto < 0 ? currentValue.saldoAbzglSkonto : 0), 0)) ?? 0) * (-1),
            },
            {
              name: this.translatePipe.transform('#OFFENE_POSTEN.DIAGRAMM.LABEL_GUTSCHRIFTEN') ?? '',
              value: [...group].reduce((value, currentValue) => value + (currentValue.saldoAbzglSkonto > 0 ? currentValue.saldoAbzglSkonto : 0), 0)
            }
          ]
        });
      }
      return returnValue;
    })
  );

  readonly faelligkeitenInTotal$ = this.offenePosten$.pipe(
    map(offenePosten => {
      return offenePosten.reduce((value, currentValue) => value + (currentValue.saldoAbzglSkonto < 0 ? currentValue.saldoAbzglSkonto : 0), 0) * (-1);
    })
  );

  readonly colorScheme = {
    domain: ['#E11D51', '#1A9B36']
  } as any

  constructor(
    private store: Store,
    private translatePipe: TranslatePipe,
    private currencyFormatPipe: CurrencyFormatPipe
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      await this.store.dispatch(
        SOFTLINE_FEATURE_OFFENE_POSTEN_JOPOSTEN,
        JopostenStore.actions.loadMany,
        {
          clear: true,
        }
      );
    }
    catch (e) {
      handleRequestErrors(this.store, e);
    }
  }
  ngOnDestroy(): void {
    this.store.commit(SOFTLINE_FEATURE_OFFENE_POSTEN_JOPOSTEN, JopostenStore.mutations.clear);
  }

  readonly formatDataLabel = (value) => {
    return this?.currencyFormatPipe?.transform(value, 'EUR', 'symbol', 'before', '', 'de-AT') ?? value;
  }

  private resetHoursToStartOfDay(dateString?: string): Date {
    const date = dateString ? new Date(dateString) : new Date()
    date.setHours(0, 0, 0, 0);
    return date;
  }
}
