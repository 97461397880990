<div class="fixed inset-0 flex items-center justify-center">
  <div class="flex flex-col justify-center items-center p-8 text-color bg-surface text-xl rounded">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-16 h-16">
      <path stroke-linecap="round" stroke-linejoin="round" d="M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z" />
      <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z" />
    </svg>
    <span class="mt-4">
      <i class="fas fa-spinner fa-spin mr-2"></i>
      {{'#HTML5_SCANNER.STARTING' | translate}}
    </span>
  </div>
</div>
<div class="flex flex-row justify-center items-center w-[100vw] h-[100vh] bg-black bg-opacity-30">
  <div id="html5Scanner"
       class="flex flex-row bg-gray bg-opacity-50 w-full h-full"></div>
</div>
<div class="fixed text-white left-4 top-4 z-100 cursor-pointer"
     (click)="cancel()">
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="w-8 h-8">
    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
  </svg>
</div>
<div *ngIf="isTorchAvailable"
     class="fixed text-white right-4 top-4 z-100 cursor-pointer"
     (click)="cancel()">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
    <path fill-rule="evenodd" d="M14.615 1.595a.75.75 0 01.359.852L12.982 9.75h7.268a.75.75 0 01.548 1.262l-10.5 11.25a.75.75 0 01-1.272-.71l1.992-7.302H3.75a.75.75 0 01-.548-1.262l10.5-11.25a.75.75 0 01.913-.143z" clip-rule="evenodd" />
  </svg>
</div>
