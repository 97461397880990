import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@softline/core';
import { UiCoreModule } from '@softline/ui-core';
import { SOFTLINE_CONFIG_SETTINGS } from '../application.shared';
import * as GeolocationStore from './geolocation.store';
import {
  SOFTLINE_FEATURE_GEOLOCATION,
  SOFTLINE_SETTINGS_GEOLOCATION,
} from './geolocation.shared';
import { InputGeolocationComponent } from './components/input-geolocation.component';
import { GeolocationSettingsComponent } from './settings/geolocation-settings.component';
import { GeolocationLinkPipe } from './pipes/geolocation-link.pipe';

@NgModule({
  declarations: [
    GeolocationSettingsComponent,
    InputGeolocationComponent,
    GeolocationLinkPipe,
  ],
  imports: [CommonModule, ReactiveFormsModule, UiCoreModule],
  exports: [GeolocationLinkPipe],
  providers: [],
})
export class GeolocationModule {
  static forRoot(): ModuleWithProviders<GeolocationRootModule> {
    return {
      ngModule: GeolocationRootModule,
      providers: [
        {
          provide: SOFTLINE_CONFIG_SETTINGS,
          multi: true,
          useValue: {
            key: SOFTLINE_SETTINGS_GEOLOCATION,
            component: GeolocationSettingsComponent,
            title: '#APPLICATION.GEOLOCATION.TITLE',
            default: {},
            icon: 'fas fa-map-marker-alt',
          },
        },
      ],
    };
  }
}

@NgModule({
  imports: [
    GeolocationModule,
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_GEOLOCATION,
      feature: GeolocationStore.feature,
    }),
  ],
  exports: [GeolocationModule],
})
export class GeolocationRootModule {}
