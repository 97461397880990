<form [formGroup]="loginForm" (ngSubmit)="submit(loginForm.value)">
  <div class="w-full grid grid-cols-3 align-middle text-center">
    <h4 class="text-lg font-bold col-start-2">{{ '#LOGIN.TITLE' | translate }}</h4>
    <div *ngIf="config.scan" class="col-start-3 flex flex-row justify-end">
      <span class="flex flex-row flex-nowrap items-center bg-gray-300 hover:bg-gray-400 rounded whitespace-nowrap py-1 px-2 cursor-pointer"
            (click)="onScanClick()">
        <i class="fas fa-qrcode"></i>
      </span>
    </div>
  </div>
  <div class="c mt-3">
    <soft-input
      type="email"
      formControlName="username"
      id="softapps-allgemein-login-username"
      placeholder="{{ '#LOGIN.USERNAME' | translate }}"
      autocomplete="username"
    ></soft-input>
  </div>
  <div class="c mt-3">
    <soft-password-input
      formControlName="password"
      id="softapps-allgemein-login-password"
      placeholder="{{ '#LOGIN.PASSWORD' | translate }}"
      autocomplete="current-password"
    >
    </soft-password-input>
  </div>
  <div class="c mt-3">
    <soft-checkbox id="softapps-allgemein-login-remember"
                   formControlName="remember">
      <span class="soft-label right">{{ '#LOGIN.REMEMBER' | translate }}</span>
    </soft-checkbox>
  </div>

  <div class="c center mt-3">
    <button
      id="softapps-allgemein-login-submit"
      class="soft-button soft-button-primary"
      [disabled]="loading"
      type="submit"
    >
      <i *ngIf="loading" class="fas fa-spinner fa-spin"></i>
      {{ '#LOGIN.LOGIN' | translate }}
    </button>
  </div>

  <div *ngFor="let link of links || []" class="c center mt-4">
    <a [routerLink]="link.route" class="soft-link">
      {{ link.name | translate }}
    </a>
  </div>

  <div class="c center mt-4">
    <a (click)="openSettings()" class="soft-link cursor-pointer">
      {{ '#LOGIN.SETTINGS' | translate }}
    </a>
  </div>
</form>
