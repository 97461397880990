import { ENVIRONMENT_INITIALIZER, inject, Provider } from '@angular/core';
import { Store } from '@softline/core';
import {
  SOFTLINE_FEATURE_TRANSLATION,
  TranslationStore,
} from '@softline/ui-core';
import {
  ApplicationStore,
  SOFTLINE_FEATURE_APPLICATIONS,
} from '@softline/application';
import { default as de } from '../i18n/de.json';
import {
  SOFTLINE_DEFINITION_HAFTRUECKLASS_LIST,
  SOFTLINE_DEFINITION_HAFTRUECKLASS_QUERY,
  SOFTLINE_PERMISSIONS_HAFTRUECKLASS,
} from './haftruecklass.shared';
import { SOFTLINE_CONFIG_DEFINITION } from '@softline/dynamic';
import { default as queryDefinition } from './definitions/haftruecklass-query.definition.json';
import { default as listDefinition } from './definitions/haftruecklass-list.definition.json';

export const haftruecklassProviders: Provider[] = [
  {
    provide: ENVIRONMENT_INITIALIZER,
    useValue: () => {
      const store = inject(Store);
      store.commit(
        SOFTLINE_FEATURE_TRANSLATION,
        TranslationStore.mutations.add,
        {
          module: 'haftruecklass',
          language: 'de',
          translations: de,
        }
      );
      store.commit(
        SOFTLINE_FEATURE_APPLICATIONS,
        ApplicationStore.mutations.add,
        {
          name: '#HAFTRUECKLASS.TITLE',
          icon: 'fas fa-comment-dollar',
          route: '/haftruecklass',
          permission: SOFTLINE_PERMISSIONS_HAFTRUECKLASS,
        }
      );
    },
    multi: true,
  },
  {
    provide: SOFTLINE_CONFIG_DEFINITION,
    useValue: {
      name: SOFTLINE_DEFINITION_HAFTRUECKLASS_QUERY,
      definition: queryDefinition,
    },
    multi: true,
  },
  {
    provide: SOFTLINE_CONFIG_DEFINITION,
    useValue: {
      name: SOFTLINE_DEFINITION_HAFTRUECKLASS_LIST,
      definition: listDefinition,
    },
    multi: true,
  },
];
