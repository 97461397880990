<div class="grid grid-cols-1 xl:grid-cols-2 gap-4">
  <div class="text-sm xl:col-start-2 xl:row-start-1">
    <div class="soft-card">
      <soft-dynamic-object
        [definition]="$any(detailDefinition$ | async)"
        [value]="workflow$ | async"
        [loading]="detailLoading"
      ></soft-dynamic-object>
    </div>

    <soft-widget-outlet name="softappsFibuFreigabe"
                        [context]="id$ | async"
                        ></soft-widget-outlet>
  </div>
  <div class="relative xl:col-start-1 xl:row-start-1 w-full viewer-height">
    <div class="flex flex-col absolute right-12 top-8 z-10">
      <button
        *ngIf="!documentLoading && document"
        (click)="onEditPdf()"
        class="soft-button secondary  opacity-20 hover:opacity-80"
      >
        Bearbeiten
      </button>
      <button
        *ngIf="!documentLoading && document && documentEdited"
        (click)="onResetDocument()"
        class="soft-button link danger opacity-20 hover:opacity-80"
      >
        Zurücksetzen
      </button>
    </div>
    <soft-pdf-viewer
      [document]="document"
      [loading]="documentLoading"
    ></soft-pdf-viewer>
  </div>
</div>
