import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { GroupDefinition } from '../../../../data/definitions';

@Component({
  selector: 'soft-dynamic-group-input',
  templateUrl: './group-input.component.html',
  styleUrls: ['./group-input.component.scss'],
})
export class GroupInputComponent implements OnInit {
  @Input() definition!: GroupDefinition;
  @Input() form: UntypedFormGroup = new UntypedFormGroup({});

  constructor() {}

  ngOnInit(): void {}
}
