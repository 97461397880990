import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {UiCoreModule} from '@softline/ui-core';
import {SOFTLINE_CONFIG_SETTINGS} from '../application.shared';
import {SOFTLINE_CONFIG_FONT_SIZES, SOFTLINE_CONFIG_THEMES, SOFTLINE_SETTINGS_APPEARANCE,} from './appearance.shared';
import {AppearanceComponent} from './settings/appearance.component';
import {AppearanceService} from './services/appearance.service';
import {Appearance} from './data/appearance';

export interface AppearanceModuleOptions {
  defaultSettings: Appearance;
}

@NgModule({
  declarations: [AppearanceComponent],
  imports: [CommonModule, ReactiveFormsModule, UiCoreModule],
  exports: [],
  providers: [],
})
export class AppearanceModule {
  static forRoot(
    options?: AppearanceModuleOptions
  ): ModuleWithProviders<AppearanceRootModule> {
    return {
      ngModule: AppearanceRootModule,
      providers: [
        {
          provide: SOFTLINE_CONFIG_SETTINGS,
          multi: true,
          useValue: {
            key: SOFTLINE_SETTINGS_APPEARANCE,
            component: AppearanceComponent,
            title: '#APPLICATION.APPEARANCE.TITLE',
            default: options?.defaultSettings ?? {
              theme: 'new'
            },
            icon: 'fas fa-font',
          },
        },
      ],
    };
  }
}

@NgModule({
  imports: [AppearanceModule],
  providers: [
    AppearanceService,
    {
      provide: SOFTLINE_CONFIG_THEMES,
      useValue: {
        key: undefined,
        name: '#APPLICATION.APPEARANCE.THEMES.DEFAULT',
      },
      multi: true,
    },
    {
      provide: SOFTLINE_CONFIG_THEMES,
      useValue: {
        key: 'new',
        name: '#APPLICATION.APPEARANCE.THEMES.NEW',
      },
      multi: true,
    },
    {
      provide: SOFTLINE_CONFIG_FONT_SIZES,
      useValue: {
        key: '14px',
        name: '#APPLICATION.APPEARANCE.FONT_SIZES.SMALL',
      },
      multi: true,
    },
    {
      provide: SOFTLINE_CONFIG_FONT_SIZES,
      useValue: {
        key: undefined,
        name: '#APPLICATION.APPEARANCE.FONT_SIZES.DEFAULT',
      },
      multi: true,
    },
    {
      provide: SOFTLINE_CONFIG_FONT_SIZES,
      useValue: {
        key: '20px',
        name: '#APPLICATION.APPEARANCE.FONT_SIZES.LARGE',
      },
      multi: true,
    },
    {
      provide: SOFTLINE_CONFIG_FONT_SIZES,
      useValue: {
        key: '24px',
        name: '#APPLICATION.APPEARANCE.FONT_SIZES.EXTRA_LARGE',
      },
      multi: true,
    },
  ],
})
export class AppearanceRootModule {
  constructor(service: AppearanceService) {}
}
