import { Provider } from '@angular/core';
import { DeveloperStore, SOFTLINE_CONFIG_SETTINGS, SOFTLINE_FEATURE_DEVELOPER } from "@softline/application";
import {
  SOFTLINE_CONFIG_TEST_CONNECTION_PATH,
  SOFTLINE_SERVICE_TEST_CONNECTION,
  SOFTLINE_SETTINGS_CONNECTION,
} from './connection-settings.shared';
import { ConnectionSettingsComponent } from './components/connection-settings.component';
import { TestConnectionService } from './services/test-connection.service';
import { Store } from "@softline/core";

export const connectionSettingsProviders: Provider[] = [
  {
    provide: SOFTLINE_SERVICE_TEST_CONNECTION,
    useClass: TestConnectionService,
  },
  { provide: SOFTLINE_CONFIG_TEST_CONNECTION_PATH, useValue: '/v1/test' },
  {
    provide: SOFTLINE_CONFIG_SETTINGS,
    multi: true,
    useFactory: (store: Store) => ({
      key: SOFTLINE_SETTINGS_CONNECTION,
      component: ConnectionSettingsComponent,
      title: '#CONNECTION_SETTINGS.TITLE',
      default: undefined,
      icon: 'fas fa-link',
      visible: store.observe(SOFTLINE_FEATURE_DEVELOPER, DeveloperStore.getters.isDeveloper)
    }),
    deps: [Store]
  },
];
