import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { equals } from '@softline/core';
import { ObjectDefinition } from '../../data/definitions';
import { VirtualScrollParameters } from '../../data/virtual-scroll';
import { UiCoreModule } from "@softline/ui-core";
import { CommonModule } from "@angular/common";
import { DynamicPipesModule } from "../../pipes/dynamic-pipes.module";
import { DynamicSkeletonComponent } from "../dynamic-skeleton/dynamic-skeleton.component";
import { DynamicValueComponent } from "../dynamic-value/dynamic-value.component";
import { GroupComponent } from "../atoms/values/group/group.component";
import { ScrollingModule } from "@angular/cdk/scrolling";

@Component({
  selector: 'soft-dynamic-table',
  standalone: true,
  imports: [CommonModule, ScrollingModule, UiCoreModule, DynamicPipesModule, DynamicSkeletonComponent, DynamicValueComponent, GroupComponent],
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicTableComponent<T> implements OnInit {
  @Input() definition?: ObjectDefinition;
  @Input() rows: T[] = [];
  @Input() footer?: Partial<T>[];

  @Input() selectionMode?: 'single' | 'multi';

  @Input() selectedItem?: T;
  @Output() selectedItemChange = new EventEmitter<T>();

  @Input() selectedItems: T[] = [];
  @Output() selectedItemsChange = new EventEmitter<T[]>();

  @Input() tableClass = '';
  @Input() virtual?: VirtualScrollParameters;

  @Input() loading = false;
  @Input() skeletonRows = 10;

  @Output() rowDblClick = new EventEmitter<T>();

  get allSelected(): boolean{
    return this.rows.length > 0 && this.rows.length === this.selectedItems.length;
  }

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {}

  toggleSelect(item: T): void {
    switch (this.selectionMode) {
      case 'single':
        if (equals(item, this.selectedItem)) {
          this.selectedItem = undefined;
          this.selectedItems = [];
        } else {
          this.selectedItem = item;
          this.selectedItems = [item];
        }
        this.selectedItemChange.emit(this.selectedItem);
        this.selectedItemsChange.emit(this.selectedItems);
        break;
      case 'multi':
        const index = this.selectedItems.findIndex((o) => equals(item, o));
        if (index !== -1) {
          const selectedItems = [...this.selectedItems];
          selectedItems.splice(index, 1);
          this.selectedItems = selectedItems;
        } else this.selectedItems = [...this.selectedItems, item];

        this.selectedItemChange.emit(item);
        this.selectedItemsChange.emit(this.selectedItems);
        break;
      default:
        break;
    }
    this.changeDetectorRef.detectChanges();
  }

  select(item: T): void {
    switch (this.selectionMode) {
      case 'single':
        const selectedItem = this.selectedItem;
        this.selectedItem = item;
        if (equals(item, selectedItem))
          this.selectedItemChange.emit(this.selectedItem);
        break;
      case 'multi':
        const index = this.selectedItems.findIndex((o) => equals(item, o));
        if (index === -1) {
          this.selectedItems = [...this.selectedItems, item];
          this.selectedItemChange.emit(item);
          this.selectedItemsChange.emit(this.selectedItems);
        }
        break;
      default:
        break;
    }
  }

  unselect(item: T): void {
    switch (this.selectionMode) {
      case 'single':
        const selectedItem = this.selectedItem;
        this.selectedItem = undefined;
        if (equals(item, selectedItem))
          this.selectedItemChange.emit(this.selectedItem);
        break;
      case 'multi':
        const index = this.selectedItems.findIndex((o) => equals(item, o));
        if (index !== -1) {
          const selectedItems = [...this.selectedItems];
          selectedItems.splice(index, 1);
          this.selectedItems = selectedItems;
          this.selectedItemChange.emit(item);
          this.selectedItemsChange.emit(this.selectedItems);
        }
        break;
      default:
        break;
    }
  }

  toggleSelectAll(): void {
    if(this.selectionMode !== 'multi')
      return;

    if(this.rows.length > this.selectedItems.length)
      this.selectedItems = [...this.rows]
    else
      this.selectedItems = []

    this.selectedItemsChange.emit(this.selectedItems);
  }

}
