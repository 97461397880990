<div class="modal question-modal">
  <div class="p-4 sm:pl-0 sm:pt-0 text-2xl font-semibold">
    {{ title ?? 'Frage' | translate | interpolate: params }}
  </div>
  <div class="question pr-4 pl-4 pb-4 sm:pl-0">
    {{ question | translate | interpolate: params }}
  </div>
  <div class="flex no-wrap">
    <button
      *ngIf="showCancel"
      class="soft-button link cancel h-14 w-full"
      (click)="onClick('CANCEL')"
    >
      {{ '#UI_CORE.DIALOGS.CANCEL' | translate }}
    </button>
    <button
      class="soft-button secondary no h-14 w-full"
      (click)="onClick('NO')"
    >
      {{ '#UI_CORE.DIALOGS.NO' | translate }}
    </button>
    <button
      class="soft-button primary yes sm:ml-3 h-14 w-full"
      (click)="onClick('YES')"
    >
      {{ '#UI_CORE.DIALOGS.YES' | translate }}
    </button>
  </div>
</div>
