<label [for]="labelName" class="soft-checkbox">
  <ng-container *ngIf="labelPosition === 'before'"
    ><ng-content></ng-content
  ></ng-container>
  <span
    class="soft-checkbox-box"
    [class.checked]="checked"
    [class.readonly]="readonly || parentReadonly"
    [class.disabled]="disabled"
    [class.focus]="hasFocus.hasFocus"
  ></span>
  <i
    class="fa fa-check soft-checkbox-indicator"
    [class.hidden]="!checked"
    aria-hidden="true"
  ></i>
  <input
    [id]="labelName"
    class="soft-checkbox-input"
    type="checkbox"
    [checked]="checked"
    (change)="
      !disabled && !readonly && !parentReadonly
        ? setChecked(input.checked)
        : undefined
    "
    [readOnly]="readonly || parentReadonly"
    [disabled]="disabled"
    #hasFocus="softHasFocus"
    softHasFocus
    #input
  />
  <ng-container *ngIf="labelPosition === 'after'"
    ><ng-content></ng-content
  ></ng-container>
</label>
