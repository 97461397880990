import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewContainerRef
} from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  AddOnHost,
  AddOnService,
  BackNavigable,
  BackNavigationService,
  Command,
  CommandStore,
  handleRequestErrors,
  Refreshable,
  RefreshService,
  SOFTLINE_FEATURE_COMMANDS,
  SOFTLINE_FEATURE_TITLE,
  TitleStore
} from "@softline/application";
import { containsText, isDefinedNotEmpty, Store } from "@softline/core";
import { BehaviorSubject, combineLatestWith, debounceTime, map, Observable, startWith } from "rxjs";
import { Router } from "@angular/router";
import { DefinitionStore, DynamicModule, SOFTLINE_FEATURE_DEFINITIONS } from "@softline/dynamic";
import { UiCoreModule } from "@softline/ui-core";
import {
  SOFTLINE_CONST_WORKFLOW_DATA_DETAIL,
  SOFTLINE_DEFINITION_FREIGABE_LIST,
  SOFTLINE_FEATURE_FREIGABE
} from "../../freigabe.shared";
import { FreigabeStore } from "../../store";
import { Workflow } from "../../types/workflow";

@Component({
  selector: 'soft-freigabe-list',
  standalone: true,
  imports: [CommonModule, UiCoreModule, DynamicModule],
  templateUrl: './freigabe-list.component.html',
  styleUrls: ['./freigabe-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FreigabeListComponent
  implements OnInit, OnDestroy, BackNavigable, Refreshable, AddOnHost
{
  filter$ = new BehaviorSubject<string | undefined>(undefined);
  debouncedFilter$ = this.filter$.pipe(debounceTime(400), startWith(undefined));

  listDefinition$: any = this.store.observe(
    SOFTLINE_FEATURE_DEFINITIONS,
    DefinitionStore.getters.definition,
    SOFTLINE_DEFINITION_FREIGABE_LIST
  );

  selected$ = new BehaviorSubject<any | undefined>(undefined);
  freigaben$: Observable<Readonly<Workflow[]>> = this.store
    .observe(SOFTLINE_FEATURE_FREIGABE, FreigabeStore.getters.allWithDetails)
    .pipe(
      combineLatestWith(this.debouncedFilter$),
      map(([items, filter]) =>
        items.filter((o) =>
          isDefinedNotEmpty(filter) ? containsText(o, filter, true) : true
        )
      )
    );
  loading = false;

  constructor(
    private store: Store,
    private backNavigationService: BackNavigationService,
    private refreshService: RefreshService,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private addOnService: AddOnService,
    public vcRef: ViewContainerRef
  ) {}

  async ngOnInit(): Promise<void> {
    this.backNavigationService.set(this);
    await this.addOnService.attachTo(this);
    this.refreshService.add(this);
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      '#FREIGABE.TITLE'
    );
    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.addSet,
      {
        name: FreigabeListComponent,
        commands: this.createCommands(),
      }
    );
    try {
      await this.store.dispatch(
        SOFTLINE_FEATURE_DEFINITIONS,
        DefinitionStore.actions.loadOnce,
        { name: SOFTLINE_DEFINITION_FREIGABE_LIST }
      );
    } catch (e) {
      handleRequestErrors(this.store, e);
    }

    try {
      this.loading = true;
      this.cdRef.detectChanges();
      const workflows = await this.store.dispatch(
        SOFTLINE_FEATURE_FREIGABE,
        FreigabeStore.actions.loadMany,
        { clear: true }
      );
      const ids = workflows.map((o) => o.id);
      await this.store.dispatch(
        SOFTLINE_FEATURE_FREIGABE,
        FreigabeStore.actions.loadManyDetails,
        {
          ids,
          name: SOFTLINE_CONST_WORKFLOW_DATA_DETAIL,
          keyMap: (o) => o.wfprocessid,
        }
      );
    } catch (e) {
      handleRequestErrors(this.store, e);
    } finally {
      this.loading = false;
      this.cdRef.detectChanges();
    }
  }

  async ngOnDestroy(): Promise<void> {
    this.backNavigationService.set(undefined);
    this.refreshService.remove(this);
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      ''
    );
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setSubTitle,
      ''
    );
    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.removeSet,
      FreigabeListComponent
    );
    await this.addOnService.detachFrom(this);
    this.backNavigationService.set(undefined);
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['/']);
  }

  async refresh(): Promise<void> {
    try {
      this.loading = true;
      this.cdRef.detectChanges();
      const workflows = await this.store.dispatch(
        SOFTLINE_FEATURE_FREIGABE,
        FreigabeStore.actions.loadMany,
        { clear: true }
      );
      const ids = workflows.map((o) => o.id);
      await this.store.dispatch(
        SOFTLINE_FEATURE_FREIGABE,
        FreigabeStore.actions.loadManyDetails,
        {
          ids,
          name: SOFTLINE_CONST_WORKFLOW_DATA_DETAIL,
          keyMap: (o) => o.wfprocessid,
        }
      );
    } catch (e) {
      handleRequestErrors(this.store, e);
    } finally {
      this.loading = false;
      this.cdRef.detectChanges();
    }
  }

  createCommands(): Command[] {
    return [
      {
        name: '#FREIGABE.TITLE',
        class: 'menu main-menu main-menu-top title',
      },
      {
        icon: 'fas fa-list',
        name: '#FREIGABE.MENU.LIST',
        class: 'menu main-menu main-menu-top',
        routerLink: ['/freigabe'],
      },
      {
        icon: 'fas fa-file-export',
        name: '#FREIGABE.MENU.OPEN',
        class: 'menu action-menu action-menu-top',
        canExecute: this.selected$.pipe(
          map((o) => !!o && o?.detail?.kzfreigabe === 'J')
        ),
        execute: async () => {
          await this.router.navigate(['/freigabe', this.selected$.value.id]);
        },
      },
    ];
  }
}
