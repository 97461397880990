import { Routes } from '@angular/router';
import { AuthenticationGuard, AuthorizationGuard } from '@softline/auth';
import { importProvidersFrom } from '@angular/core';
import {
  ConnectionHttpService,
  SOFTLINE_SERVICE_HTTP,
  SOFTLINE_STORE_DETAIL_SERVICE_FACTORY,
  SOFTLINE_STORE_ENTITY_SERVICE,
  StoreModule,
} from '@softline/core';
import {
  SOFTLINE_FEATURE_BUCHUNGSBRIEF,
  SOFTLINE_PERMISSION_BUCHUNGSBRIEF,
} from './buchungsbrief.shared';
import { BuchungsbriefStore } from './store';
import { OfflineResolver } from '@softline/application';

export const buchungsbriefRoutes: Routes = [
  {
    path: 'buchungsbrief',
    children: [
      {
        path: ':id',
        loadComponent: () =>
          import('./pages/buchungsbrief/buchungsbrief.component').then(
            (o) => o.BuchungsbriefComponent
          ),
      },
      { path: '**', redirectTo: '' },
    ],
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    data: { permission: SOFTLINE_PERMISSION_BUCHUNGSBRIEF },
    resolve: { offline: OfflineResolver },
    providers: [],
  },
];
