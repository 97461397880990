<div class="c row space-between mt-4">
  <span class="soft-link flex flex-row flex-nowrap items-center" (click)="onChange(date, -1)">
    <i class="fas fa-chevron-left"></i>
    <span class="f-1 ml-2 hidden sm:block">{{
      '#UI_CORE.COMPONENTS.DATE_NAVIGATOR.PREVIOUS_DAY' | translate
    }}</span>
  </span>
  <div class="center f-2" (click)="openDatePickerDialog()">
    <div #ref>
      <ng-content></ng-content>
    </div>
    <ng-container *ngIf="!ref.hasChildNodes()">
      {{ date | format: 'DATE.DEFAULT' }}
    </ng-container>
  </div>
  <span class="soft-link  flex flex-row flex-nowrap items-center" (click)="onChange(date, 1)">
    <span class="f-1 mr-2 hidden sm:block">{{
      '#UI_CORE.COMPONENTS.DATE_NAVIGATOR.NEXT_DAY' | translate
    }}</span>
    <i class="fas fa-chevron-right"></i>
  </span>
</div>
