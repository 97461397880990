import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { asapScheduler, Observable, observeOn } from "rxjs";
import { Command, SOFTLINE_FEATURE_COMMANDS } from "@softline/application";
import * as CommandStore from "../../../program/command.store";
import { Store } from "@softline/core";
import { NavigationExtras, Router } from "@angular/router";
import { I18nModule, UiCoreDirectivesModule } from "@softline/ui-core";

@Component({
  selector: 'soft-bottom-bar',
  standalone: true,
  imports: [CommonModule, UiCoreDirectivesModule, I18nModule],
  templateUrl: './bottom-bar.component.html',
  styleUrls: ['./bottom-bar.component.scss']
})
export class BottomBarComponent implements OnInit {

  bottomCommands$: Observable<readonly Command[]> = this.store
    .observe(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.getters.commands,
      'bottom-menu'
    )
    .pipe(observeOn(asapScheduler));
  constructor(private store: Store,
              private router: Router) { }

  ngOnInit(): void {
  }

  onClick(
    execute: (() => void) | undefined,
    routerLink: any[] | string | undefined,
    canExecute: boolean,
    routerLinkParams: NavigationExtras | undefined
  ): void {
    if (!canExecute)
      return;
    if (execute)
      execute();
    else if (routerLink)
      this.router.navigate(
        typeof routerLink === 'string' ? [routerLink] : routerLink,
        routerLinkParams
      );
  }
}
