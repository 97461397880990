<div class="flex w-full justify-between flex-wrap mt-2">
  <h1 class="text-2xl font-bold">
    {{ '#FREIGABE.PAGES.LIST.HEADER' | translate }}
  </h1>
  <soft-input
    class="w-full sm:w-[30vw]"
    placeholder="{{ '#FREIGABE.PAGES.LIST.FILTER' | translate }}"
    (valueChange)="filter$.next($event ?? '')"
  ></soft-input>
</div>
<hr class="my-4" />
<div class="w-full overflow-y-auto">
  <soft-dynamic-table
    [selectionMode]="'single'"
    [definition]="$any(listDefinition$ | async)"
    [selectedItem]="selected$ | async"
    (selectedItemChange)="selected$.next($event)"
    [rows]="freigaben$ | async | take: 100"
    [loading]="loading"
    tableClass="soft-table no-border"
    class="body-border divided"
  >
  </soft-dynamic-table>
</div>
