import { importProvidersFrom } from '@angular/core';
import { Routes } from '@angular/router';
import { AuthenticationGuard, AuthorizationGuard } from '@softline/auth';
import {
  ConnectionHttpService,
  SOFTLINE_SERVICE_HTTP,
  SOFTLINE_STORE_DETAIL_SERVICE_FACTORY,
  SOFTLINE_STORE_ENTITY_SERVICE,
  SoftlineEntityDetailService,
  StoreModule,
} from '@softline/core';
import { FreigabeStore } from './store';
import {
  SOFTLINE_FEATURE_FREIGABE,
  SOFTLINE_PERMISSIONS_FREIGABE,
} from './freigabe.shared';
import { WorkflowService } from './services/workflow.service';
import { WorkflowData } from './types/workflow-data';
import {
  SOFTLINE_API_WORKFLOW,
  SOFTLINE_API_WORKFLOW_DATA,
} from './freigabe.api';
import { OfflineResolver } from '@softline/application';

export const freigabeRoutes: Routes = [
  {
    path: 'freigabe',
    children: [
      {
        path: ':id',
        loadComponent: () =>
          import('./pages/freigabe/freigabe.component').then(
            (o) => o.FreigabeComponent
          ),
      },
      {
        path: '',
        loadComponent: () =>
          import('./pages/freigabe-list/freigabe-list.component').then(
            (o) => o.FreigabeListComponent
          ),
        pathMatch: 'full',
      },
      { path: '**', redirectTo: '' },
    ],
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    data: { permission: SOFTLINE_PERMISSIONS_FREIGABE },
    resolve: { offline: OfflineResolver },
    providers: [
      importProvidersFrom(
        StoreModule.forFeature({
          name: SOFTLINE_FEATURE_FREIGABE,
          feature: FreigabeStore.feature,
          providers: [
            { provide: WorkflowService, useClass: WorkflowService },
            {
              provide: SOFTLINE_STORE_ENTITY_SERVICE,
              useExisting: WorkflowService,
            },
            {
              provide: SOFTLINE_STORE_DETAIL_SERVICE_FACTORY,
              useFactory: (service: ConnectionHttpService) => () =>
                new SoftlineEntityDetailService<WorkflowData>(
                  service,
                  SOFTLINE_API_WORKFLOW,
                  'data',
                  SOFTLINE_API_WORKFLOW_DATA
                ),
              deps: [SOFTLINE_SERVICE_HTTP],
            },
          ],
        })
      ),
    ],
  },
];
