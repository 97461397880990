import {
  ChangeDetectorRef,
  ComponentRef,
  Directive,
  ElementRef, HostListener,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewContainerRef
} from "@angular/core";
import { ValidationContainerComponent } from "../components/validation-container/validation-container.component";
import { AbstractControl, FormGroupDirective, ValidationErrors } from "@angular/forms";
import { ValidationIconComponent } from "../validation/components/validation-icon/validation-icon.component";
import { Subscription } from "rxjs";
import { isDefined, isGroupValidation, isValidation, ValidationMessage, ValidationType } from "@softline/core";

@Directive({
  selector: '[softFieldValidation]',
  standalone: true,
})
export class FieldValidationDirective implements OnInit, OnDestroy{

  private groupSubscription: Subscription | null = null;
  private controlSubscription: Subscription | null = null;
  private validationContainer: ComponentRef<ValidationContainerComponent> | null = null;
  private validationIcon: ComponentRef<ValidationIconComponent> | null = null;


  private _formControlName: string | null = null;
  get formControlName(): string | null {
    return this._formControlName
  }
  @Input()
  set formControlName (value: string | null) {
    this._formControlName = value;
    this.updateView(value);
  }

  private _messagesVisible: boolean = false;
  get messagesVisible(): boolean {
    return this._messagesVisible
  }
  @Input('softFieldValidationOpen')
  set messagesVisible (value: boolean) {
    this._messagesVisible = value;
    this.updateView(this._formControlName);
  }

  @Input('softFieldValidationTrigger') trigger: 'onTouched' | 'onSubmit' | 'always' = 'onTouched';

  private _touched = false;
  get touched(): boolean {
    return this._touched
  }
  set touched(value: boolean){
    if(this._touched === value)
      return;
    this._touched = value;

    const group = this.formGroup.control;
    const control = isDefined(this.formControlName) ? this.formGroup.control.controls[this.formControlName] : null;
    this.onStatusChanged(group, control);
  }

  private _submitted = false;
  get submitted(): boolean {
    return this._submitted
  }
  set submitted(value: boolean){
    if(this._submitted === value)
      return;
    this._submitted = value;

    const group = this.formGroup.control;
    const control = isDefined(this.formControlName) ? this.formGroup.control.controls[this.formControlName] : null;
    this.onStatusChanged(group, control);
  }

  constructor(
    private vcRef: ViewContainerRef,
    private formGroup: FormGroupDirective,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.validationIcon = this.vcRef.createComponent(ValidationIconComponent);
    this.validationContainer = this.vcRef.createComponent(ValidationContainerComponent);
    this.renderer.setStyle(this.validationIcon.location.nativeElement, 'position', 'absolute');
    this.renderer.setStyle(this.validationIcon.location.nativeElement, 'top', '-0.75rem');
    this.renderer.setStyle(this.validationIcon.location.nativeElement, 'right', '0.25rem');
    this.renderer.listen(this.validationIcon.location.nativeElement, 'click', () => {
      if(!this.validationContainer)
        return;
      this.renderer.removeClass(this.validationContainer.location.nativeElement, this.messagesVisible ? 'visible' : 'hidden')
      this.messagesVisible = !this.messagesVisible;
      this.renderer.addClass(this.validationContainer.location.nativeElement, this.messagesVisible ? 'visible' : 'hidden');
    });
    this.renderer.appendChild(this.elementRef.nativeElement, this.validationIcon.location.nativeElement);

    this.renderer.addClass(this.validationContainer.location.nativeElement, 'soft-property-validation');
    this.renderer.addClass(this.validationContainer.location.nativeElement, this.messagesVisible ? 'visible' : 'hidden');
    this.updateView(this.formControlName);
  }

  ngOnDestroy() {
    if (this.controlSubscription && !this.controlSubscription.closed)
      this.controlSubscription.unsubscribe();
    this.controlSubscription = null;
    if (this.groupSubscription && !this.groupSubscription.closed)
      this.groupSubscription.unsubscribe();
    this.groupSubscription = null;
  }

  //@HostListener('blur')
  @HostListener('focusout')
  onBlur() {
    this.touched = true;
  }

  updateView(property: string | null): void {
    if(!this.validationContainer)
      return;
    if (this.controlSubscription && !this.controlSubscription.closed)
      this.controlSubscription.unsubscribe();
    if (this.groupSubscription && !this.groupSubscription.closed)
      this.groupSubscription.unsubscribe();

    const group = this.formGroup.control;
    const control = isDefined(property) ? this.formGroup.control.controls[property] : null;

    this.groupSubscription = group.statusChanges.subscribe(
      o => { this.onStatusChanged(group, control);}
    );
    if(control) {
      this.controlSubscription = control.statusChanges.subscribe(
        o => { this.onStatusChanged(group, control);}
      );
      this.onStatusChanged(group, control);
    }
  }

  private onStatusChanged(group: AbstractControl, control?: AbstractControl | null): void {
    if(!this.validationIcon || !this.validationContainer)
      return;

    let validationType: ValidationType | null = null;
    let messages: ValidationMessage[] = [];

    if(this.touched || this.submitted || this.trigger === 'always') {
      validationType = this.getValidationType(group.errors, control?.errors);
      messages = this.getValidationMessages(group.errors, control?.errors)
    }

    this.validationIcon.instance.type = validationType;
    this.validationContainer.instance.validation = {messages: messages};

    this.renderer.removeClass(this.elementRef.nativeElement, 'error');
    this.renderer.removeClass(this.elementRef.nativeElement, 'warning');
    this.renderer.removeClass(this.elementRef.nativeElement, 'info');
    this.renderer.removeClass(this.elementRef.nativeElement, 'success');
    if(validationType)
      this.renderer.addClass(this.elementRef.nativeElement, validationType);

    this.cdRef.markForCheck();
    this.cdRef.detectChanges();
    this.validationIcon.changeDetectorRef.markForCheck();
    this.validationIcon.changeDetectorRef.detectChanges();
    this.validationContainer.changeDetectorRef.markForCheck();
    this.validationContainer.changeDetectorRef.detectChanges();
  }

  private getValidationType(group: ValidationErrors | null, control?: ValidationErrors | null): ValidationType | null {
    let type: ValidationType | null = null;
    if(group){
      for (let entry of Object.values(group)) {
        if(!isGroupValidation(entry) || !entry.properties.includes(this.formControlName ?? ''))
          continue;

        for(let message of entry.messages)
          type = this.getHigherType(type, message.type)
      }
    }
    if(control) {
      for (let entry of Object.values(control)) {
        if(!isValidation(entry))
          continue;
        for(let message of entry.messages)
          type = this.getHigherType(type, message.type)
      }
    }
    return type;
  }

  private getHigherType(current: ValidationType | null, error: ValidationType): ValidationType | null {
    if(error === 'error')
      return 'error';
    else if (error === 'warning')
      current = 'warning'
    else if (error === 'success' && current !== 'warning')
      current = 'success'
    else if (error === 'info' && current !== 'warning'&& current !== 'success')
      current = 'info'
    return current
  }

  private getValidationMessages(groupErrors: ValidationErrors | null, controlErrors?: ValidationErrors | null): ValidationMessage[] {
    let messages: ValidationMessage[] = [];
    if(groupErrors) {
      for (let entry of Object.values(groupErrors)) {
        if(!isGroupValidation(entry) || !entry.properties.includes(this.formControlName ?? ''))
          continue;
        messages.push(...entry.messages)
      }
    }
    if(controlErrors) {
      for (let entry of Object.values(controlErrors)) {
        if(!isValidation(entry))
          continue;
        messages.push(...entry.messages)
      }
    }
    return messages;
  }
}
