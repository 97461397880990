<h4 class="text-xl font-semibold mt-3 ml-3 sm:mt-0 sm:ml-0">
  {{ '#GESAMTABFRAGE.ADD_ONS.CHANGE_BAUSTELLE.TITLE' | translate }}
</h4>

<div class="flex flex-row items-center border border-gray-200 mt-4 p-2 text-sm mx-3 sm:mx-0">
  <label for="baustelleAendern" class="soft-label font-bold w-32">
    {{'#GESAMTABFRAGE.ADD_ONS.CHANGE_BAUSTELLE.CURRENT' | translate}}
  </label>
  <ng-container> {{lieferadresse?.kostenstelle}} - {{lieferadresse?.name}}</ng-container>
</div>
<div class="flex flex-row items-center border border-gray-200 mt-2 p-2 text-sm mx-3 sm:mx-0">
  <label for="baustelleAendern" class="soft-label font-bold required w-32">
    {{'#GESAMTABFRAGE.ADD_ONS.CHANGE_BAUSTELLE.LABEL' | translate}}</label>
  <soft-field-ok
    id="baustelleAendern"
    [name]="'ktostamm.kunde.subnummer'"
    title="{{'#GESAMTABFRAGE.ADD_ONS.CHANGE_BAUSTELLE.LABEL' | translate}}"
    [parameters]="{
        geloescht: 0,
        gesperrt: 0,
        kategorie: 2,
        freigabedatum: 'Alle',
        KUNDENABFRAGE_KENNZEICHEN_AKTIV: 'ALLE',
        kundennummer: (ktostammnummer$ | async)
    }"
    class="ml-4 w-full"
    softFocus
    #baustelle
  ></soft-field-ok>
</div>

<button type="submit"
        [disabled]="!baustelle.value"
        class="soft-button primary w-full h-16 mt-4"
        (click)="onSubmit(baustelle.value)">
  {{ '#GESAMTABFRAGE.ADD_ONS.CHANGE_BAUSTELLE.SUBMIT' | translate }}
</button>
