import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  QueryList,
} from '@angular/core';
import { Tab } from './tab/tab';
import { CommonModule } from "@angular/common";
import { CdkPortalOutlet, PortalModule } from "@angular/cdk/portal";

@Component({
  selector: 'soft-tab-group',
  standalone: true,
  imports: [CommonModule, PortalModule],
  templateUrl: './tab-group.component.html',
  styleUrls: ['./tab-group.component.scss'],
})
export class TabGroupComponent implements OnInit, AfterContentInit {
  @Input()
  get activatedIndex(): number {
    return this._activatedIndex;
  }
  set activatedIndex(value: number) {
    this._activatedIndex = value;
    this.activatedTab = this.tabs.toArray()[value];
  }
  private _activatedIndex = 0;
  @Output() activatedIndexChange = new EventEmitter<number>();

  @ContentChildren(forwardRef(() => Tab), { descendants: true })
  tabs: QueryList<Tab> = new QueryList<Tab>();
  @Input() hideSelection = false;

  @Input() customClass = ''

  activatedTab?: Tab;
  constructor() {}

  ngOnInit(): void {}

  ngAfterContentInit(): void {
    this.tabs.changes.subscribe(console.log);
    this.activatedTab = this.tabs.toArray()[this.activatedIndex];
    this.activatedTab?.activate.emit();
  }

  activateIndex(index: number): void {
    this.activatedTab?.deactivate.emit();
    this.activatedIndex = index;
    this.activatedIndexChange.emit(index);
    this.activatedTab?.activate.emit();
  }
}
