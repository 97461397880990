import {
  ENVIRONMENT_INITIALIZER,
  ImportedNgModuleProviders,
  importProvidersFrom,
  inject,
  Provider
} from "@angular/core";
import { isDefinedNotEmpty, Store, StoreModule } from "@softline/core";
import {
  CommandStore, handleRequestErrors,
  NotificationStore,
  SOFTLINE_FEATURE_COMMANDS,
  SOFTLINE_FEATURE_NOTIFICATION
} from "@softline/application";
import { map, of } from "rxjs";
import { AuthenticationContextStore, SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT } from "@softline/auth";

export const notificationProviders: (Provider | ImportedNgModuleProviders)[] = [
  {
    provide: ENVIRONMENT_INITIALIZER,
    multi: true,
    useValue: () => {
      const store = inject(Store);
      store.commit(SOFTLINE_FEATURE_COMMANDS, CommandStore.mutations.addSet, {
        name: 'notifications',
        priority: 1,
        commands: [
          {
            name: '#UTILITIES.NOTIFICATIONS.TITLE',
            routerLink: ['notifications'],
            icon: 'fas fa-bell',
            class: 'menu main-menu main-menu-bottom',
            badge: store.observe(
              SOFTLINE_FEATURE_NOTIFICATION,
              NotificationStore.getters.all
            ).pipe(
              map(o => {
                const length = o.filter(p => !p.readtime).length
                return length > 0 ? `${length}` : ''
              })
            ),
          },
        ],
      });

      let userId: number | null | undefined
      store.observe(SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT, AuthenticationContextStore.getters.data)
        .subscribe(async o => {
          if(!isDefinedNotEmpty(o)) {
            userId = null;
            return;
          }
          if(userId === (o as any).userId)
            return;
          userId =  (o as any).userId;
          try{
            await store.dispatch(SOFTLINE_FEATURE_NOTIFICATION, NotificationStore.actions.loadMany, {});
          }
          catch (e) {
            console.log('[notificationProviders.ENVIRONMENT_INITIALIZER]', e);
          }
        })
    },
  },
];
